import React, { useState, useEffect, Fragment } from 'react'
import moment from 'moment';
import Swal from 'sweetalert2'
import axios from 'axios'
import { Container, Row, Col, } from 'react-grid-system'
import DatePicker from "react-datepicker";
import validator from 'validator';
import summarypwdImg from '../../assets/images/last-step-cg-create-plwd.png'

import Header from '../../components/header2'
import Input from '../../components/input'
import Button from '../../components/button'
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBox from '../../components/modal'

import checkActive from '../../assets/images/ACTIVE.png'
import checkInactive from '../../assets/images/INACTIVE.png'

import { StyledSummary, Label } from '../styles/StyledSummary'

import AppConfig from '../../config'

import { updateInvitation, } from '../../api/UserAPI'
import { getAddress } from '../../api/UserAPI';
import ProccessData from '../../lib/ProccessData'

const accountUrl = AppConfig.ACCOUNT_URL

const steps = ['Details', 'Address', 'Questions', 'Summary', 'Tagging']

const inputPointerStyle = `
  background: #FFFFFF;
  cursor: pointer;
  .input-field {
    cursor: pointer;
  }
`

const inputStyle = `
  background: #FFFFFF;
  cursor: pointer;
`

function pwdSummaryNew({
  location,
  history,
}) {
  const [state, setState] = useState({
    agree: false,
    agree2: false,
    agree3: false,
    loading: false,
    uid: '',
    token: '',
    showError: false,
    errorMessage: '',
    showSuccess: false,
    displayName: window.sessionStorage.getItem('displayNameNew') ?  window.sessionStorage.getItem('displayNameNew') : `${location.state.firstname} ${location.state.lastname || ''}`,
    accountData: {
      ...location.state,
    }
  })
  

  const { agree, agree2, agree3, accountData, uid, token, showError, errorMessage, showSuccess, loading, displayName } = state

  const { 
    firstname,
    lastname,
    gender,
    NIRC: lastNric,
    birth,
    relationship,
    relationshipData,
    streetName,
    unitNumber,
    blockNumber,
    address,
    building,
    postalCode,
    cityCountry,
    masterPartnerUid,
    prefixCountryNum,
    language,
    dementiaType,
    dementiaStage,
    hospitalDepartment,
    hospitalName,
    symptoms,
    existingDementiaSingapore,
    fullData: { routeState: { email, phoneNumber: phone,homeNumber, fullData: { credential: { accountData: { email2, phone2, homeNumber2 } } } } }
  } = accountData
  console.table('COBA CEK BRE =>', accountData)

  // useEffect(() => {
  //   if (credential) {
  //     setState(state => ({
  //       ...state,
  //       accountData: credential.accountData,
  //     }))
  //   }
  // }, [])

  const renderField = ({ label, value, onChange, onClick, maxLength = 50, desc = null, labelDesc = null}) => {
    return (
      <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
        <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
          {labelDesc ? (
            <div>
              <Label>{label}</Label>
              <p style={{ color: '#502773', marginTop: '5px', marginBottom: '0', fontSize: '14px' }}>{labelDesc}</p>
            </div>
          ) : <Label>{label}</Label> }
        </Col>
        <Col md={6} xs={12} offset={{ md: 1, }}>
          <Input
            inputProps={{
              value,
              onChange,
              maxLength,
              onClick,
            }}
            containerStyle={inputStyle}
          />
          {desc && <p style={{ color: '#502773', marginTop: '-5px', marginBottom: '0', fontSize: '14px' }}>{desc}</p>}
        </Col>
      </Row>
    )
  }

  const alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log('exit => ', exit)
        if (exit) {
          this.props.history.replace('/')
        } else null
      },
    })
  }


  const _handleCheckbox = (e, agree) => {
    let checked = e.target.checked;
    setState(state => ({
      ...state,
      [agree]: checked
    }))
  } 

  const handleClose = () => {
    
  }

  const handleChange = ({ stateName, value, }) => {
    if(stateName === 'postalCode') {
      setState(states => ({
        ...states,
        accountData: {
          ...states.accountData,
          postalCode: value
        }
      }))
    } else {
      setState(states => ({
        ...states,
        [stateName]: value
      }))
    }
  }

  const onGetAddress = async (name, value) => {
    handleChange({ stateName: name, value })

    try {
      let resultGet = {}
      resultGet.payload = await getAddress({
        searchVal: `${value}`,
        returnGeom: `Y`,
        getAddrDetails: `Y`,
      })
      resultGet.payload.type = 'SUCCESS'
      ProccessData(resultGet, 'get address')
      .then(res => {
        if (res && res.data && res.data.results && res.data.results.length > 0) {
          setState(states => {
  
            // if(states && states.accountData) {
            //   // states[`address`] = `${res.data.results[0].ADDRESS.replace(value, '')}`;
            // states['accountData'][`streetName`] = `${res.data.results[0].ROAD_NAME}`;
            // // states[`building`] = `${res.data.results[0].BUILDING}`;
            // // states[`blockNumber`] = `${res.data.results[0].BLK_NO}`;
            // }
  
            if(states && states.accountData) {
              return {
                ...states,
                accountData: {
                  ...states.accountData,
                  streetName: `${res.data.results[0].ROAD_NAME}`
                }
              }
            } else {
              return {
                ...states
              }
            }
          })
        }
      })
      .catch(err => {
        alertError(err)
      })
    } catch (e) {
      alertError(e)
    }
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <Input
        inputProps={{
          value: props.value || props.placeholder,
          onClick: props.onClick, 
          ref,
        }}
        containerStyle={`
          ${inputPointerStyle}
          width: 190%;
        `}
      />
    );
  });

  const buttonDisabled = !agree || !agree2 || !agree3 || loading || firstname === '' || lastname === '' || lastNric === '' || streetName === '' || postalCode === '' || cityCountry === ''

  return (
    <StyledSummary>
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <div className="base-container">
          <Header />
          <Row style={{
            marginTop: "5%"
          }}>
            <Col md={8}>
              <p className="title-summary" style={{
                margin: 0,
              }}>Person Living with Dementia Summary</p>
            </Col>
            <Col md={4}>
              <img src={summarypwdImg} className="fluid" alt="Summary PLWD Detail" />
            </Col>
          </Row>
          <Row nogutter style={{ marginTop: '5px', paddingTop: '19px' }}>
            {
              [
                renderField({ 
                  label: 'The Person Living with Dementia is my', 
                  value: relationship,
                  disabled: false,
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  }) 
                }),
              ]
            }
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <p style={{
                color: '#502773',
                fontWeight: 'bold'
              }}>
                Thank you for filling in your details!
              </p>
              <p style={{
                color: '#502773',
              }}>
                Your physical CARA card will be processed 
                based on the following details:
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <p style={{
                color: '#502773',
                fontWeight: 'bold'
              }}>
                Please confirm your name
              </p>
              <p style={{
                color: '#502773',
              }}>
                This is how your name will be printed on your physical CARA card, please check it matches your NRIC.
              </p>
            </Col>
          </Row>
          <Row nogutter>
             {
               [
                renderField({ 
                  label: 'Name', 
                  value: displayName,
                  desc: 'If this incorrect, please edit it in the box above.',
                  onChange: (e) => {
                    window.sessionStorage.setItem('displayNameNew', e.target.value )
                    handleChange({ stateName: 'displayName', value: e.target.value })
                  }
                }),
                renderField({ 
                  label: 'First Name  ( Given name(s) as per NRIC )', 
                  value: firstname,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'firstname', value: e.target.value })
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     }, 
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Last Name  ( Surename as per NRIC )', 
                  value: lastname,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'lastname', value: e.target.value }) 
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Date of Birth', 
                  value:  moment(birth).format('DD-MM-YYYY'),
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'lastname', value: e.target.value }) 
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })
                }),
               ]
             }    
             {/* <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>{`Date of Birth`}</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }} style={{ width: '100%', }}>
                <DatePicker 
                  peekNextMonth  
                  showMonthDropdown   
                  showYearDropdown  
                  customInput={<CustomInput />}   
                  filterDate = {
                    (date) => { 
                      return moment() > date;
                    }
                  }  
                  dropdownMode= 'select' 
                  dateFormat="dd-MM-yyyy" 
                  selected={birth ? 
                      new Date(birth) 
                    : null
                  } 
                  // onChange={date => handleChange({ stateName: 'birth', value: date, })} 
                  // onClick={() => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) }
                  onClick={ () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })}
                  placeholderText="Please select a date" 
                />
              </Col>
            </Row> */}
            <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>Gender</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }}>
                <Row style={{  }}>
                  <Col xs={6}>
                    <button  className="type" 
                      // onClick={() => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } })} 
                      onClick={ () => history.push('/CareGiverCreateNewPWDDetail', { 
                        ...location.state, 
                        isEdit: true, 
                        editingData: {
                          ...location.state, 
                         fullData: {
                           routeState:{ 
                             fullData: {
                               credential: {
                                 accountData: { 
                                   ...location.state.fullData.routeState.fullData.credential.accountData,
                                   email,
                                   homeNumber,
                                   phone,
                                  }
                               }
                             }
                           }
                         },  
                          editing: true, 
                        } 
                      })}
                    >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'male' ? checkActive : checkInactive} style={{marginRight:'15px'}} /> 
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Male</span>
                      </Row>
                    </button>
                  </Col>
                  <Col xs={6}>
                    <button  className="type" 
                    // onClick={ () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } })} 
                    onClick={ () => history.push('/CareGiverCreateNewPWDDetail', { 
                      ...location.state, 
                      isEdit: true, 
                      editingData: { 
                        ...location.state,
                       fullData: {
                         routeState:{ 
                           fullData: {
                             credential: {
                               accountData: { 
                                 ...location.state.fullData.routeState.fullData.credential.accountData,
                                 email,
                                 homeNumber,
                                 phone,
                                }
                             }
                           }
                         }
                       },  
                        editing: true, 
                      } 
                    })}
                    >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'female' ? checkActive : checkInactive} style={{marginRight:'15px', }} />
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Female</span>
                      </Row>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row> 
            {renderField({ 
              label: 'Last 4 characters of NRIC', 
              value: lastNric,
              disabled: true,
              // onChange: (e) => handleChange({ stateName: 'lastNric', value: e.target.value, }),
              maxLength: 4,
              // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
              onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                ...location.state, 
                isEdit: true, 
                editingData: { 
                  ...location.state,
                 fullData: {
                   routeState:{ 
                     fullData: {
                       credential: {
                         accountData: { 
                           ...location.state.fullData.routeState.fullData.credential.accountData,
                           email,
                           homeNumber,
                           phone,
                          }
                       }
                     }
                   }
                 },  
                  editing: true, 
                } 
              })
            })}
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />
            {
              [
                renderField({ 
                  label: 'Preferred Language of Communication', 
                  value: language,
                  disabled: true,
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Type of Dementia', 
                  value: dementiaType,
                  disabled: true,
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Stage of Dementia', 
                  value: dementiaStage,
                  disabled: true,
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     },  
                      editing: true, 
                    } 
                  })
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />
            {
              [
                renderField({ 
                  label: 'Email Address (Optional)', 
                  value: email,
                  disabled: true,
                  labelDesc: 'Email is our preferred communication as it allows us to assist you with account issues quickly. If you have one, please include it.',
                  // onChange: (e) => handleChange({ stateName: 'email', value: e.target.value }) 
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                    ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     }, 
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Mobile Number (optional)', 
                  value: phone,
                  disabled: true,
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     }, 
                      editing: true, 
                    } 
                  })
                }),
                renderField({ 
                  label: 'Home Number (optional)', 
                  value: homeNumber,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'homeNumber', value: e.target.value }) 
                  // onClick: () => history.push('/CareGiverCreateNewPWDDetail', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDDetail', { 
                    ...location.state, 
                    isEdit: true, 
                    editingData: { 
                      ...location.state,
                     fullData: {
                       routeState:{ 
                         fullData: {
                           credential: {
                             accountData: { 
                               ...location.state.fullData.routeState.fullData.credential.accountData,
                               email,
                               homeNumber,
                               phone,
                              }
                           }
                         }
                       }
                     }, 
                      editing: true, 
                    } 
                  })
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            <Row>
              <Col md={12}>
                <p style={{
                  color:'#502773',
                  fontWeight: 'bold'
                }}>
                  Please confirm this is the address for your CARA card to be posted to
                </p>
                <p style={{
                  color:'#502773',
                }}>If address is not correct, please edit in the boxes.</p>
              </Col>
            </Row>
            {
              [
                renderField({ 
                  label: 'Postal Code', 
                  value: postalCode,
                  // disabled: true,
                  // onChange: (e) => onGetAddress('postalCode', e.target.value)
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                  // onClick: () => {
                  //   console.log('INI BROTHER 1 =>', location.state.fullData.routeState)
                  //   console.log('INI BROTHER 2 =>', location.state)
                  // }
                }),
                renderField({ 
                  label: 'Street Name', 
                  value: streetName,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'streetName', value: e.target.value }),
                  maxLength: 200,
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                }),
                renderField({ 
                  label: 'Building', 
                  value: building,
                  // onChange: (e) => handleChange({ stateName: 'building', value: e.target.value }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                }),
                renderField({ 
                  label: 'Block Number', 
                  value: blockNumber,
                  // onChange: (e) => handleChange({ stateName: 'blockNumber', value: e.target.value })
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } })  
                }),
                renderField({ 
                  label: 'Unit Number', 
                  value: unitNumber,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'unitNumber', value: e.target.value }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                }),
                renderField({ 
                  label: 'City, Country', 
                  value: cityCountry,
                  disabled: true,
                  // onChange: (e) => handleChange({ stateName: 'cityCountry', value: e.target.value }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                }),
                renderField({ 
                  label: 'Address', 
                  value: address,
                  // onChange: (e) => handleChange({ stateName: 'address', value: e.target.value }) 
                  onClick: () => history.push('/CareGiverCreateNewPWDResidingAddress', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, editing: true, } }) 
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />
            {
              [
                renderField({ 
                  label: 'Hospital Name', 
                  value: hospitalName,
                  disabled: true,
                  onClick: () => history.push('/CareGiverCreateNewPWDScreeningQuestion', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                }),
              ]
            }
            {
              [
                renderField({ 
                  label: 'Hospital Departement', 
                  value: hospitalDepartment,
                  disabled: true,
                  onClick: () => history.push('/CareGiverCreateNewPWDScreeningQuestion', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } })  
                }),
              ]
            }
             <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>Currently or have previously used services from Dementia Singapore, formerly known as Alzheimer's Disease Association (ADA)</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }}>
                <Row style={{  }}>
                  <Col xs={6}>
                    <button  className="type" onClick={() => history.push('/CareGiverCreateNewPWDScreeningQuestion', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } })} >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={existingDementiaSingapore ? checkActive : checkInactive} style={{marginRight:'15px'}} /> 
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Yes</span>
                      </Row>
                    </button>
                  </Col>
                  <Col xs={6}>
                    <button  className="type" onClick={ () => history.push('/CareGiverCreateNewPWDScreeningQuestion', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } })} >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={!existingDementiaSingapore ? checkActive : checkInactive} style={{marginRight:'15px', }} />
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> No</span>
                      </Row>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row> 
            {
              [
                renderField({ 
                  label: 'Name 2 Signs & Symptoms Experienced by the Person Living with Dementia', 
                  value: symptoms,
                  disabled: true ,
                  onClick: () => history.push('/CareGiverCreateNewPWDScreeningQuestion', { ...location.state, isEdit: true, editingData: { ...location.state.fullData.routeState, ...location.state, editing: true, } }) 
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            <div>
              <span className="confirm-text">Inaccurate information may affect your application. Please check carefully.</span>
              <div />
              {/* <div className="confirm-text2">By clicking next, you consent to ADA collecting your information for the delivery of service.<br /><br />For more details on our terms of use and privacy policy, please click<a href='/privacy-policy' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}>here</a>.</div> */}
              <div style={{ marginTop: '13px' }}>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">I agree to the <a href="https://cara.sg/home/terms-of-use/" target="_blank" style={{color: '#502773'}}>Terms of Use</a>, <a href="https://cara.sg/home/privacy-policy/" target="_blank" style={{color: '#502773'}}>Privacy Policy</a> and the  <a href="https://cara.sg/home/membership-terms/" target="_blank" style={{color: '#502773'}}>CARA Membership Terms and Conditions</a></div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree2')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">I hereby declare that all information provided by me in this registration form is true and accurate to the best of my knowledge</div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree3')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title" style={{marginLeft: '25px'}}>
                  By clicking NEXT, I agree to the collection, use and/or disclosure of the entered personal data for the purposes of processing the CARA membership application and providing services associated with the CARA membership. This includes, but is not limited to, sending me marketing information about new CARA features, notification of marketing events, initiatives and promotions, lucky draws, membership and rewards schemes and other promotions.
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', margin: '57px 0 120px' }}>
              <div>
                <Button
                  text="Back"
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                    color: #502773;
                  `}
                  bordered
                  onClick={() => history.goBack()}
                />
              </div>
              <div style={{ width: '21px', }} />
              <div>
                <Button
                  text={loading ? "Loading..." : "Next"}
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                  `}
                  disabled={buttonDisabled}
                  onClick={() => {
                    history.push('/lastPageOfCgCreatePwd', state)
                  }}
                />
              </div>
            </div>

          </Row>
        </div>
      </Container>

    </StyledSummary>
  )
}

export default pwdSummaryNew
