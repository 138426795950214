import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2'

import Input from '../../components/input'
import Header from '../../components/header2'

import ProccessData from '../../lib/ProccessData'

import { verifyPartnerToken, submitPartner } from '../../api/UserAPI'

import { StyledSigninPartner, InputStyle, } from './Styles/StyledSigninPartner'

import AppConfig from '../../config'

import smallInfo from '../../assets/images/small-info.png'

function SigninPartner({
  match,
  history,
}) {
  const [state, setState] = useState({
    paramsSubmit: {
      "username": "",
      "password": "",
      "confirmPassword": ""
    },
    loading: false,
    agree: false,
  })

  const { username, password, confirmPassword } = state.paramsSubmit

  const buttonDisabled = username === '' || password === '' || confirmPassword === '' || state.loading || !state.agree || password.length < 6 || confirmPassword.length < 6

  useEffect(() => {
    checkToken()
    return () => {
      
    }
  }, [])

  const checkToken = async () => {
    try {
      const { token } = match.params
      let resultCheck = {}
      resultCheck .payload = await verifyPartnerToken({ headers: {
        Authorization: `bearer ${token}`
      }})
      ProccessData(resultCheck  , 'check partner token')
      .then(res => {
        console.log('res => ', res)
        if (res.data.account.credentialSubmitted) {
          // alertError('Your credential already submitted, please signin on partner dashboard', false, () => {
          //   window.open(`${AppConfig.ADMIN_HOSTNAME}/login`, '_self')
          // })
          alertError('Your registration has been completed, sign-in to CARA mobile with these credentials.', false, () => {
            window.open(`${AppConfig.ADMIN_HOSTNAME}/login`, '_self')
          })
        }
      })
      .catch(err => {
        console.log('err => ', err)
        alertError(err)
      })
    } catch (e) {
      alertError(e)
    }
  }

  const alertError = (text, exit = true, preConfirm = () => null) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log('exit => ', exit)
        exit ? history.push('/') : preConfirm()
      },
    })
  }

  const onChangeText = (name, value) => {
    setState(state => {  
      const { paramsSubmit } = state;

      paramsSubmit[name] = value;

      return {
        ...state,
        paramsSubmit: {
          ...state.paramsSubmit,
          paramsSubmit,
        }
      }
    })
  }

  const submit = async () => {
    setState(state => ({ ...state, loading: true }))
    try {
      const { token } = match.params
      if (password !== confirmPassword) {
        alertError(`Password & Confirm Password not match`, false)
        setState(state => ({ ...state, loading: false }))
        return false
      } 
      let resultSubmit = {}
      resultSubmit.payload = await submitPartner({ 
        headers: {
          Authorization: `${token}`
        },
        data: state.paramsSubmit,
      })
      ProccessData(resultSubmit  , 'Submit Partner')
      .then(res => {
        if (res.type === 'SUCCESS' && res.data.token) {
          window.open(`${AppConfig.ADMIN_HOSTNAME}/token/${res.data.token}`, '_self');
          // history.push('/')
        }
      })
      .catch(err => {
        console.log('err => ', err)
        alertError(err, false)
      })
    } catch (e) {
      alertError(e, false)
    }
    setState(state => ({ ...state, loading: false }))
  }

  const _handleCheckbox = (e, agree) => {
    let checked = e.target.checked;
    setState(state => ({
      ...state,
      [agree]: checked
    }))
  } 

  return (
    <StyledSigninPartner buttonDisabled={buttonDisabled}>
      <Header />
      <p className="title-submit">Enter your partner username & password</p>
      <p className="title-submit2">For standardisation, we recommend the following Username configuration</p>
      <div className="form-container"> 
        <div className="input-container">
          <div>
            <label for='username' className="label-partner">Username</label>
          </div>
          <div style={{ width: '100%' }}>
            <Input 
              containerStyle={InputStyle} 
              inputProps={{
                id: 'username',
                maxLength: 30,
                onChange: (e) => onChangeText('username', e.target.value),
                placeholder: 'OrgName_ServiceType_Location'
            }}/>
            <div className="small-info-container">
              <div>
                <img src={smallInfo} alt="info" className="small-info-image" />
              </div>
              <div className="small-info-text" style={{ marginRight: '17px', }}>
                Example
              </div>
              <div>
                <div className="small-info-text">Dementia Singapore, New Horizon Centre ( Bukit Batok )</div>
                <div className="small-info-text">Will be : DSG_NHC_BB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-container">
          <div>
            <label for='password' className="label-partner">Password</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'password',
              type: 'password',
              maxLength: 30,
              onChange: (e) => onChangeText('password', e.target.value),
              placeholder: 'Enter your password'
          }}/>
        </div>
        <div className="input-container">
          <div>
            <label for='confirm-password' className="label-partner">Confirm Password</label>
          </div>
          <Input 
            containerStyle={InputStyle} 
            inputProps={{
              id: 'confirm-password',
              type: 'password',
              maxLength: 30,
              onChange: (e) => onChangeText('confirmPassword', e.target.value),
              placeholder: 'Enter your password confirmation'
          }}/>
        </div>
      </div>
      <div className="agree-container">
        <div className="agree-text" style={{ color: `#502773`, marginBottom: '14px' }}>*By clicking “Submit”, I have verified that the Partnering organisation agrees to create a CARA “Care Professional” membership account, and will be bounded by the CARA Membership Terms, CARA Privacy Policy, and CARA Terms of Use (“CARA documents”)</div>
        <div className="agree-text">All information (including details of their dementia clients) uploaded by partnering organisations will be deemed as accurate at the point of upload. It is the responsibility of partnering organisations to ensure the accuracy of all information uploaded. Dementia Singapore reserves the right to verify any information uploaded by partnering organisations. Dementia Singapore reserves the right to remove the CARA membership of any dementia client if their information is inaccurate.​</div>
        <div class="checkbox-container">
          <label class="checkbox-label">
              <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree')} />
              <span class="checkbox-custom rectangular"></span>
          </label>
          <div class="input-title"> I agree to the <a href='/Terms-Of-Use' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Terms of Use</a>,<a href='/Privacy-Policy' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Privacy Policy</a> and the<a href='/Membership-Terms' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>CARA Membership Terms and Conditions</a></div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', }}>
        <div style={{ width: '15px', height: 1 }} />
        <div className="button-register" onClick={() => {
          buttonDisabled ? null : submit()
        }}>
          <div className="button-register-text">{state.loading ? `Loading...` : `Submit`}</div>
        </div>
      </div>
    </StyledSigninPartner>
  )
}

export default SigninPartner
