import React, { useState, useEffect } from 'react'
import moment from 'moment';
import Swal from 'sweetalert2'
import axios from 'axios'
import { Container, Row, Col, } from 'react-grid-system'
import DatePicker from "react-datepicker";
import summaryCgImg from '../../assets/images/summary-cg-img.png'

import Header from '../../components/header2'
import Input from '../../components/input'
import Button from '../../components/button'
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBox from '../../components/modal'

import checkActive from '../../assets/images/ACTIVE.png'
import checkInactive from '../../assets/images/INACTIVE.png'

import { StyledSummary, Label } from '../styles/StyledSummary'

import AppConfig from '../../config'

import { updateInvitation, } from '../../api/UserAPI'
import { getAddress } from '../../api/UserAPI';

import ProccessData from '../../lib/ProccessData'

const accountUrl = AppConfig.ACCOUNT_URL

const inputPointerStyle = `
  background: #FFFFFF;
  cursor: pointer;
  .input-field {
    cursor: pointer;
  }
`

const inputStyle = `
  background: #FFFFFF;
  cursor: pointer;
`

function SummaryPLWD({
  location,
  history,
}) {
  const [state, setState] = useState({
    agree: false,
    agree2: false,
    agree3: false,
    loading: false,
    uid: '',
    token: '',
    showError: false,
    errorMessage: '',
    showSuccess: false,
    accountData: {}
  })

  const { agree, agree2, agree3, accountData, uid, token, showError, errorMessage, showSuccess, loading, } = state
  const { credential, partnerToken, } = location.state
  const { credentialType, credentialData, } = credential
  const { idTokenGoogle, username, password, } = credential.credentialData
  const { 
    firstname,
    lastname,
    gender,
    lastNric,
    birth,
    email,
    phone,
    homeNumber,
    streetName,
    unitNumber,
    blockNumber,
    address,
    building,
    postalCode,
    cityCountry,
    prefixCountryNum,
  } = accountData

  useEffect(() => {
    if (credential) {
      setState(state => ({
        ...state,
        accountData: credential.accountData,
      }))
    }
  }, [])

  const renderField = ({ label, value, onChange, maxLength = 50, onClick }) => {
    return (
      <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
        <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
          <Label>{label}</Label>
        </Col>
        <Col md={6} xs={12} offset={{ md: 1, }}>
          <Input
            inputProps={{
              value,
              onChange,
              maxLength,
              onClick,
            }}
            containerStyle={inputStyle}
          />
        </Col>
      </Row>
    )
  }

  const _handleCheckbox = (e, agree) => {
    let checked = e.target.checked;
    setState(state => ({
      ...state,
      [agree]: checked
    }))
  } 

  const handleRegister = (e) => {
    setState(state => ({
      ...state,
      loading: true,
    }))
    const isInvited = window.sessionStorage.getItem("isInvited")
    
    if (credentialType == "GOOGLE") {
      axios.post(`${accountUrl}/public/credential/signupCaregiver`, {
        credential : {
          credentialType,
          credentialData: {
            idTokenGoogle
          },
          accountData: {
            username,
            password,
            prefixCountryNum,
            phone,
            email,
            firstname,
            lastname,
            gender,
            birth,
            streetName,
            unitNumber,
            postalCode,
            cityCountry,
            building,
            blockNumber,
            address,
            interests: "-",
            activityPreferences: "-",
            alreadyLoggedIn: true,
            homeNumber,
            lastNric,
            metadata: isInvited && isInvited !== "null" ?
              {
                caraPro: true
              }
              :
              {
                caraPro: false,
              }
          }
        } 
      })
      .then(async res => {
        if(res.status == 200 && res.data.type == "SUCCESS"){
          if (isInvited && isInvited !== "null") {
            const token = res.data.data.account.token
            try {
              let resultUpdate = {}
              resultUpdate.payload = await updateInvitation({ 
                headers: {
                  Authorization: `${token}`
                },
                data: {
                  invitedUid: res.data.data.account.uid,
                },
                params: {
                  id: isInvited,
                }
              })
              ProccessData(resultUpdate  , 'update invitation')
              .then(res => {
                console.log('res edit => ', res)
              })
              .catch(err => {
                console.log('err => ', err)
              })
            } catch (e) {
              console.log('err => ', e)
            }
          }
          sessionStorage.clear();
          setState(state => ({
            ...state,
            showSuccess: true,
            loading: false,
            uid: res.data.data.account.uid,
            token: res.data.data.account.token
          }))
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
          setState(state => ({
            ...state,
            loading: false,
          }))
        };
      }, (error) => {
        console.log(error);
        setState(state => ({
          ...state,
          loading: false,
        }))
      });
    } 
    
    else {
      axios.post(`${accountUrl}/public/credential/signupCaregiver`, {
        credential : {
          credentialType,
          credentialData: {
            username,
            password,
          },
          accountData: {
            username,
            password,
            prefixCountryNum,
            phone,
            email,
            firstname,
            lastname,
            gender,
            birth,
            streetName,
            unitNumber,
            postalCode,
            cityCountry,
            building,
            blockNumber,
            address,
            interests: "-",
            activityPreferences: "-",
            homeNumber,
            lastNric,
            alreadyLoggedIn: true,
            metadata: isInvited && isInvited !== "null" ?
              {
                caraPro: true
              }
              :
              {
                caraPro: false
              }
          }
        }
      })
      .then(async res => {
        if (res.status == 200 && res.data.type == "SUCCESS") {
          if (isInvited && isInvited !== "null") {
            const token = res.data.data.account.token
            try {
              let resultUpdate = {}
              resultUpdate.payload = await updateInvitation({ 
                headers: {
                  Authorization: `${token}`
                },
                data: {
                  invitedUid: res.data.data.account.uid,
                },
                params: {
                  id: isInvited,
                }
              })
              ProccessData(resultUpdate  , 'update invitation')
              .then(res => {
                console.log('res edit => ', res)
              })
              .catch(err => {
                console.log('err => ', err)
              })
            } catch (e) {
              console.log('err => ', e)
            }
          }
          sessionStorage.clear();
          
          setState(state => ({
            ...state,
            showSuccess: true,
            loading: false,
            uid: res.data.data.account.uid,
            token: res.data.data.account.token
          }))
        }
        else {
          setState(state => ({
            ...state,
            loading: false,
          }))
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
        setState(state => ({
          ...state,
          loading: false,
        }))
      });
    }
  } 

  const handleClose = () => {
    history.push({pathname:'/CareGiverCreateNewPWDORExisting',
      state: {
        uid,
        username,
        password,
        phone,
        firstname,
        lastname,
        gender,
        birth,
        streetName,
        unitNumber,
        postalCode,
        cityCountry,
        building,
        blockNumber,
        address,
        uid,
        token,
        homeNumber,
        phone,
        partnerToken,
        credential : {
          credentialType,
          credentialData,
          accountData
        }
      }})
      return state
  }

  const handleChange = ({ stateName, value, }) => {
    setState(state => ({
      ...state,
      accountData: {
        ...state.accountData,
        [stateName]: value
      }
    }))
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <Input
        inputProps={{
          value: props.value || props.placeholder,
          onClick: props.onClick, 
          ref,
        }}
        containerStyle={`
          ${inputPointerStyle}
          width: 190%;
        `}
      />
    );
  });

  const onGetAddress = async ({ stateName, value }) => {
    handleChange({ stateName: stateName, value })

    try {
      let resultGet = {}
      resultGet.payload = await getAddress({
        searchVal: `${value}`,
        returnGeom: `Y`,
        getAddrDetails: `Y`,
      })
      resultGet.payload.type = 'SUCCESS'
      ProccessData(resultGet, 'get address')
      .then(res => {
        if (res && res.data && res.data.results && res.data.results.length > 0) {
          setState(states => {
  
            if(states && states.accountData) {
              return {
                ...states,
                accountData: {
                  ...states.accountData,
                  address: `${res.data.results[0].ADDRESS.replace(value, '')}`,
                  streetName: `${res.data.results[0].ROAD_NAME}`,
                  building: `${res.data.results[0].BUILDING}`,
                  blockNumber: `${res.data.results[0].BLK_NO}`
                }
              }
            } else {
              return {
                ...states
              }
            }
          })
        }
      })
      .catch(err => {
        alertError(err)
      })
    } catch (e) {
      alertError(e)
    }
  }

  const buttonDisabled = !agree || !agree2 || !agree3 || loading || firstname === '' || lastname === '' || lastNric === '' || streetName === '' || postalCode === '' || cityCountry === ''

  return (
    <StyledSummary>
       <PopUpBox  
        body={'Great! Thanks for submitting your details! ​'} 
        show={showSuccess}  
        handleClose={handleClose}
      />
      <PopUpBoxHandler 
        show={showError} 
        text={errorMessage} 
        handleClose={() => {
          setState(state => ({
              ...state,
              showError: false
            })
          )
        }} 
      />
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <div className="base-container">
          <Header />
          <Row style={{
            marginTop: "5%"
          }}>
            <Col md={8}>
              <p className="title-summary" style={{
                margin: 0,
              }}>Summary of details</p>
            </Col>
            <Col md={4}>
              <img src={summaryCgImg} className="fluid" alt="Summary Caregiver Detail" />
            </Col>
          </Row>
          <Row nogutter style={{ marginTop: '5px', paddingTop: '19px' }}>
             {
               [
                renderField({ 
                  label: 'First Name', 
                  value: firstname,
                  // onChange: (e) => handleChange({ stateName: 'firstname', value: e.target.value })
                  onClick: () => history.push('CareGiverPersonDetail', {...state.accountData, idTokenGoogle,  ...location.state, isEditing: true}),
                }),
                renderField({ 
                  label: 'Last Name (Surname)', 
                  value: lastname,
                  // onChange: (e) => handleChange({ stateName: 'lastname', value: e.target.value }) 
                  onClick: () => history.push('CareGiverPersonDetail', {...state.accountData, idTokenGoogle,  ...location.state, isEditing: true}),
                }),
               ]
             }    
            <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>Gender</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }}>
                <Row style={{  }}>
                  <Col xs={6}>
                    <button  
                      className="type" 
                        // onClick={() => (e) => handleChange({ stateName: 'gender', value: 'male' })}
                        onClick={() => history.push('CareGiverPersonDetail', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true})}
                      >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'male' ? checkActive : checkInactive} style={{marginRight:'15px'}} /> 
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Male</span>
                      </Row>
                    </button>
                  </Col>
                  <Col xs={6}>
                    <button  
                      className="type" 
                      // onClick={(e) => handleChange({ stateName: 'gender', value: 'female' })}
                      onClick={() => history.push('CareGiverPersonDetail', {...state.accountData,   idTokenGoogle, ...location.state, isEditing: true})}
                    >
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'female' ? checkActive : checkInactive} style={{marginRight:'15px', }} />
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Female</span>
                      </Row>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row> 
            {renderField({ 
              label: 'Last 4 characters of NRIC', 
              value: lastNric,
              // onChange: (e) => handleChange({ stateName: 'lastNric', value: e.target.value, }),
              onClick: () => history.push('CareGiverPersonDetail', {...state.accountData,  idTokenGoogle, ...location.state, isEditing: true}),
              maxLength: 4, 
            })}
            {renderField({ 
              label: 'Date of Birth', 
              value: moment(birth).format('DD-MM-YYYY'),
              // onChange: (e) => handleChange({ stateName: 'lastNric', value: e.target.value, }),
              onClick: () => history.push('CareGiverPersonDetail', {...state.accountData,  idTokenGoogle, ...location.state, isEditing: true}),
              // maxLength: 4, 
            })}
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />
            {
              [
                renderField({ 
                  label: 'Email Address', 
                  value: email,
                  // onChange: (e) => handleChange({ stateName: 'email', value: e.target.value }) 
                  onClick: () => history.push('CareGiverPersonDetail', {...state.accountData,  idTokenGoogle, ...location.state, isEditing: true}),
                }),
                renderField({ 
                  label: 'Home Number (optional)', 
                  value: homeNumber,
                  // onChange: (e) => handleChange({ stateName: 'homeNumber', value: e.target.value }) 
                  onClick: () => history.push('CareGiverPersonDetail', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}),
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            {
              [
                renderField({ 
                  label: 'Postal Code', 
                  value: postalCode,
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true})
                  // onChange: (e) => onGetAddress({ stateName: 'postalCode', value: e.target.value }) 
                }),
                renderField({ 
                  label: 'Street Name', 
                  value: streetName,
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}),
                  // onChange: (e) => handleChange({ stateName: 'streetName', value: e.target.value }),
                  maxLength: 200,
                }),
                renderField({ 
                  label: 'Building', 
                  value: building,
                  // onChange: (e) => handleChange({ stateName: 'building', value: e.target.value }) 
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}),
                }),
                renderField({ 
                  label: 'Block Number', 
                  value: blockNumber,
                  // onChange: (e) => handleChange({ stateName: 'blockNumber', value: e.target.value }) 
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}),
                }),
                renderField({ 
                  label: 'Unit Number', 
                  value: unitNumber,
                  // onChange: (e) => handleChange({ stateName: 'unitNumber', value: e.target.value })
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}), 
                }),
                renderField({ 
                  label: 'City, Country', 
                  value: cityCountry,
                  // onChange: (e) => handleChange({ stateName: 'cityCountry', value: e.target.value })
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,  idTokenGoogle,  ...location.state, isEditing: true}), 
                }),
                renderField({ 
                  label: 'Address', 
                  value: address,
                  // onChange: (e) => handleChange({ stateName: 'address', value: e.target.value }) 
                  onClick: () => history.push('CareGiverResidingAddress', {...state.accountData,   idTokenGoogle, ...location.state, isEditing: true}),
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            <div>
              <span className="confirm-text">Inaccurate information may affect your application. Please check carefully.</span>
              <div />
              {/* <div className="confirm-text2">By clicking next, you consent to ADA collecting your information for the delivery of service.<br /><br />For more details on our terms of use and privacy policy, please click<a href='/privacy-policy' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}>here</a>.</div> */}
              <div style={{ marginTop: '13px' }}>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">I agree to the <a href="https://cara.sg/home/terms-of-use/" target="_blank" style={{color: '#502773'}}>Terms of Use</a>, <a href="https://cara.sg/home/privacy-policy/" target="_blank" style={{color: '#502773'}}>Privacy Policy</a> and the  <a href="https://cara.sg/home/membership-terms/" target="_blank" style={{color: '#502773'}}>CARA Membership Terms and Conditions</a></div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree2')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">I hereby declare that all information provided by me in this registration form is true and accurate to the best of my knowledge</div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree3')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title" style={{marginLeft: '25px'}}>
                  By clicking NEXT, I agree to the collection, use and/or disclosure of the entered personal data for the purposes of processing the CARA membership application and providing services associated with the CARA membership. This includes, but is not limited to, sending me marketing information about new CARA features, notification of marketing events, initiatives and promotions, lucky draws, membership and rewards schemes and other promotions.
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', margin: '57px 0 120px' }}>
              <div>
                <Button
                  text="Back"
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                    color: #502773;
                  `}
                  bordered
                  onClick={() => history.goBack()}
                />
              </div>
              <div style={{ width: '21px', }} />
              <div>
                <Button
                  text={loading ? "Loading..." : "Next"}
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                  `}
                  disabled={buttonDisabled}
                  onClick={handleRegister}
                />
              </div>
            </div>

          </Row>
        </div>
      </Container>

    </StyledSummary>
  )
}

export default SummaryPLWD
