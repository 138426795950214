import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG} from "./StyledCG";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import styled from 'styled-components';
import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import secondStep from '../../assets/images/second-step-cg-create-plwd.png'
import AppConfig from '../../config'
import { getAddress } from '../../api/UserAPI';
import ProccessData from '../../lib/ProccessData';
import Swal from 'sweetalert2'

export class pwdResidingAddress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
         
      username: this.props.location.state.username,
      password: this.props.location.state.password,
      phone: this.props.location.state.phone,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      displayName: this.props.location.state.displayName,
      gender : this.props.location.state.gender,
      birth: this.props.location.state.birth,
      NIRC:this.props.location.state.NIRC,
      language: this.props.location.state.language,
      dementiaType: this.props.location.state.dementiaType,
      dementiaStage: this.props.location.state.dementiaStage,
      uid:this.props.location.state.uid,

      relationship:'',
      relationshipStatus:'',
      showError:false,
      errorMessage:'',
      streetName:'',
      unitNumber:'',
      postalCode:'',
      blockNumber: '',
      building: '',
      address: '',
      cityCountry:'',
        Switch: SwitchNo,
          show: false,
        }

      }
      
      // componentDidMount(){
      //   if(window.sessionStorage.getItem("streetName") != '' && window.sessionStorage.getItem("streetName") != 'null'){
      //     this.setState({streetName: window.sessionStorage.getItem("streetName")})
     
      //   }
      //    if(window.sessionStorage.getItem("unitNumber") != '' && window.sessionStorage.getItem("unitNumber") != 'null'){
      //     this.setState({unitNumber: window.sessionStorage.getItem("unitNumber")})
     
      //   }
      //   if(window.sessionStorage.getItem("postalCode") != '' && window.sessionStorage.getItem("postalCode") != 'null'){
      //     this.setState({postalCode: window.sessionStorage.getItem("postalCode")})
     
      //   }
      //   if(window.sessionStorage.getItem("cityCountry") != '' && window.sessionStorage.getItem("cityCountry") != 'null'){
      //     this.setState({cityCountry: window.sessionStorage.getItem("cityCountry")})
      //   }
      // if(window.sessionStorage.getItem("relationshipStatus") != ''&& window.sessionStorage.getItem("relationshipStatus") != 'null'){
      //   this.setState({relationshipStatus: window.sessionStorage.getItem("relationshipStatus")})
      // }
      // if(relationshipStatusData[window.sessionStorage.getItem("relationship")]!= null){
      //   this.setState({relationship: relationshipStatusData[window.sessionStorage.getItem("relationshipStatus")].value})
      // }
      // }

      componentDidMount() {
        if (this.props.history.location.state.isEdit && this.props.history.location.state.editingData) {
          if (String(this.props.history.location.state.editingData.fullData.postalCode) === String(this.props.history.location.state.postalCode)) {
            this.setState(() => ({
              Switch: SwitchYes,
              postalCode: this.props.history.location.state.editingData.fullData.postalCode,
              blockNumber: this.props.history.location.state.editingData.fullData.blockNumber,
              building: this.props.history.location.state.editingData.fullData.building,
              streetName: this.props.history.location.state.editingData.fullData.streetName,
              address: this.props.history.location.state.editingData.fullData.address,
              unitNumber: this.props.history.location.state.editingData.fullData.unitNumber,
              cityCountry: this.props.history.location.state.editingData.fullData.cityCountry,
            }));
          } else {
            if(this.props.history.location.state.editingData2) {
              this.setState(() => ({
                Switch: SwitchNo,
                postalCode: this.props.history.location.state.editingData2.postalCode,
                blockNumber: this.props.history.location.state.editingData2.blockNumber,
                building: this.props.history.location.state.editingData2.building,
                streetName: this.props.history.location.state.editingData2.streetName,
                address: this.props.history.location.state.editingData2.address,
                unitNumber: this.props.history.location.state.editingData2.unitNumber,
                cityCountry: this.props.history.location.state.editingData2.cityCountry,
              }));
            } else {
              this.setState(() => ({
                Switch: SwitchNo,
                postalCode: this.props.history.location.state.postalCode,
                blockNumber: this.props.history.location.state.blockNumber,
                building: this.props.history.location.state.building,
                streetName: this.props.history.location.state.streetName,
                address: this.props.history.location.state.address,
                unitNumber: this.props.history.location.state.unitNumber,
                cityCountry: this.props.history.location.state.cityCountry,
              }));
            }
          }
        } else {
          this.setState(() => ({
            postalCode: '',
            blockNumber: '',
            building: '',
            streetName: '',
            address: '',
            unitNumber: '',
            cityCountry: '',
          }));
        }
      }

    handleShow = (e)=> {
    
     this.setState({show: true})
    }
 changeSwitch=()=>{
    if(this.props.history.location.state.isEdit || this.props.history.location.state.editingData2) {
      if(this.state.Switch== SwitchNo ) {
        // window.alert('itu')
        this.setState({
          Switch: SwitchYes,
          postalCode: this.props.history.location.state.editingData.fullData.postalCode,
          blockNumber: this.props.history.location.state.editingData.fullData.blockNumber,
          building: this.props.history.location.state.editingData.fullData.building,
          streetName: this.props.history.location.state.editingData.fullData.streetName,
          address: this.props.history.location.state.editingData.fullData.address,
          unitNumber: this.props.history.location.state.editingData.fullData.unitNumber,
          cityCountry: this.props.history.location.state.editingData.fullData.cityCountry,
        })
      } else if(this.state.Switch== SwitchYes ) { 
        // window.alert('ini')
        // this.setState({
        //   Switch: SwitchNo, 
        //   postalCode: this.props.history.location.state.postalCode,
        //   blockNumber: this.props.history.location.state.blockNumber,
        //   building: this.props.history.location.state.building,
        //   streetName: this.props.history.location.state.streetName,
        //   address: this.props.history.location.state.address,
        //   unitNumber: this.props.history.location.state.unitNumber,
        //   cityCountry: this.props.history.location.state.cityCountry,
        // })
        this.setState({Switch: SwitchNo, 
          streetName:'',
          unitNumber:'',
          postalCode:'',
          address: '',
          blockNumber: '',
          building: '',
          cityCountry:'',
        })
      }
    } else {
      if(this.state.Switch== SwitchNo ) {
        this.setState({Switch: SwitchYes,
          streetName:this.props.location.state.streetName,
          unitNumber:this.props.location.state.unitNumber,
          postalCode:this.props.location.state.postalCode,
          building: this.props.location.state.fullData.building,
          blockNumber: this.props.location.state.fullData.blockNumber,
          address: this.props.location.state.fullData.address,
          cityCountry:this.props.location.state.cityCountry,
        })
      } else if(this.state.Switch== SwitchYes ) { 
        this.setState({Switch: SwitchNo, 
          streetName:'',
          unitNumber:'',
          postalCode:'',
          address: '',
          blockNumber: '',
          building: '',
          cityCountry:'',
        })
      }
    }
  }
  submitForm = ()=>{
    if((this.state.streetName =='' ) || window.sessionStorage.getItem("streetName") == '' || window.sessionStorage.getItem("streetName") == 'null' || (this.state.postalCode =='' )  || window.sessionStorage.getItem("postalCode") == '' || window.sessionStorage.getItem("postalCode") == 'null' || (this.state.cityCountry =='' )  || window.sessionStorage.getItem("cityCountry") == '' || window.sessionStorage.getItem("cityCountry") == 'null'  ){
      Swal.fire({
        title: 'Warning!',
        text: 'please fill the required data ',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    }else{
      
    window.sessionStorage.setItem("streetName",this.state.streetName)
    window.sessionStorage.setItem("unitNumber",this.state.unitNumber)
    window.sessionStorage.setItem("postalCode",this.state.postalCode)
    window.sessionStorage.setItem("cityCountry",this.state.cityCountry)
    window.sessionStorage.setItem("Switch",this.state.Switch)
    
    if (this.props.history.location.state.editingData && !this.props.history.location.state.editingData2) {
      this.props.history.push({pathname:'/CareGiverCreateNewPWDScreeningQuestion',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          relationship: this.props.history.location.state.relationship,
          relationshipStatus: this.props.history.location.state.relationshipStatusData,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          building:this.state.building,
          blockNumber:this.state.blockNumber,
          address:this.state.address,
          uid:this.state.uid,
          token:this.state.token,
          fullData: { state: { ...this.state }, routeState: { ...this.props.history.location.state.editingData } },
          editingData: { 
            ...this.props.history.location.state
          },
        }})
      console.log('TEST =>', this.props.history.location.state.editingData)
    } else if (this.props.history.location.state.editingData2) {
      this.props.history.push({pathname:'/CareGiverCreateNewPWDScreeningQuestion',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          relationship: this.props.history.location.state.relationship,
          relationshipStatus: this.props.history.location.state.relationshipStatusData,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          building:this.state.building,
          blockNumber:this.state.blockNumber,
          address:this.state.address,
          uid:this.state.uid,
          token:this.state.token,
          fullData: { state: { ...this.state }, routeState: { ...this.props.history.location.state.editingData } },
          editingData: { 
            ...this.props.history.location.state
          },
          editingData2: { 
            ...this.props.history.location.state.editingData2
          },
        }})
      console.log('TEST =>', this.props.history.location.state.editingData)
    } else {
      this.props.history.push({pathname:'/CareGiverCreateNewPWDScreeningQuestion',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          relationship: this.props.history.location.state.relationship,
          relationshipStatus: this.props.history.location.state.relationshipStatusData,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          building:this.state.building,
          blockNumber:this.state.blockNumber,
          address:this.state.address,
          uid:this.state.uid,
          token:this.state.token,
          fullData: { state: { ...this.state }, routeState: { ...this.props.history.location.state } },
        }})
    }
    
    return this.state
    }
  }
  
onChangeText = (stateName, value) => {
  this.setState({ [stateName]: value });
};
    
  handleCloseError =()=>{
    this.setState({showError:false})
  }
back=()=>{
  this.props.history.push({
    pathname: '/CareGiverCreateNewPWDDetail',
    state: {
      uid:this.state.uid,
      token:this.state.token,
      password: this.state.password,
      credentialType: this.state.credentialType, 
      username: this.state.username,
      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      NIRC: this.state.NIRC,
    }
  });
}

alertError = (text, exit = true) => {
  Swal.fire({
    title: 'Error!',
    text,
    icon: 'error',
    confirmButtonText: 'OK',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    preConfirm: () => {
      console.log('exit => ', exit)
      if (exit) {
        this.props.history.replace('/')
      } else null
    },
  })
}

onGetAddress = async (name, value) => {
  this.onChangeText(name, value)

  try {
    let resultGet = {}
    resultGet.payload = await getAddress({
      searchVal: `${value}`,
      returnGeom: `Y`,
      getAddrDetails: `Y`,
    })
    resultGet.payload.type = 'SUCCESS'
    ProccessData(resultGet, 'get address')
    .then(res => {
      if (res && res.data && res.data.results && res.data.results.length > 0) {
        this.setState(states => {

          states[`address`] = `${res.data.results[0].ADDRESS.replace(value, '')}`;
          states[`streetName`] = `${res.data.results[0].ROAD_NAME}`;
          states[`building`] = `${res.data.results[0].BUILDING}`;
          states[`blockNumber`] = `${res.data.results[0].BLK_NO}`;

          return {
            ...states
          }
        })
      }
    })
    .catch(err => {
      this.alertError(err)
    })
  } catch (e) {
    this.alertError(e)
  }
}
  render() {
      const {Switch,streetName,unitNumber,postalCode,cityCountry, blockNumber, building, address} = this.state
    return (
      <>
        <StyledCG>
            <Header/>
                <Row style={{marginTop:'6%'}}>
                  <Col md={5}>
                    <h2>Person Living with Dementia Residing Address</h2>
                  </Col>
                  <Col md={{ span: 4, offset: 3}}>
                    <img src={secondStep} className="fluid" alt='Second Step' />
                  </Col>
                </Row>
                <Row  style={{textAlign:'start',marginTop:'6%'}}>
                <Col md={6} >
                    <label>Same as Caregiver</label>
                </Col>
                <Col md={6} style={{display:'flex',justifyContent:'flex-start'}}>
                    <button  onClick={this.changeSwitch}>
                            <img src={Switch} />
                    </button>
                </Col>
                </Row>
                <hr />
                <Row style={{marginTop:'1%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Postal Code</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your postal code here...',name:"postalCode",
                                                                    value:postalCode,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onGetAddress('postalCode', e.target.value),}}/>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Street Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your street name here...',name:"streetName",
                                                                    value:streetName,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('streetName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Building</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your building here...',name:"building",
                                                                    value:building,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('building', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Block No</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your block no here...',name:"blockNumber",
                                                                    value:blockNumber,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('blockNumber', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Unit Number</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your unit number here...',name:"unitNumber",
                                                                    value:unitNumber,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('unitNumber', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>City, Country</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your city country here...',name:"cityCountry",
                                                                    value:cityCountry,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('cityCountry', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Address</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your address here...',name:"address",
                                                                    value:address,
                                                                    disabled: this.state.Switch == SwitchYes,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('address', e.target.value),}}/>
                    </Col>
                </Row>
            <Row style={{marginTop:'5%',marginBottom:'5%'}}>
            <Col md={6}></Col>
                 <Col md={3}> 
                {/* <Button containerStyle={StyledButton} textStyle={StyledButton}  onClick={this.back} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" /> */}
                </Col>
                <Col  md={3}>
                
                {/* <Button containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.submitForm}  text="Next" /> */}
                <CustomButton color='#62318C' onClick={this.submitForm}>Next</CustomButton>
               
                </Col>
                </Row>
            </StyledCG>

        
       
      </>
    )
  }
}

const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;


export default pwdResidingAddress