import React, { Component } from 'react'
import ReactGA from 'react-ga';

import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import thirdStepImg from '../../assets/images/third-step-cg-create-plwd.png'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import Swal from 'sweetalert2'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import checklistborder from '../../assets/images/checklistborder.png'
import checklist from '../../assets/images/checklist.png'

import PopUpBox from '../../components/modal'
import axios from 'axios'

import Select from 'react-select'
import AppConfig from '../../config'
import { useHistory } from "react-router-dom";
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBoxConfirmation from '../../components/modalConfirmation';
const accountUrl = AppConfig.ACCOUNT_URL

const relationshipStatusData =
[
      {
        id: 0,
        name: `Uncle / Aunt`,
        value: `Uncle / Aunt`,
        label: `Uncle / Aunt`
      },
      {
        id: 1,
        name:  'Spouse',
        value: `Spouse`,
        label:  `Spouse`
      },
      {
        id: 2,
        name:  'Parent',
        value: `Parent`,
        label:  `Parent`
      },
      {
        id: 3,
        name:  'Grandparent',
        value: `Grandparent`,
        label: `Grandparent`
      },
      {
        id: 4,
        name:  'Child',
        value: `Child`,
        label:  `Child`
      },
      {
        id: 5,
        name: 'Grandchild',
        value: `Grandchild`,
        label:  `Grandchild`
      },
      {
        id: 6,
        name: `Nephew / Niece`,
        value: `Nephew / Niece`,
        label:  `Nephew / Niece`
      },
      {
        id: 7,
        name: 'Others, please specify',
        isCustom: false
      }
    ]


export class CGscreeningQuestion extends Component {
    constructor(props) {
        super(props)
        
        this.state = {   
            username: window.sessionStorage.getItem("username"),
            password: window.sessionStorage.getItem("password"),
            phone: this.props.location.state.phone,
            firstname: this.props.location.state.firstname,
            lastname: this.props.location.state.lastname,
            displayName: this.props.location.state.displayName,
            gender : this.props.location.state.gender,
            birth: this.props.location.state.birth,
            NIRC:this.props.location.state.NIRC,
            streetName:this.props.location.state.streetName,
            unitNumber:this.props.location.state.unitNumber,
            postalCode:this.props.location.state.postalCode,
            cityCountry:this.props.location.state.cityCountry,
            blockNumber:this.props.location.state.blockNumber,
            building:this.props.location.state.building,
            address:this.props.location.state.address,
            language: this.props.location.state.language,
            dementiaType: this.props.location.state.dementiaType,
            dementiaStage: this.props.location.state.dementiaStage,
            uid:this.props.location.state.uid,
            
            hospitalName: "",
            hospitalDepartment: "",
            appointment: "",
            symptoms: "",
            relationship:this.props.history.location.state.relationship,
            relationshipStatus: this.props.history.location.state.relationshipStatus,
            existingDementiaSingapore: this.props.history.location.state.existingDementiaSingapore,
            errorMessage:'',
            showError:false,
            showConfirmation:false,
            agree: true,
            agree1: false,
            agree2: false,
            SwitchYes:TypeInactive,
            SwitchWeekly:TypeInactive,
            SwitchNo:TypeInactive,
            SwitchYearly:TypeInactive,
            fullData: { ...this.props.location.state.fullData },
          
         
          show: false,
        }
      }
      handleShowConfirm=()=>{
        if(this.state.hospitalName =='' || this.state.hospitalDepartment==''|| this.state.symptoms=='' ||(this.state.relationship =='' ) ||      this.state.relationshipStatus=='' ){
          Swal.fire({
              title: 'Warning!',
              text: 'please fill the required data ',
              icon: 'info',
              confirmButtonText: 'OK'
            })
        }else{
          this.setState({showConfirmation:true})
        }
      }
      // componentDidMount(){
      //   if(window.sessionStorage.getItem("password") != ''){
      //     this.setState({password: window.sessionStorage.getItem("password")})
      //   }
      //   if(window.sessionStorage.getItem("hospitalName") != ''){
      //     this.setState({hospitalName: window.sessionStorage.getItem("hospitalName")})
      //   }
      //    if(window.sessionStorage.getItem("hospitalDepartment") != ''){
      //    this.setState({hospitalDepartment: window.sessionStorage.getItem("hospitalDepartment")})
      //  }
      //   if( window.sessionStorage.getItem("existingDementiaSingapore") == 'true'){
      //    this.setState({existingDementiaSingapore: window.sessionStorage.getItem("existingDementiaSingapore") , SwitchYes: TypeActive,})
      //  }
      //  if( window.sessionStorage.getItem("existingDementiaSingapore") == 'false'){
      //   this.setState({existingDementiaSingapore: window.sessionStorage.getItem("existingDementiaSingapore") , SwitchNo: TypeActive,})
      // }
      //   if(window.sessionStorage.getItem("symptoms") != ''){
      //    this.setState({symptoms: window.sessionStorage.getItem("symptoms")})
      //  }
      // }

      componentDidMount() {
        // if (!this.props.history.location.state.editingData) {
        //   this.setState(() => ({
        //     hospitalName: this.props.history.location.state.hospitalName,
        //     hospitalDepartment: this.props.history.location.state.hospitalDepartment,
        //     appointment: this.props.history.location.state.appointment,
        //     symptoms: this.props.history.location.state.symptoms,
        //     SwitchYes: this.props.history.location.state.existingDementiaSingapore ? TypeActive : TypeInactive,
        //     SwitchNo: !this.props.history.location.state.existingDementiaSingapore ? TypeActive : TypeInactive,
        //   }));
        // } else if (this.props.history.location.state.editingData) {
        //   this.setState(() => ({
        //     hospitalName: this.props.history.location.state.editingData.hospitalName,
        //     hospitalDepartment: this.props.history.location.state.editingData.hospitalDepartment,
        //     appointment: this.props.history.location.state.editingData.appointment,
        //     symptoms: this.props.history.location.state.editingData.symptoms,
        //     SwitchYes: this.props.history.location.state.editingData.existingDementiaSingapore ? TypeActive : TypeInactive,
        //     SwitchNo: !this.props.history.location.state.editingData.existingDementiaSingapore ? TypeActive : TypeInactive,
        //   }));
        // } else {
        //   this.setState(() => ({
        //     hospitalName: "",
        //     hospitalDepartment: "",
        //     appointment: "",
        //     symptoms: "",
        //     SwitchYes:TypeInactive,
        //     SwitchNo:TypeInactive,
        //   }));
        // }

        if(this.props.history.location.state.editingData && this.props.history.location.state.editingData.hospitalDepartment && this.props.history.location.state.editingData.hospitalName && !this.props.history.location.state.editingData2) {
          this.setState(() => ({
            hospitalName: this.props.history.location.state.editingData.hospitalName,
            hospitalDepartment: this.props.history.location.state.editingData.hospitalDepartment,
            appointment: this.props.history.location.state.editingData.appointment,
            symptoms: this.props.history.location.state.editingData.symptoms,
            SwitchYes: this.props.history.location.state.editingData.existingDementiaSingapore ? TypeActive : TypeInactive,
            SwitchNo: !this.props.history.location.state.editingData.existingDementiaSingapore ? TypeActive : TypeInactive,
            existingDementiaSingapore: this.props.history.location.state.editingData.existingDementiaSingapore
          }));
        } else if(this.props.history.location.state.editingData2) {
          this.setState(() => ({
            hospitalName: this.props.history.location.state.editingData2.hospitalName,
            hospitalDepartment: this.props.history.location.state.editingData2.hospitalDepartment,
            appointment: this.props.history.location.state.editingData2.appointment,
            symptoms: this.props.history.location.state.editingData2.symptoms,
            SwitchYes: this.props.history.location.state.editingData2.existingDementiaSingapore ? TypeActive : TypeInactive,
            SwitchNo: !this.props.history.location.state.editingData2.existingDementiaSingapore ? TypeActive : TypeInactive,
            existingDementiaSingapore: this.props.history.location.state.editingData2.existingDementiaSingapore
          }));
        }  else {
            this.setState(() => ({
              hospitalName: "",
              hospitalDepartment: "",
              appointment: "",
              symptoms: "",
              SwitchYes:TypeInactive,
              SwitchNo:TypeInactive,
            }));
          }
      }

      handleNo = ()=>{
        window.sessionStorage.setItem("hospitalName", this.state.hospitalName);
        window.sessionStorage.setItem("hospitalDepartment", this.state.hospitalDepartment);
        window.sessionStorage.setItem("existingDementiaSingapore", this.state.existingDementiaSingapore);
        window.sessionStorage.setItem("symptoms",this.state.symptoms);

        window.sessionStorage.setItem("relationshipStatus",this.state.relationshipStatus),

        this.props.history.push({pathname:'/CareGiverCreateNewPWDDetail',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          email: this.state.email,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          idTokenGoogle:this.state.idTokenGoogle,
          credentialType: this.state.credentialType, 
          phone:this.state.phone,
          uid:this.state.uid,
          token:this.state.token,
    
          homeNumber:this.state.homeNumber,
    
        }})
        this.setState({showConfirmation:false})
      }
      handleYes = ()=>{
        this.setState({showConfirmation:false})
       this.handleShow()
      }
      
  handleChangeRelationship =(e)=> {
    this.setState({relationship:e.value})
    console.log('relationship', this.state.relationship )
    this.setState({relationshipStatus:e.id})
  };
  
     handleShow = ()=> {

            axios.post(`${accountUrl}/public/accounts/createdementia`, {
                uid: this.state.uid,
                relationship: this.state.relationship,
                dementia: {
                  credential : {
                    credentialType: "USERNAME",
                    credentialData: {
                      username:this.state.username,
                      password:this.state.password
                    },
                    accountData:{
                      firstname: this.state.firstname,
                      lastname: this.state.lastname,
                      displayName: this.state.displayName,
                      gender: this.state.gender,
                      prefixCountryNum: "0",
                      phone: '0',
                      birth: this.state.birth,
                      lastNric: this.state.NIRC,
                      streetName: this.state.streetName,
                      unitNumber: this.state.unitNumber,
                      postalCode: this.state.postalCode,
                      cityCountry: this.state.cityCountry,
                      
                    existingDementiaSingapore: this.state.existingDementiaSingapore,
                      dementiaDetail: {
                          language: this.state.language,
                          typeDementia: this.state.dementiaType,
                          stageDementia: this.state.dementiaStage
                      },
                      dementiaHospital: {
                          hospitalName: this.state.hospitalName,
                          hospitalDepartment: this.state.hospitalDepartment,
                          appointment: 'DAILY',
                          symptoms: this.state.symptoms
                      },
                      registrationPlatform: "WEB"
                    }
                  }
                }
              })
              .then(res => {
                console.log(res)
                if(res.status==200  && res.data.type=="SUCCESS"){
                  console.log('res data dementia => ', res)
                  // ReactGA.event({
                  //   uid: res.data.data.account.uid,
                  //   action: 'Web landing dementia registered',
                  //   typeRegisteed: 'DEMENTIA',
                  //   registerType: 'BY_CAREGIVER'
                  // });
                  this.setState({show: true})
                  sessionStorage.clear();
                }else{
                  Swal.fire({
                    title: 'Warning!',
                    text: `${res.data.data.message}`,
                    icon: 'info',
                    confirmButtonText: 'OK'
                  })
                };
              }, (error) => {
                console.log(error);
              });
    
    }
    handleClose= ()=>{
        this.setState({show: false})
        this.props.history.push({pathname:'/registrationNotification'})
    }
    onChangeText = (stateName, value) => {
      this.setState({ [stateName]: value });
    };
 
    changeYes=()=>{
      if(this.state.SwitchYes == TypeInactive ){
          this.setState({SwitchYes: TypeActive,existingDementiaSingapore:true, SwitchNo:TypeInactive})
          
      }
  }
  changeNo=()=>{
  if(this.state.SwitchNo == TypeInactive ){
     
      this.setState({SwitchNo: TypeActive,existingDementiaSingapore:false, SwitchYes:TypeInactive})
  }
}
  
    
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
};

agreeSubmit = (e)=>{
       
  if(this.state.agree1){
      this.setState({agree1:false,agree:true})
  }else{
      this.setState({agree1:true}, () => {
          console.log(`agree1: ${this.state.agree1}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit1  = (e)=>{
 
  if(this.state.agree2){
      this.setState({agree2:false,agree:true})
  }else{
  
      this.setState({agree2:true},() => {
          console.log(`agree2: ${this.state.agree2}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit3 = ()=>{
 

  
  if (this.state.agree1 == true && this.state.agree2 == true){
      this.setState({agree:false})

  }
}
back=()=>{
  this.props.history.push({pathname:'/CareGiverCreateNewPWDResidingAddress',
  state:{
    username: this.state.username,
    password: this.state.password,
    firstname: this.state.firstname,
    lastname: this.state.lastname,
    displayName: this.state.displayName,
    gender : this.state.gender,
    NIRC: this.state.NIRC,
    birth: this.state.birth,
    language: this.state.language,
    dementiaType: this.state.dementiaType,
    dementiaStage: this.state.dementiaStage,
    email: this.state.email,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    phone:this.state.phone,
    uid:this.props.location.state.uid,
    token:this.props.location.state.token, 
    homeNumber:this.state.homeNumber,
}}
)
}

handleCloseError =()=>{
  this.setState({showError:false})
}
  render() {
      const {agree,SwitchYes,SwitchWeekly,SwitchNo,SwitchYearly,hospitalName,hospitalDepartment,showError,symptoms}= this.state
    
      return (
      <>
        <StyledCG>
            <Header/>
            <Row style={{marginTop:'6%',marginBottom:'2%'}}>
              <Col md={5}>
                <h2>Screening Questions</h2>
                <p style={{
                  color: '#758BA9',
                  marginTop: '5%',
                  textAlign: 'left',
                  lineHeight: 1.6
                }}>
                  The information provided will help us process your membership.
                </p>
              </Col>
              <Col md={{span: 4, offset: 3}}>
                <img src={thirdStepImg} className="fluid" alt="Third Step" />
              </Col>
            </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Hospital Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type hospital name here...',name:"hospitalName",
                                                                    value:hospitalName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('hospitalName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Hospital Department</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type hospital department here...',name:"hospitalDepartment",
                                                                    value:hospitalDepartment,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('hospitalDepartment', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row style={{marginTop:'10px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label style={{lineHeight:'1.4'}}>Currently or previously used services from Dementia Singapore, formerly known as Alzheimer's Disease Association (ADA)</label>
                    </Col>
                    <Col md={6}>
                    <Row>
                    <Col xs={6}>
                    <button style={{marginRight:'20px',marginBottom:'10px'}} className="type" onClick={this.changeYes}><Row style={{displa:'flex',alignItems:'center'}}><img src={SwitchYes} style={{marginRight:'15px'}} /> Yes</Row></button>
                    </Col>
                    <Col xs={6}>
                    <button style={{marginRight:'20px',marginBottom:'10px'}} className="type" onClick={this.changeNo}><Row style={{displa:'flex',alignItems:'center'}}><img src={SwitchNo} style={{marginRight:'15px'}} /> No</Row></button>
                   
                    </Col></Row></Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Name 2 Signs & Symptoms Experienced by the Person Living with Dementia</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your signs and symptoms here',name:"symptoms",
                                                                    value:symptoms,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('symptoms', e.target.value),}}/>
                    </Col>
                </Row>
                {/* <Row style={{marginTop:'20px'}}>
                    <Col md={6} style={{margin:'auto'}}>
                        <label>This Person Living with Dementia is my </label>
                    </Col>
                    <Col md={6} >
                    <Select  onChange={(e) =>  this.handleChangeRelationship(e)} defaultValue={relationshipStatusData[window.sessionStorage.getItem("relationshipStatus")] } options={relationshipStatusData} />
                    </Col>
                    
                </Row> */}
                {/* <Row style={{lineHeight:'18px'}}>
                    <Col md={6}></Col>
                    <Col md={6} className='p'>
                    <Row style={{paddingLeft:'5%',marginTop:'3%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>* By clicking next, you consent to DSG collecting your information for the delivery of service.</p> </Row>
                       <Row style={{marginTop:'2%',paddingLeft:'5%', marginBottom:'2%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>For more details on our terms of use and privacy policy, please  click </p> <a href='/privacy-policy' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}>here</a> .</Row>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                       <Row style={{paddingLeft:'15px'}}><Col md={1}> <label className="containerCheck"><input type="checkbox" onClick={this.agreeSubmit}  /><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px'}}>I agree to the terms of use.</p></Col>
                       </Row>
                       <Row style={{paddingLeft:'15px',marginTop:'3%'}}><Col md={1}>  <label  className="containerCheck" ><input type="checkbox" onClick={(e) => this.agreeSubmit1(e)}/><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px',textAlign:'start'}}>I hereby declare that the details furnished above are true and correct to the best of my knowledge.</p></Col>
                       </Row>
                    </Col>
                </Row> */}

                <hr/>
            <Row style={{marginTop:'3%',marginBottom:'5%'}}>
            <Col md={6}></Col>
                 <Col md={3}> 
                {/* <Button containerStyle={StyledButton} textStyle={StyledButton}  onClick={this.back} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" /> */}
                </Col>
                <Col  md={3}>
                <Button containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} disabled={!this.state.hospitalName || !this.state.hospitalDepartment || !this.state.symptoms} onClick={() => {
                  this.props.history.push('/pwd-summary-new', {...this.state})
                }} text="Next"  />
                <PopUpBoxConfirmation showConfirmation={this.state.showConfirmation} handleNo={this.handleNo} handleYes={this.handleYes}/>
                <PopUpBox body='Thanks for submitting details for the Person Living with Dementia!' show={this.state.show}  handleClose={this.handleClose}/>
                <PopUpBoxHandler  show={showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                </Col></Row>
                
            </StyledCG>

        
       
      </>
    )
  }
}


export default CGscreeningQuestion