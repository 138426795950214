import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import Recaptcha from './components/recaptcha';
import UserQRDetail from './containers/UserQRDetail';
import LandingPage from './containers/LandingPage';

// always be last
import './index.css';

ReactDOM.render(
<BrowserRouter> 
<App />
</BrowserRouter>, document.getElementById('root'));
registerServiceWorker();
