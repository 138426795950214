import React, { Component } from 'react'
import queryString from 'query-string';

import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import ReactDOM from 'react-dom'
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import PopUpBoxOtp from '../../components/modalotp';
import axios from 'axios'
import Indonesia from '../../assets/images/Indonesia.png'
import Singapore from '../../assets/images/Singapore.png'
import Malaysia from '../../assets/images/Malaysia.png'
import { Colors, Fonts, Scale } from '../../themes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import PopUpBoxHandler from '../../components/modalhandler';

import Select, { components } from 'react-select'
import colors from '../../themes/Colors';
import Swal from 'sweetalert2'
import GoogleLogin from 'react-google-login';
import AppConfig from '../../config'
import { Link } from 'react-router-dom';

import { verifyPartnerToken, } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

const countryCodeData =[
  
  {
    id: 0,
    name : '+65',
    value: '+65',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Singapore} />+65 </Row>
  },{
    id: 1,
    name : '+62',
    value: '+62',
    label: <Row style={{ width:'200px', display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Indonesia} />+62 </Row>
  },
  
  {
    id: 2,
    name : '+60',
    value: '+60',
    label: <Row style={{ width:'200px',display:'flex',justifyContent:'flex-start'}}><img style={{marginRight:'5px',marginLeft:'15px',maxHeight:"20px", width:"20px"}} src={Malaysia}  />+60  </Row>
  },
]

const stylesCountryCode = {
  
  control: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold',
    padding:'0',margin:'0'
  }),
  menu: base => ({
    ...base,
    fontFamily: Fonts.FontRegular,
    padding:'0',margin:'0',
    fontColor: Colors.color,
    fontSize: '14px',
    fontWeight:'bold'
  })
};

const accountUrl = AppConfig.ACCOUNT_URL

const clientID = AppConfig.CLIENT_ID
const eye = <FontAwesomeIcon icon={faEye} />;

class OtpPage extends Component {
    constructor(props) {
        super(props)
        console.log(this.props.location.state)
        this.state = {
          ...this.props.location.state,
          code: '',
          btnLoading: false
        }
      }
   handleHide = (e)=> {
    
     this.setState({show: false})
 } 

  alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log('exit => ', exit)
        if (exit) {
          this.props.history.replace('/')
        } else null
      },
    })
  }

 handleCountryCode =(e)=> {
  this.setState({countryCode:e.value}, ()=>{

    
    console.log('countryCode', this.state.countryCode )
  })
  
this.setState({defaultCountryCode:e.id})
}; 
 validatePass=()=>{
   if(this.state.password.length >5){
     this.setState({enableBtn:false})
   }
 }
 togglePasswordVisiblity = () => {
    if(this.state.passwordShown==false){

      this.setState({passwordShown :true});
      
      this.setState({type:'text'})

    }else{
      this.setState({type:'password'})
      this.setState({passwordShown :false});
      
    }
  };
    
  responseGoogleFail = ()=> {
    Swal.fire({
      title: 'Warning!',
      text: 'This website uses cookies to help ensure you receive an optimal experience. Please activate cookies.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
    
  }
 responseGoogle = (response) => {
   this.setState({credentialType: "GOOGLE"})
Swal.fire({
  title: 'Please fill username and phone number',
  html:
    `<label>username </label><input   id="swal-input1" class="swal2-input">` +
    `<label>phone </label><input   id="swal-input2" class="swal2-input">`,
    
    showCancelButton: true,
  preConfirm: () => {
    
      this.setState({username : document.getElementById('swal-input1').value})
     
     this.setState({phone :document.getElementById('swal-input2').value})
     if(this.state.username =='' ||this.state.phone=='') {
      Swal.fire({ title: 'Warning!',
      text: 'Username or phone cannot be empty',
      icon: 'info',
      confirmButtonText: 'OK'})
    }else{
      axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
        username : this.state.username,
        phone: this.state.phone
   })
   .then(res => {
       console.log(res)
     if(res.status==200 && res.data.data.registered==true){
       
      Swal.fire({
        title: 'Warning!',
        text: 'This google account is already exist',
        icon: 'info',
        confirmButtonText: 'OK'
      })
     }else{ 
       this.setState({idTokenGoogle:response.tokenId, firstname: response.profileObj.givenName,
          lastname:response.profileObj.familyName,email:response.profileObj.email})

      this.submitForm(); 
      
     }
     
    })
  }


  }
})
 
    
  
  console.log(response);
}
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value }
  //     , () => {
      // if((this.state.password.length >5) &&(this.state.password == this.state.confirmPassword)){

            //  this.setState({enableBtn:false})
      
    //  } else{
    //    this.setState({enableBtn:true})
    //  };
    // }
    );
  };
  onChangeOTP = (stateName, value)=>{
    this.setState({[stateName]: value})
  }

  submitForm = () => { 

    console.log(this.state.countryCode.concat(this.state.phone))
    const isPartner = queryString.parse(this.props.location.search).partnerToken
    window.sessionStorage.setItem("isInvited", this.state.isInvited);
    axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
      username : this.state.username,
      // phone: this.state.countryCode.concat(this.state.phone)
    })
    .then(res => {
     console.log(res.data)
    if(res.status==200 && res.data.data.registered==true){
     
      Swal.fire({
        title: 'Warning!',
        text: 'This username already exists',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      if (isPartner) {
        window.sessionStorage.setItem("firstname", this.state.firstname);
        window.sessionStorage.setItem("lastname", this.state.lastname);
        window.sessionStorage.setItem("email",this.state.email);
        window.sessionStorage.setItem("countryCode",this.state.countryCode);
        if (this.state.masterPartnerUid) window.sessionStorage.setItem("masterPartnerUid",this.state.masterPartnerUid);
        if (this.state.partnerToken) window.sessionStorage.setItem("partnerToken",this.state.partnerToken);
        this.props.history.push({pathname:'/CareGiverPersonDetail',
        state:{
          username: this.state.username,
          password: this.state.password,
          phone: this.state.phone,
          credentialType: this.state.credentialType,
          idTokenGoogle:this.state.idTokenGoogle,
          masterPartnerUid: this.state.masterPartnerUid,
          partnerToken: this.state.partnerToken,
          countryCode: this.state.countryCode,
        }})
      } else {
        axios.post(`${accountUrl}/public/credential/generateCaregiverSignUpOTP`, {
          phone : this.state.phone,
          prefixCountryNum: this.state.countryCode,
          channel: "sms",
          username: this.state.username
        })
        .then(res => {
          console.log(res)
           if(res.status==200 && res.data.type=="SUCCESS"){
            this.setState({show: true})
               console.log(res.data.data.otp)
        
          } else{
           
            Swal.fire({
              title: 'Warning!',
              text: `${res.data.data.message}`,
              icon: 'info',
              confirmButtonText: 'OK'
            }) 
          };
        }, (error) => {
          console.log(error);
        });
      }
   };
  })}
    
    
  handleClose = () => {
    this.setState({ btnLoading: true })
    axios.post(`${accountUrl}/public/credential/verifyCaregiverSignUpOTP`, {
      phone : this.state.phone,
      prefixCountryNum: this.state.countryCode,
      code: this.state.code,
      })
      .then(res => {
       
        console.log(res)
        if(res.status==200 && (res.data.data.valid.status=='approved' || res.data.data.valid.valid) ){
          Swal.fire({
            title: 'Verified',
            text: `Your mobile number is verified`,
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'OK'
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.setState({show: false})
  
              window.sessionStorage.setItem("firstname", this.state.firstname);
              window.sessionStorage.setItem("lastname", this.state.lastname);
              window.sessionStorage.setItem("email",this.state.email);
              window.sessionStorage.setItem("countryCode",this.state.countryCode);
              window.sessionStorage.setItem("idTokenGoogle",this.state.idTokenGoogle);
              if (this.state.masterPartnerUid) window.sessionStorage.setItem("masterPartnerUid",this.state.masterPartnerUid);
              if (this.state.partnerToken) window.sessionStorage.setItem("partnerToken",this.state.partnerToken);
              this.props.history.push({pathname:'/CareGiverPersonDetail',
              state:{
                username: this.state.username,
                password: this.state.password,
                phone: this.state.phone,
                credentialType: this.state.credentialType,
                idTokenGoogle:this.state.idTokenGoogle,
                masterPartnerUid: this.state.masterPartnerUid,
                partnerToken: this.state.partnerToken,
                countryCode: this.state.countryCode,
              }})
            
              if (this.state.password == this.state.confirmPassword) {
                console.log("Password Match")
              }
            }
          })
        } else {
          this.setState({ btnLoading: false })
          Swal.fire({
            title: 'Warning!',
            text: ` OTP CODE IS NOT VALID`,
            icon: 'info',
            confirmButtonText: 'OK'
          }) 
        };
    }, (error) => {
      console.log(error);
    });  
  }
    handleCloseError =()=>{
      this.setState({showError:false})
    }
    back=()=>{
      this.props.history.goBack()
    }

    resendOtp = () => {
      axios.post(`${accountUrl}/public/credential/generateCaregiverSignUpOTP`, {
        phone : this.props.history.location.state.phone,
        prefixCountryNum: this.props.history.location.state.countryCode,
        channel: "sms",
        username: this.props.history.location.state.username
      })
      .then(res => {
        console.log(res)
         if(res.status==200 && res.data.type=="SUCCESS"){
          Swal.fire({
            title: 'Success!',
            text: `The otp code has been sent`,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else{
         
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          }) 
        };
      }, (error) => {
        console.log(error);
      });
    }

  render() {
      const {type,username,password,phone,confirmPassword,enableBtn,showError,errorMessage} = this.state
      const isPartner = queryString.parse(this.props.location.search).partnerToken
      console.log('this.state.invitationId => ', this.state.isInvited)
    return (
      <>
        <StyledCG>
          
        <Header title={isPartner ? ` ` : null} />
          <h2 style={{marginTop:'5%'}}>Input 6 digit code</h2>   
          <p style={{marginTop:'2%', color: '#B1C2D8'}}>A 6 digit code has been sent via SMS to your mobile number tied to {this.state.countryCode && this.state.phone ? this.state.countryCode.concat(' ', this.state.phone, '.') : '.' } <span style={{color: '#EFC900'}}> &nbsp;Please verify to your mobile number to continue.</span></p> 
          <Row style={{textAlign:'start',marginTop:'6%'}}>
            <Col md={8} style={{margin:'auto'}}>
                <label style={{color: '#502773', fontWight: 'normal'}}>Input Code</label>
            </Col>
            <Col md={4} style={{margin:'auto', position: 'relative'}}>
                <input 
                    maxLength={6}
                    placeholder="Enter your otp"
                    style={{ paddingLeft: '20px', width:'100%',height:'50px',border:'1px solid #B1C2D8',borderRadius:'10px',textAlign:'left',outline: 'none',cursor:  'pointer', fontFamily: 'NunitoSans',fontWeight: 'bold',color: '#000000',resize: 'none', fontSize: '18px'}} 
                    type='text' 
                    value={this.state.code} 
                    onChange={(e) => this.onChangeOTP('code', e.target.value)}
                /> 
              <p style={{
                cursor: 'pointer',
                position: 'absolute',
                right: 15,
                top: 65,
              }} onClick={this.resendOtp}>Resend code</p>
            </Col>
          </Row>
          <hr style={{marginTop:'20%',border: "1px solid #758BA9",}}/>
          <Row style={{justifyContent:'flex-end',marginRight:'1%',marginTop:'5%', marginBottom:'5%'}}>
            <Col md={3}>
              {this.state.btnLoading ? (
                <Button buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Loading..."/>
              ) : (
                <Button disabled={this.state.code.length !== 6 ? true : false}  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Submit" onClick={this.handleClose}/>
              )}
            </Col>
          </Row>
        </StyledCG>       
      </>
    )
  }
}


export default OtpPage