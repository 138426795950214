import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Input from "../../components/input";
import Header from "../../components/header2";

import ProccessData from "../../lib/ProccessData";

import {
  verifyCGUPToken,
  SubmitCaregiverUnderPartner
} from "../../api/UserAPI";

import {
  StyledSigninPartner,
  InputStyle
} from "../registrationPartner/Styles/StyledSigninPartner";

import AppConfig from "../../config";

import smallInfo from "../../assets/images/small-info.png";

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import playStore from "../../assets/images/GPlay.png";
import appleStore from "../../assets/images/AppS.png";

function SubmitCG({ match, history }) {
  const [state, setState] = useState({
    paramsSubmit: {
      username: "",
      password: "",
      confirmPassword: ""
    },
    loading: false
  });

  const [eula, setEula] = useState({
    first: false,
    second: false
  });

  const [revealField, setRevealField] = useState({
    password: false,
    confirmPassword: false
  });

  const [step, setStep] = useState(1);

  const { username, password, confirmPassword } = state.paramsSubmit;

  const buttonDisabled =
    username === "" ||
    password === "" ||
    confirmPassword === "" ||
    state.loading ||
    !eula.first ||
    !eula.second ||
    password.length < 6 ||
    confirmPassword.length < 6;

  useEffect(() => {
    return checkToken();
  }, []);

  const checkToken = async () => {
    try {
      const { token } = match.params;
      let resultCheck = {};
      resultCheck.payload = await verifyCGUPToken({
        headers: {
          Authorization: `bearer ${token}`
        }
      });
      ProccessData(resultCheck, "check Caregiver Under Partner token")
        .then((res) => {
          console.log("res => ", res);
          if (res.data.account.credentialSubmitted) {
            alertError(
              "Your credentials have been previously verified. You can login using your Caregiver account"
            );
          }
          // else throw new Error (res)
        })
        .catch((err) => {
          console.log("err => ", err);
          alertError(err);
        });
    } catch (e) {
      alertError(e);
    }
  };

  const alertSuccess = (text, exit = true, preConfirm = () => null) => {
    Swal.fire({
      title: "Success!",
      text,
      icon: "success",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        console.log("exit => ", exit);
        exit ? (window.location.href = "https://cara.sg/") : preConfirm();
      }
    });
  };

  const alertError = (text, exit = true, preConfirm = () => null) => {
    Swal.fire({
      title: "Error!",
      text,
      icon: "error",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        exit ? (window.location.href = "https://cara.sg/") : preConfirm();
      }
    });
  };

  const onChangeText = (name, value) => {
    setState((state) => {
      const { paramsSubmit } = state;

      paramsSubmit[name] = value;

      return {
        ...state,
        paramsSubmit: {
          ...state.paramsSubmit,
          paramsSubmit
        }
      };
    });
  };

  const submit = async () => {
    setState((state) => ({ ...state, loading: true }));
    try {
      const { token } = match.params;
      if (password.length < 6) {
        alertError(`Password must be longer or equal to 6 characters`, false);
        setState((state) => ({ ...state, loading: false }));
        return false;
      }
      if (password !== confirmPassword) {
        alertError(`Password & Confirm Password not match`, false);
        setState((state) => ({ ...state, loading: false }));
        return false;
      }
      let resultSubmit = {};
      resultSubmit.payload = await SubmitCaregiverUnderPartner({
        headers: {
          Authorization: `${token}`
        },
        data: state.paramsSubmit
      });
      ProccessData(resultSubmit, "Submit Partner")
        .then((res) => {
          if (res.type === "SUCCESS" && res.data.token) {
            return setStep(2);
            // return alertSuccess(
            //   "Your care partner account has been successfully created. Please login to continue"
            // );
          } else {
            if (
              res.data.message ==
              "Your credentials have been previously verified. You can login using your Caregiver account"
            )
              alertError(res.data.message);
          }
        })
        .catch((err) => {
          console.log("err => ", err);
          alertError(err, false);
        });
    } catch (e) {
      alertError(e);
    }
    setState((state) => ({ ...state, loading: false }));
  };

  return (
    <StyledSigninPartner buttonDisabled={buttonDisabled}>
      <Header title="Care Profession Member Registration"/>
      {step == 1 ? (
        <div>
          <p className="title-submit">Enter your username & password</p>
          <div className="form-container">
            <div className="input-container">
              <div>
                <label htmlFor="username" className="label-partner">
                  Username
                </label>
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  containerStyle={InputStyle}
                  inputProps={{
                    id: "username",
                    maxLength: 30,
                    onChange: (e) => onChangeText("username", e.target.value),
                    placeholder: "user@example.com",
                    style: { fontWeight: 400 }
                  }}
                />
              </div>
            </div>
            <div className="input-container">
              <div>
                <label htmlFor="password" className="label-partner">
                  Password
                </label>
              </div>
              <Input
                containerStyle={InputStyle}
                inputProps={{
                  id: "password",
                  type: revealField.password ? "text" : "password",
                  maxLength: 30,
                  onChange: (e) => onChangeText("password", e.target.value),
                  placeholder: "Enter your password",
                  style: { fontWeight: 400 }
                }}
                onToggleIcon={() =>
                  setRevealField({
                    ...revealField,
                    password: !revealField.password
                  })
                }
                fontAwesomeRight={revealField.password ? faEyeSlash : faEye}
                fontAwesomeRightStyle={{
                  justifyContent: "center",
                  alignSelf: "center",
                  color: "#B1C2D8"
                }}
              />
            </div>
            <div
              style={{ textAlign: "right", fontWeight: 400, color: "#502773" }}
            >
              <small>* Minimum 6 characters</small>
            </div>
            <div className="input-container">
              <div>
                <label htmlFor="confirm-password" className="label-partner">
                  Confirm Password
                </label>
              </div>
              <Input
                containerStyle={InputStyle}
                inputProps={{
                  id: "confirm-password",
                  type: revealField.confirmPassword ? "text" : "password",
                  maxLength: 30,
                  onChange: (e) =>
                    onChangeText("confirmPassword", e.target.value),
                  placeholder: "Enter your password confirmation",
                  style: { fontWeight: 400 }
                }}
                onToggleIcon={() =>
                  setRevealField({
                    ...revealField,
                    confirmPassword: !revealField.confirmPassword
                  })
                }
                fontAwesomeRight={
                  revealField.confirmPassword ? faEyeSlash : faEye
                }
                fontAwesomeRightStyle={{
                  justifyContent: "center",
                  alignSelf: "center",
                  color: "#B1C2D8"
                }}
              />
            </div>
          </div>
          <div className="agree-container">
            <div className="checkbox-container">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  onClick={(e) => setEula({ ...eula, first: e.target.checked })}
                />
                <span className="checkbox-custom rectangular"></span>
              </label>
              <div
                className="input-title"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                {" "}
                I agree to the{" "}
                <a
                  href="/Terms-Of-Use"
                  target="_blank"
                  style={{
                    color: " #502773",
                    paddingLeft: "5px",
                    textDecoration: "underline"
                  }}
                >
                  Terms of Use
                </a>
                ,
                <a
                  href="/Privacy-Policy"
                  target="_blank"
                  style={{
                    color: " #502773",
                    paddingLeft: "5px",
                    textDecoration: "underline"
                  }}
                >
                  Privacy Policy
                </a>{" "}
                and the
                <a
                  href="/Membership-Terms"
                  target="_blank"
                  style={{
                    color: " #502773",
                    paddingLeft: "5px",
                    textDecoration: "underline"
                  }}
                >
                  CARA Membership Terms and Conditions
                </a>
              </div>
            </div>

            <div className="checkbox-container">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  onClick={(e) =>
                    setEula({ ...eula, second: e.target.checked })
                  }
                />
                <span className="checkbox-custom rectangular"></span>
              </label>
              <div
                className="input-title"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                {" "}
                I agree that the information shared is used solely for the support and engagement with my loved one or client.
              </div>
            </div>
          </div>
          <div
            className="input-title"
            style={{
              marginTop: "32px",
              marginLeft: `35px`,
              fontSize: "14px",
              fontWeight: 400
            }}
          >
            For help and enquiries please email us at:{" "}
            <a
              style={{ color: "#502773", fontWeight: 500 }}
              href="mailto:cara.carecircle@dementia.org.sg"
              target="_blank"
            >
              Cara CareCircle Help
            </a>
          </div>
          <div
            className="flex_endDesktop_centerMobile"
            style={{
              marginTop: "40px"
            }}
          >
            <div style={{ width: "15px", height: 1 }} />
            <div
              className="button-register"
              onClick={() => {
                buttonDisabled ? null : submit();
              }}
            >
              <div className="button-register-text">
                {state.loading ? `Loading...` : `Submit`}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "500px"
              }}
            >
              <p
                className="title-submit text-center"
                style={{ marginBottom: "40px" }}
              >
                Congratulations!
              </p>
              <p
                className="text-center"
                style={{
                  fontSize: "18px",
                  color: "#424242",
                  marginBottom: "40px"
                }}
              >
                Your Care Professional Username & Password is created. You can
                now log on to your CARA mobile app with these login details.
              </p>
              <p
                className="text-center"
                style={{
                  color: "#348F5F",
                  marginBottom: "40px"
                }}
              >
                Thank you for your continued contribution to the
                <br /> dementia care community.
              </p>
              <p
                className="text-center"
                style={{
                  marginBottom: "40px"
                }}
              >
                Yours Sincerely,
                <br />{" "}
                <span style={{ fontWeight: "bold", color: "#502773" }}>
                  CARA
                </span>
              </p>
              <p
                className="text-center"
                style={{
                  marginBottom: "25px"
                }}
              >
                Download the CARA SG app on your mobile phone.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "40px"
                }}
              >
                <div style={{ display: "flex", width: "60%" }}>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.embreo.carasg">
                      <img src={playStore} />
                    </a>
                  </div>
                  <div style={{ width: "40px" }} />
                  <div>
                    <a href="https://apps.apple.com/sg/app/cara-sg/id1553855834">
                      <img src={appleStore} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center">
            For assistance and enquiries, contact us at{" "}
            <a
              href="mailto:cara.carecircle@dementia.org.sg"
              style={{
                color: "#2a78bf",
                textDecoration: "underline"
              }}
            >
              Cara.CareCircle Help
            </a>
          </p>
        </div>
      )}
    </StyledSigninPartner>
  );
}

export default SubmitCG;
