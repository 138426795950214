import React, { Component } from 'react'
import ReactGA from 'react-ga';

import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import caraLogo from '../../assets/images/cara-logo.png'
import subtract2 from '../../assets/images/subtract2.png'
import subtract from '../../assets/images/subtract.png'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import Swal from 'sweetalert2'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import checklistborder from '../../assets/images/checklistborder.png'
import checklist from '../../assets/images/checklist.png'

import PopUpBox from '../../components/modal'
import axios from 'axios'

import Select from 'react-select'
import AppConfig from '../../config'
import { useHistory } from "react-router-dom";
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBoxConfirmation from '../../components/modalConfirmation';
const accountUrl = AppConfig.ACCOUNT_URL

const relationshipStatusData =
[
      {
        id: 0,
        name: `Uncle / Aunt`,
        value: `Uncle / Aunt`,
        label: `Uncle / Aunt`
      },
      {
        id: 1,
        name:  'Spouse',
        value: `Spouse`,
        label:  `Spouse`
      },
      {
        id: 2,
        name:  'Parent',
        value: `Parent`,
        label:  `Parent`
      },
      {
        id: 3,
        name:  'Grandparent',
        value: `Grandparent`,
        label: `Grandparent`
      },
      {
        id: 4,
        name:  'Child',
        value: `Child`,
        label:  `Child`
      },
      {
        id: 5,
        name: 'Grandchild',
        value: `Grandchild`,
        label:  `Grandchild`
      },
      {
        id: 6,
        name: `Nephew / Niece`,
        value: `Nephew / Niece`,
        label:  `Nephew / Niece`
      },
      {
        id: 7,
        name: 'Others, please specify',
        isCustom: false
      }
    ]


export class lastPageOfCgCreatePwd extends Component {
    constructor(props) {
        super(props)
        
        this.state = {   
            username: window.sessionStorage.getItem("username"),
            password: window.sessionStorage.getItem("password"),
            phone: this.props.location.state.accountData.phone,
            firstname: this.props.location.state.accountData.firstname,
            lastname: this.props.location.state.accountData.lastname,
            displayName: this.props.location.state.displayName,
            gender : this.props.location.state.accountData.gender,
            birth: this.props.location.state.accountData.birth,
            NIRC:this.props.location.state.accountData.NIRC,
            streetName:this.props.location.state.accountData.streetName,
            unitNumber:this.props.location.state.accountData.unitNumber,
            postalCode:this.props.location.state.accountData.postalCode,
            cityCountry:this.props.location.state.accountData.cityCountry,
            language: this.props.location.state.accountData.language,
            dementiaType: this.props.location.state.accountData.dementiaType,
            dementiaStage: this.props.location.state.accountData.dementiaStage,
            uid:this.props.location.state.accountData.uid,
            
            hospitalName: "",
            hospitalDepartment: "",
            appointment: "",
            symptoms: "",
            relationship:this.props.history.location.state.accountData.relationship,
            relationshipStatus: this.props.history.location.state.accountData.relationshipStatus,
            errorMessage:'',
            showError:false,
            showConfirmation:false,
            agree: true,
            agree1: false,
            agree2: false,
            SwitchYes:TypeInactive,
            SwitchWeekly:TypeInactive,
            SwitchNo:TypeInactive,
            SwitchYearly:TypeInactive,
            fullData: { ...this.props.location.state.fullData },
          
         
          show: false,
        }
      }
      handleShowConfirm=()=>{
        if(this.state.hospitalName =='' || this.state.hospitalDepartment==''|| this.state.symptoms=='' ||(this.state.relationship =='' ) ||      this.state.relationshipStatus=='' ){
          Swal.fire({
              title: 'Warning!',
              text: 'please fill the required data ',
              icon: 'info',
              confirmButtonText: 'OK'
            })
        }else{
          this.setState({showConfirmation:true})
        }
      }
      
  handleChangeRelationship =(e)=> {
    this.setState({relationship:e.value})
    console.log('relationship', this.state.relationship )
    this.setState({relationshipStatus:e.id})
  };
  
     handleShow = ()=> {
            console.log('POST DATA =>', this.props.history.location.state)
            const postData = {
                uid: this.props.history.location.state.accountData.uid,
                relationship: this.props.history.location.state.accountData.relationship,
                dementia: {
                  credential : {
                    credentialType: "USERNAME",
                    credentialData: {
                      username:this.props.history.location.state.accountData.username,
                      password:this.props.history.location.state.accountData.password
                    },
                    accountData:{
                      firstname: this.props.history.location.state.accountData.firstname,
                      lastname: this.props.history.location.state.accountData.lastname,
                      displayName: this.props.history.location.state.displayName,
                      gender: this.props.history.location.state.accountData.gender,
                      prefixCountryNum: "0",
                      phone: '0',
                      birth: this.props.history.location.state.accountData.birth,
                      lastNric: this.props.history.location.state.accountData.NIRC,
                      streetName: this.props.history.location.state.accountData.streetName,
                      unitNumber: this.props.history.location.state.accountData.unitNumber,
                      postalCode: this.props.history.location.state.accountData.postalCode,
                      cityCountry: this.props.history.location.state.accountData.cityCountry,
                      building: this.props.history.location.state.accountData.building,
                      blockNumber: this.props.history.location.state.accountData.blockNumber,
                      
                    existingDementiaSingapore: this.props.history.location.state.existingDementiaSingapore,
                      dementiaDetail: {
                          language: this.props.history.location.state.accountData.language,
                          typeDementia: this.props.history.location.state.accountData.dementiaType,
                          stageDementia: this.props.history.location.state.accountData.dementiaStage
                      },
                      dementiaHospital: {
                          hospitalName: this.props.history.location.state.accountData.hospitalName,
                          hospitalDepartment: this.props.history.location.state.accountData.hospitalDepartment,
                          appointment: 'DAILY',
                          symptoms: this.props.history.location.state.accountData.symptoms
                      },
                      registrationPlatform: "WEB"
                    }
                  }
                }
              }
              console.log('POST DATA BODY =>', postData)

            axios.post(`${accountUrl}/public/accounts/createdementia`, postData)
              .then(res => {
                console.log(res)
                if(res.status==200  && res.data.type=="SUCCESS"){
                  this.setState({show: true})
                  sessionStorage.clear();
                }else{
                  Swal.fire({
                    title: 'Warning!',
                    text: `${res.data.data.message}`,
                    icon: 'info',
                    confirmButtonText: 'OK'
                  })
                };
              }, (error) => {
                console.log(error);
              });
    
    }
    handleClose= ()=>{
        this.setState({show: false})
        this.props.history.push({pathname:'/registrationNotification'})
    }
    onChangeText = (stateName, value) => {
      this.setState({ [stateName]: value });
    };
 
    changeYes=()=>{
      if(this.state.SwitchYes == TypeInactive ){
          this.setState({SwitchYes: TypeActive,existingDementiaSingapore:true, SwitchNo:TypeInactive})
          
      }
  }
  changeNo=()=>{
  if(this.state.SwitchNo == TypeInactive ){
     
      this.setState({SwitchNo: TypeActive,existingDementiaSingapore:false, SwitchYes:TypeInactive})
  }
}
  
    
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
};

agreeSubmit = (e)=>{
       
  if(this.state.agree1){
      this.setState({agree1:false,agree:true})
  }else{
      this.setState({agree1:true}, () => {
          console.log(`agree1: ${this.state.agree1}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit1  = (e)=>{
 
  if(this.state.agree2){
      this.setState({agree2:false,agree:true})
  }else{
  
      this.setState({agree2:true},() => {
          console.log(`agree2: ${this.state.agree2}`);
          this.agreeSubmit3()
      });
  }
}
agreeSubmit3 = ()=>{
 

  
  if (this.state.agree1 == true && this.state.agree2 == true){
      this.setState({agree:false})

  }
}
back=()=>{
  this.props.history.push({pathname:'/CareGiverCreateNewPWDResidingAddress',
  state:{
    username: this.state.username,
    password: this.state.password,
    firstname: this.state.firstname,
    lastname: this.state.lastname,
    displayName: this.state.displayName,
    gender : this.state.gender,
    NIRC: this.state.NIRC,
    birth: this.state.birth,
    language: this.state.language,
    dementiaType: this.state.dementiaType,
    dementiaStage: this.state.dementiaStage,
    email: this.state.email,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    phone:this.state.phone,
    uid:this.props.location.state.uid,
    token:this.props.location.state.token, 
    homeNumber:this.state.homeNumber,
}}
)
}

handleCloseError =()=>{
  this.setState({showError:false})
}
  render() {
      const {agree,SwitchYes,SwitchWeekly,SwitchNo,SwitchYearly,hospitalName,hospitalDepartment,showError,symptoms}= this.state
      let membershipId = this.state.NIRC ? 
        `${'D'}${this.state.NIRC ? `${this.state.NIRC}` : ''}XXXXX`
      : '-'
      return (
      <>
        <StyledCG>
            <Header/>
            <Row style={{marginTop:'6%',marginBottom:'2%'}}>
              <Col md={6}>
                <h2 style={{fontSize: 25}}>This is how your name will appear on your physical CARA card</h2>
              </Col>
            </Row>
            <Row className="mt-5" style={{ marginBottom: 100 }}>
                <Col md={5}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#F5F5F5',
                        padding: '0 40px',
                        height: 150,
                        borderRadius: 10
                    }}>
                        <ul>
                            <li>Please check the order matches your NRIC</li>
                            <li>If you would like to edit your name, please click on “Edit Information“</li>
                        </ul>
                    </div>
                </Col>
                <Col md={{
                    span: 4,
                    offset: 3
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: 10,
                        height: 400,
                        width: '100%',
                        boxShadow: '3px 3px 10px #CCD5E1',
                        position: 'relative',
                        padding: '0 20px',
                        overflow: 'hidden'
                    }}>
                        <div>
                            <img src={caraLogo} style={{
                                width: 70,
                                marginTop: 25,
                            }} />
                            <img src={subtract2} style={{
                                position: 'absolute',
                                width: 90,
                                top: 0,
                                right: 5,
                            }} />
                            <img src={subtract} style={{
                                position: 'absolute',
                                width: 150,
                                bottom: 0,
                                left: 5,
                            }} />
                        </div>
                        <div style={{
                            marginTop: 40,
                        }}>
                            <p style={{
                                color: '#000000',
                                fontSize: 14
                            }}>
                            Hello, my name is
                            </p>
                            <p style={{
                               color: '#000000',
                               fontSize: 25,
                               width: '100%',
                               textAlign: 'left',
                               lineHeight: 1.2,
                               marginBottom: 0
                            }}>
                                {
                                  this.state.displayName.split(' ')[0]
                                }
                            </p>
                            <p style={{
                               color: '#000000',
                               fontSize: 25,
                               maxWidth: '15ch',
                               textAlign: 'left',
                               wordWrap: 'break-word',
                               lineHeight: 1.2, 
                            }}>
                                {
                                  this.state.displayName.split(' ').splice(1).join(' ')
                                }
                            </p>
                            <p style={{
                                color: '#000000',
                                fontSize: 12,
                                marginTop: 20,
                            }}>
                                This is where your membership number will appear
                            </p>
                            <p style={{
                               color: '#000000',
                               fontSize: 25,
                               width: 120,
                               textAlign: 'left',
                               lineHeight: 1.2, 
                            }}>
                                {membershipId}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop:'3%',marginBottom:'5%'}}>
            <Col md={6}></Col>
                 <Col md={3}> 
                 <CustomButton color="#EFC900" onClick={() => this.props.history.goBack()}>
                    Edit Information
                 </CustomButton>
                {/* <Button containerStyle={StyledButton} textStyle={StyledButton}  onClick={() => {}} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color14} textColor={colors.color11} bordered={colors.color14} text="Edit Information" /> */}
                </Col>
                <Col  md={3}>
                {/* <Button containerStyle={StyledButton} textStyle={StyledButton}  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} disabled={!this.state.hospitalName || !this.state.hospitalDepartment || !this.state.symptoms} onClick={() => {
                  window.alert('pertama ' + this.state.relationshipStatus)
                  window.alert(this.props.history.location.state.relationshipStatus)
                  this.props.history.push('/pwd-summary-new', {...this.state})
                }} text="Next"  /> */}
                 <CustomButton color="#62318C" onClick={this.handleShow}>
                    Confirm Submission
                 </CustomButton>
                <PopUpBox body='Thanks for submitting details for the Person Living with Dementia!' show={this.state.show}  handleClose={this.handleClose}/>
                <PopUpBoxHandler  show={showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                </Col></Row>
                
            </StyledCG>

        
       
      </>
    )
  }
}


const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;

export default lastPageOfCgCreatePwd