import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import { Container, Row, Col, } from 'react-grid-system'

import { StyledSampleCard } from '../styles/StyledSampleCard'

import Header from '../../components/header2'
import Button from '../../components/button'
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBox from '../../components/modal'

import AppConfig from '../../config'

const accountUrl = AppConfig.ACCOUNT_URL

function SampleCard({
  history,
  location,
}) {
  const [state, setState] = useState({
    uid: '',
    token: '',
    showError: false,
    errorMessage: '',
    showSuccess: false,
    loading: false,
    accountData: {}
  })
 
  const { uid, token, showError, errorMessage, showSuccess, loading, } = state
  const { credential, partnerToken, } = location.state
  const { credentialType, } = credential
  const { idTokenGoogle, username, password, } = credential.credentialData
  const {
    displayName,
    masterPartnerUid,
    lastNric,
    phone,
    email,
    firstname,
    lastname,
    gender,
    birth,
    streetName,
    unitNumber,
    postalCode,
    cityCountry,
    existingDementiaSingapore,
    dementiaHospital,
    dementiaDetail,
    homeNumber,
    metadata,
    cardAddress,
  } = state.accountData || ''
  

  useEffect(() => {
    console.log('cred => ', location.state.credential.accountData)
    if (credential) {
      setState(state => ({
        ...state,
        accountData: credential.accountData,
      }))
    }
    
  }, [])

  const handleClose = () => {
    if (masterPartnerUid && masterPartnerUid !== 'null') {
      history.push({
        pathname:'/partner-confirmation-plwd',
        state:{
          ...location.state,
           uid,
           token,
           streetName,
           unitNumber,
           postalCode,
           cityCountry:cityCountry,
           masterPartnerUid: masterPartnerUid,
           partnerToken,
        }
      })
    } 
    
    else {
      history.push({
        pathname:'/registrationCreateCGorTagCG',
        state:{
          ...location.state,
          uid,
          token,
          streetName,
          unitNumber,
          postalCode,
          cityCountry,
          masterPartnerUid,
        }})
        
      return state
    }
  }

  const handleRegister = () => {
    setState(state => ({
      ...state,
      loading: true,
    }))

    if (credentialType == "GOOGLE") {
      axios.post(`${accountUrl}/public/credential/signupDementia`, {
        credential: {
          // credentialTypecredentialType,
          credentialType: "GOOGLE",
          credentialData: {
            idTokenGoogle
          },
          accountData: {
            prefixCountryNum: ``,
            phone: `${phone}`,
            username,
            email,
            firstname,
            lastname,
            displayName,
            gender,
            birth,
            streetName,
            password,
            unitNumber,
            postalCode,
            cityCountry,
            lastNric,
            existingDementiaSingapore,
            dementiaHospital,
            dementiaDetail,
            interests: "-",
            activityPreferences: "-",
            homeNumber: `${homeNumber}`,
            masterPartnerUid,   
            metadata,
            cardAddress
          }
        }
      })
      .then(res => {
        console.log(res)
        if (res.status == 200 && res.data.type == "SUCCESS") {
          
          setState(state => ({
            ...state,
            showSuccess: true,
            loading: false,
            uid: res.data.data.account.uid,
            token: res.data.data.account.token
          }))
        } else {
          setState(state => ({
            ...state,
            loading: false,
          }))
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          })
        };
      }, (error) => {
        console.log(error);
        setState(state => ({
          ...state,
          loading: false,
        }))
      });
    }
    
    else {

      axios.post(`${accountUrl}/public/credential/signupDementia`, {
        credential : {
          credentialType: "USERNAME",
          credentialData: {
              username,
              password: (password && password !== '') ? password : undefined,
          },
          accountData: {
            email,
            firstname,
            lastname,
            displayName,
            gender,
            birth,
            streetName,
            unitNumber,
            postalCode,
            cityCountry,
            lastNric,
            existingDementiaSingapore,
            dementiaHospital,
            dementiaDetail,
            masterPartnerUid,
            phone,
            homeNumber,
            metadata,
            cardAddress
          }
        }
      })
      .then(res => {
        console.log(res)
        if (res.status==200 && res.data.type=="SUCCESS") {
          sessionStorage.clear()
          
          setState(state => ({
            ...state,
            showSuccess: true,
            uid: res.data.data.account.uid,
            token: res.data.data.account.token
          }))
        } else {
          Swal.fire({
            title: 'Warning!',
            text: `${res.data.data.message}`,
            icon: 'info',
            confirmButtonText: 'OK'
          }) 
          setState(state => ({
            ...state,
            loading: false,
          }))
        };
      }, (error) => {
        console.log(error);
        setState(state => ({
          ...state,
          loading: false,
        }))
      });
    }
  }

  let splitName = []

  if (displayName) splitName = displayName ? displayName.split(' ') : '-'.split(' ')
  if (splitName.length > 1) {
    splitName[1] = `\n${splitName[1]}`
  }
  const renderName =  splitName.join(' ')
  
  return (
    <StyledSampleCard>
      <PopUpBox 
        body={masterPartnerUid && masterPartnerUid != "null" ? 'Success! PLWD account has been created' : 'Success! Your PLWD account has been created'} 
        show={showSuccess}  
        handleClose={handleClose}
      />
      <PopUpBoxHandler 
        show={showError} 
        text={errorMessage} 
        handleClose={() => {
          setState(state => ({
              ...state,
              showError: false
            })
          )
        }}
      />
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <div className="base-container">
          <Header title={masterPartnerUid ? 'Create PLWD Account' : null} />
          <p className="title-sample">This is how your name will appear on your physical CARA card.</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: '24px', marginRight: '10px' }}>•</div>
            <div className="title-sample" style={{ fontSize: '24px', margin: '0' }}>Please check the order matches your NRIC.</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '24px', marginRight: '10px' }}>•</div>
            <div className="title-sample" style={{ fontSize: '24px', margin: '0', marginBottom: '45px' }}>If you would like to edit your name, please click on “Edit Information”</div>
          </div>
          <Row className="card-container">
            <Col>
              <div className="card" style={{ padding: 0, width: '315px', height: '420px', }}>
                <div className="text-container">
                  <div className="card-text">Hello, my name is</div>
                  <div className="card-text2">{renderName}</div>
                  <div className="card-text" style={{ marginTop: '35px' }}>My membership number is</div>
                  <div className="card-text3">D{lastNric}XXXXX</div>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', margin: '57px 0 120px' }}>
            <div>
              <Button
                text="Edit information"
                textStyle={`
                  line-height: 60px;
                  text-align: center;
                  width: 100%;
                  color: #502773;
                `}
                bordered
                onClick={() => history.goBack()}
              />
            </div>
            <div style={{ width: '21px', }} />
            <div>
              <Button
                text={loading ? "Loading..." : "Confirm Submission"}
                textStyle={`
                  line-height: 60px;
                  text-align: center;
                  width: 100%;
                `}
                disabled={loading}
                onClick={handleRegister}
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledSampleCard>
  )
}

export default SampleCard
