import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'
import createMemberImg from '../../assets/images/create-member-img.png';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';


export class createNewMember extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
         
          phone: this.props.location.state.phone,
          firstname: this.props.location.state.firstname,
          lastname: this.props.location.state.lastname,
          gender : this.props.location.state.gender,
          birth: this.props.location.state.birth,
          uid:this.props.location.state.uid,
          token:this.props.location.state.token,
         
    streetName:this.props.location.state.streetName,
    unitNumber:this.props.location.state.unitNumber,
    postalCode:this.props.location.state.postalCode,
    cityCountry:this.props.location.state.cityCountry,
          fullData: { ...this.props.location.state },
          show: false,
        }

      }
   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
    
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
 submitForm = ()=>{
      
  this.props.history.push({pathname:'/CareGiverCreateNewPWD',
  state:{
    uid:this.state.uid,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    fullData: {
      ...this.state.fullData
    }
  }})
  console.log(this.state.uid)
  console.log(this.state.streetName)
  console.log(this.state.streetName)
  console.log(this.state.unitNumber)
  console.log(this.state.postalCode)
  console.log(this.state.cityCountry)
  return this.state
}
tagPWD=()=>{ 
  this.props.history.push({pathname:'/CareGiverTagNewPWD',
  state:{
    uid:this.state.uid,
    token:this.state.token,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,


  }})
  console.log(this.state.uid)
  console.log(this.state.token)
  return this.state}
    
  render() {
    return (
      <>
        <StyledCG>
            <Header/>
            <Row style={{marginTop:'80px'}}>
              <Col md={8}>
                <h2 style={{
                  fontSize: '30px'
                }}>Thank you for creating your account!</h2>
              </Col>
              <Col md={4}>
                <img src={createMemberImg} className="fluid" alt="Create Member Step" />
              </Col>
            </Row>
            <Container style={{textAlign:'start',marginBottom:'60px',padding:'0'}}>
                <Row className='mb-5'>
                  <Col md={12}>
                    <div style={{
                      backgroundColor: '#E6D9F2',
                      height: 120,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      borderRadius: 10
                    }}>
                      <div style={{
                        width: '95%',
                        margin: '0 auto'
                      }}>
                      <p style={{
                        color: '#502773',
                        fontSize: '20px'
                      }}>I am Caregiver</p>
                      <p style={{
                        color: '#4B4B4B',
                        fontSize: '18px',
                        fontWeight: 'normal'
                      }}>
                        Please proceed to tag account to Person Living with Dementia to complete application
                      </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p style={{
                      color: '#FF725E'
                    }}>
                      Person I am caring for:
                    </p>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <CustomButton color='#EFC900' onClick={this.tagPWD}>Has a CARA Account</CustomButton>
                        {/* <Button containerStyle={StyledButton} buttonStyle={{ display: 'flex', paddingTop: 20, paddingBotom: 20, boxSizing: 'content-box',  alignItems: 'center' }} textStyle={{fontSize: 20}} text="Does not have a CARA Account" /> */}
                      </Col>
                      <Col md={6}>
                      <CustomButton color='#62318C' onClick={this.submitForm}>Does not have a CARA Account</CustomButton>
                        {/* <Button containerStyle={StyledButton} buttonStyle={{height:'30px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.tagPWD} backgroundColor={colors.color14} text="Find existing member" /> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                
         
            </Container>
               
            </StyledCG>

        
       
      </>
    )
  }
}


const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  cursor: pointer !important; 
`;

export default createNewMember