import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import checklistborder from '../../assets/images/checklistborder.png'
import checklist from '../../assets/images/checklist.png'

import PopUpBox from '../../components/modal'
import { signUp } from '../../api/UserAPI'
import axios from 'axios'

import AppConfig from '../../config'
import PopUpBoxHandler from '../../components/modalhandler';

import Swal from 'sweetalert2'
import PopUpBoxConfirmation from '../../components/modalConfirmation';
const accountUrl = AppConfig.ACCOUNT_URL

export class screeningQuestion extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            uid:'',
            username: this.props.location.state.username,
            password: this.props.location.state.password,
            firstname: this.props.location.state.firstname,
            lastname: this.props.location.state.lastname,
            displayName: this.props.location.state.displayName,
            gender : this.props.location.state.gender,
            NIRC: this.props.location.state.NIRC,
            birth: this.props.location.state.birth,
            language: this.props.location.state.language,
            dementiaType: this.props.location.state.dementiaType,
            dementiaStage: this.props.location.state.dementiaStage,
            email: this.props.location.state.email,
            streetName:this.props.location.state.streetName,
            unitNumber:this.props.location.state.unitNumber,
            postalCode:this.props.location.state.postalCode,
            cityCountry:this.props.location.state.cityCountry,
            credentialType: this.props.location.state.credentialType,
            idTokenGoogle: this.props.location.state.idTokenGoogle,
            phone:this.props.location.state.phone,
            homeNumber:this.props.location.state.homeNumber,
            masterPartnerUid: this.props.location.state.masterPartnerUid,
            partnerToken: this.props.location.state.partnerToken,
            partnerAddress: this.props.location.state.partnerAddress,
            partnerBranch: this.props.location.state.partnerBranch,
            partnerPostalCode: this.props.location.state.partnerPostalCode,
            cardAddress: this.props.location.state.cardAddress,
            hospitalName: "",
            hospitalDepartment: "",
            appointment: "",
            existingDementiaSingapore: false,
            symptoms: "",
            token:'',
            showConfirmation:false,
            showError:false,
          errorMessage:'',
            
            agree: false,
            agree1: false,
            agree2: false,
            SwitchYes:TypeInactive,
            SwitchWeekly:TypeInactive,
            SwitchNo:TypeInactive,
            SwitchYearly:TypeInactive,

         
          show: false,
        }
        this.agreeSubmit3 = this.agreeSubmit3.bind(this)
      }
        handleClose = ()=>{

            window.sessionStorage.setItem("hospitalName", this.state.hospitalName);
            window.sessionStorage.setItem("hospitalDepartment", this.state.hospitalDepartment);
            window.sessionStorage.setItem("existingDementiaSingapore", this.state.existingDementiaSingapore);
            window.sessionStorage.setItem("symptoms",this.state.symptoms);
            this.props.history.push({pathname:'/summary-plwd',
            state:{
                uid:this.state.uid,
                token: this.state.token,
                streetName:this.state.streetName,
                unitNumber:this.state.unitNumber,
                postalCode:this.state.postalCode,
                cityCountry:this.state.cityCountry,
                masterPartnerUid: this.state.masterPartnerUid,
                partnerToken: this.state.partnerToken,
                credential : {
                    credentialType: this.state.credentialType,
                    credentialData: {
                        idTokenGoogle: this.state.idTokenGoogle,
                        username: this.state.username,
                        password: this.state.password,
                    },
                    accountData:{
                        prefixCountryNum: ``,
                        phone: this.state.phone,
                        username: this.state.username,
                        email: this.state.email,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        displayName: this.state.displayName,
                        gender: this.state.gender,
                        birth: this.state.birth,
                        streetName: this.state.streetName,
                        password: this.state.password,
                        unitNumber: this.state.unitNumber,
                        postalCode: this.state.postalCode,
                        cityCountry: this.state.cityCountry,
                        lastNric: this.state.NIRC,
                        existingDementiaSingapore: this.state.existingDementiaSingapore,
                        dementiaHospital: {
                            hospitalName: this.state.hospitalName,
                            hospitalDepartment: this.state.hospitalDepartment,
                            appointment: 'DAILY',
                            symptoms: this.state.symptoms
                        },
                        dementiaDetail: {
                            language: this.state.language,
                            typeDementia: this.state.dementiaType,
                            stageDementia: this.state.dementiaStage
                        },
                        interests: "-",
                        activityPreferences: "-",
                    
                        homeNumber: this.state.homeNumber,
                        masterPartnerUid: this.state.masterPartnerUid === "null" ? null : this.state.masterPartnerUid,
                        partnerToken: this.state.partnerToken === "null" ? null : this.state.partnerToken,
                        cardAddress: this.state.cardAddress,
                        metadata: this.state.cardAddress ? {
                            cardAddress: {
                                partnerAddress: this.state.partnerAddress,
                                partnerBranch: this.state.partnerBranch,
                                partnerPostalCode: this.state.partnerPostalCode,
                            }
                        } : {}
                    }
                }
            },
            })
            console.log(`${this.state.uid}`)
            console.log(this.state.streetName)
            console.log(this.state.unitNumber)
            console.log(this.state.postalCode)
            console.log(this.state.cityCountry)
            
            return this.state
      }
      handleShowConfirm=()=>{
          if(this.state.hospitalName =='' || this.state.hospitalDepartment ==''|| this.state.symptoms=='' ||(this.state.relationship =='' ) ||      this.state.relationshipStatus==''){
            Swal.fire({
                title: 'Warning!',
                text: 'please fill the required data ',
                icon: 'info',
                confirmButtonText: 'OK'
              })
          }else{
            this.handleClose( )
            //   this.setState({showConfirmation:true})
          }
      }
      componentDidMount(){
        if(window.sessionStorage.getItem("hospitalName") != '' && window.sessionStorage.getItem("hospitalName") !='null'){
            this.setState({hospitalName: window.sessionStorage.getItem("hospitalName")})
          }
           if(window.sessionStorage.getItem("hospitalDepartment") != '' && window.sessionStorage.getItem("hospitalName") !='null'){
           this.setState({hospitalDepartment: window.sessionStorage.getItem("hospitalDepartment")})
         }
          if( window.sessionStorage.getItem("existingDementiaSingapore") == 'true'){
           this.setState({existingDementiaSingapore: window.sessionStorage.getItem("existingDementiaSingapore") , SwitchYes: TypeActive,})
         }
         if( window.sessionStorage.getItem("existingDementiaSingapore") == 'false'){
          this.setState({existingDementiaSingapore: window.sessionStorage.getItem("existingDementiaSingapore") , SwitchNo: TypeActive,})
        }
          if(window.sessionStorage.getItem("symptoms") != '' && window.sessionStorage.getItem("symptoms") !='null' ){
           this.setState({symptoms: window.sessionStorage.getItem("symptoms")})
         }
      }
      handleNo = ()=>{
        window.sessionStorage.setItem("hospitalName", this.state.hospitalName);
        window.sessionStorage.setItem("hospitalDepartment", this.state.hospitalDepartment);
        window.sessionStorage.setItem("existingDementiaSingapore", this.state.existingDementiaSingapore);
        window.sessionStorage.setItem("symptoms",this.state.symptoms);

        this.props.history.push({pathname:'/signUpPersonDetailPWD',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          email: this.state.email,
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          idTokenGoogle:this.state.idTokenGoogle,
          credentialType: this.state.credentialType, 
          phone:this.state.phone,
    
          homeNumber:this.state.homeNumber,
          masterPartnerUid: this.state.masterPartnerUid,
          partnerToken: this.state.partnerToken,
          partnerAddress: this.state.partnerAddress,
          partnerBranch: this.state.partnerBranch,
          partnerPostalCode: this.state.partnerPostalCode,
          cardAddress: this.state.cardAddress,
    
        }})
        this.setState({showConfirmation:false})
      }
      handleYes = ()=>{
        this.setState({showConfirmation:false})
       this.handleShow()
      }
     handleShow = ()=> {

        if (this.state.credentialType=="GOOGLE") {

            axios.post(`${accountUrl}/public/credential/signupDementia`, {
                credential : {
                    credentialType: this.state.credentialType,
                    credentialData: {
                        idTokenGoogle: this.state.idTokenGoogle
                    },
                    accountData:{
                        prefixCountryNum: this.state.phone.slice(0,3),
                        phone: this.state.phone.slice(3,),
                        username: this.state.username,
                        email: this.state.email,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        displayName: this.state.displayName,
                        gender: this.state.gender,
                        birth: this.state.birth,
                        streetName: this.state.streetName,
                        password: this.state.password,
                        unitNumber: this.state.unitNumber,
                        postalCode: this.state.postalCode,
                        cityCountry: this.state.cityCountry,
                        lastNric: this.state.NIRC,
                        existingDementiaSingapore: this.state.existingDementiaSingapore,
                        dementiaHospital: {
                            hospitalName: this.state.hospitalName,
                            hospitalDepartment: this.state.hospitalDepartment,
                            appointment: 'DAILY',
                            symptoms: this.state.symptoms
                        },
                        dementiaDetail: {
                            language: this.state.language,
                            typeDementia: this.state.dementiaType,
                            stageDementia: this.state.dementiaStage
                        },
                        interests: "-",
                        activityPreferences: "-",
                    
                        homeNumber: this.state.homeNumber,
                        registrationPlatform: "WEB",
                        masterPartnerUid: this.state.masterPartnerUid === "null" ? null : this.state.masterPartnerUid,
                        partnerToken: this.state.partnerToken === "null" ? null : this.state.partnerToken,
                        cardAddress: this.state.cardAddress,
                        metadata: this.state.cardAddress ? {
                            cardAddress: {
                                partnerAddress: this.state.partnerAddress,
                                partnerBranch: this.state.partnerBranch,
                                partnerPostalCode: this.state.partnerPostalCode,
                            }
                        } : {}
                    }
            
                }
              })
              .then(res => {
                console.log(res)
                if(res.status==200 && res.data.type=="SUCCESS"){
                   
         this.setState({show: true})
                    this.setState({uid:res.data.data.account.uid,
                                    token:res.data.data.account.token})
                }else{
                    Swal.fire({
                        title: 'Warning!',
                        text: `${res.data.data.message}`,
                        icon: 'info',
                        confirmButtonText: 'OK'
                      })
                   
                };
              }, (error) => {
                console.log(error);
              });
        }else{

        
        axios.post(`${accountUrl}/public/credential/signupDementia`, {
            credential : {
                credentialType: "USERNAME",
                credentialData: {
                    username:this.state.username,
                    password:this.state.password
                },
                accountData:{
                    email: this.state.email,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    displayName: this.state.displayName,
                    gender: this.state.gender,
                    birth: this.state.birth,
                    streetName: this.state.streetName,
                    unitNumber: this.state.unitNumber,
                    postalCode: this.state.postalCode,
                    cityCountry: this.state.cityCountry,
                    lastNric: this.state.NIRC,
                    existingDementiaSingapore: this.state.existingDementiaSingapore,
                    dementiaHospital: {
                        hospitalName: this.state.hospitalName,
                        hospitalDepartment: this.state.hospitalDepartment,
                        appointment: 'DAILY',
                        symptoms: this.state.symptoms
                    },
                  dementiaDetail:{
                        language: this.state.language,
                        typeDementia: this.state.dementiaType,
                        stageDementia: this.state.dementiaStage
                    },
                    registrationPlatform: "WEB",
                    masterPartnerUid: this.state.masterPartnerUid === "null" ? null : this.state.masterPartnerUid,
                    partnerToken: this.state.partnerToken === "null" ? null : this.state.partnerToken,
                    cardAddress: this.state.cardAddress,
                    metadata: this.state.cardAddress ? {
                        cardAddress: {
                            partnerAddress: this.state.partnerAddress,
                            partnerBranch: this.state.partnerBranch,
                            partnerPostalCode: this.state.partnerPostalCode,
                        }
                    } : {}
                }
            }
          })
          .then(res => {
            console.log(res)
            if(res.status==200 && res.data.type=="SUCCESS"){
                sessionStorage.clear()
                this.setState({show: true})
                this.setState({uid:res.data.data.account.uid,
                                token:res.data.data.account.token})
            }else{
                Swal.fire({
                    title: 'Warning!',
                    text: `${res.data.data.message}`,
                    icon: 'info',
                    confirmButtonText: 'OK'
                  })
               
            };
          }, (error) => {
            console.log(error);
          });
        }
    }
    
    changeYes=()=>{
        if(this.state.SwitchYes == TypeInactive ){
            this.setState({SwitchYes: TypeActive,existingDementiaSingapore:true, SwitchNo:TypeInactive})
            
        }
    }
    changeNo=()=>{
    if(this.state.SwitchNo == TypeInactive ){
       
        this.setState({SwitchNo: TypeActive,existingDementiaSingapore:false, SwitchYes:TypeInactive})
    }
}
    
    onChangeText = (stateName, value) => {
        this.setState({ [stateName]: value });
    };
    
    agreeSubmit = (e)=>{
       
        if(this.state.agree1){
            this.setState({agree1:false,agree:true})
        }else{
            this.setState({agree1:true}, () => {
                console.log(`agree1: ${this.state.agree1}`);
                this.agreeSubmit3()
            });
        }
    }
    agreeSubmit1  = (e)=>{
       
        if(this.state.agree2){
            this.setState({agree2:false,agree:true})
        }else{
        
            this.setState({agree2:true},() => {
                console.log(`agree2: ${this.state.agree2}`);
                this.agreeSubmit3()
            });
        }
    }
    agreeSubmit3 = ()=>{
       

        
        if (this.state.agree1 == true && this.state.agree2 == true){
            this.setState({agree:false})

        }
}
back=()=>{
    
    this.props.history.push({pathname:'/registrationResidingAddressPWD',
    state:{
      username: this.state.username,
      password: this.state.password,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      displayName: this.state.displayName,
      gender : this.state.gender,
      NIRC: this.state.NIRC,
      birth: this.state.birth,
      language: this.state.language,
      dementiaType: this.state.dementiaType,
      dementiaStage: this.state.dementiaStage,
      email: this.state.email,
      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      idTokenGoogle:this.state.idTokenGoogle,
      credentialType: this.state.credentialType, 
      phone:this.state.phone,

      homeNumber:this.state.homeNumber,
      masterPartnerUid: this.state.masterPartnerUid,
      partnerToken: this.state.partnerToken,
      partnerAddress: this.state.partnerAddress,
      partnerBranch: this.state.partnerBranch,
      partnerPostalCode: this.state.partnerPostalCode,
      cardAddress: this.state.cardAddress
  }}
  )
}
    handleCloseError =()=>{
        this.setState({showError:false})
      }
  render() {
      const {agree, SwitchYes,SwitchWeekly,SwitchNo,SwitchYearly,hospitalName,hospitalDepartment,appointment,symptoms, masterPartnerUid}= this.state
   
   let valid =false;
   if(this.state.agree1 == true && this.state.agree2 == true){
       valid=true
   }

      return (
      <>
        <StyledCG>
            <Header title={masterPartnerUid !== "null" ? 'Create PLWD Account' : null} />
            <h2 style={{marginTop:'6%',marginBottom:'6%'}}>Screening Questions</h2>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Hospital Name</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type hospital name here...',name:"hospitalName",
                                                                    value:hospitalName,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('hospitalName', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Hospital Department</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type hospital department here...',name:"hospitalDepartment",
                                                                    value:hospitalDepartment,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('hospitalDepartment', e.target.value),}}/>
                   
                    </Col>
                </Row>
                <Row  style={{marginTop:'10px'}}>
                    <Col md={6} style={{margin:'auto'}}>
                        <label style={{lineHeight:'1.4'}}>Currently or previously used services from Dementia Singapore, formerly known as Alzheimer's Disease Association (ADA)</label>
                    </Col>
                    <Col md={6} >
                    <Row>
                    <Col xs={6}>
                    <button style={{marginRight:'20px',marginBottom:'10px'}} className="type" onClick={this.changeYes}><Row style={{alignItems:'center'}}><img src={SwitchYes} style={{marginRight:'15px'}} /> Yes</Row></button>
                    </Col>
                    <Col xs={6}>
                    <button style={{marginRight:'20px',marginBottom:'10px'}} className="type" onClick={this.changeNo}><Row style={{alignItems:'center'}}><img src={SwitchNo} style={{marginRight:'15px'}} /> No</Row></button>
                    </Col></Row></Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>2 Signs and Symptoms</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your signs and symptoms here',name:"symptoms",
                                                                    value:symptoms,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('symptoms', e.target.value),}} />
                    </Col>
                </Row>
                {/* <Row style={{lineHeight:'18px',display:'flex',justifyContent:'center'}}>
                    <Col md={6}></Col>
                    <Col md={6} className='p'>
                       <Row style={{paddingLeft:'5%',marginTop:'3%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>* By clicking next, you consent to DSG collecting your information for the delivery of service.</p> </Row>
                       <Row style={{marginTop:'2%',paddingLeft:'5%', marginBottom:'3%'}}> <p style={{color:'#758BA9', fontWeight:'normal',textAlign:'start'}}>For more details on our terms of use and privacy policy, please  click </p> <a href='/privacy-policy' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}> here</a> .</Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}></Col>
                    <Col md={6}>
                       <Row style={{paddingLeft:'15px'}}>
                           <Col md={1}> 
                                <label  className="containerCheck" >
                                    <input type="checkbox" onClick={this.agreeSubmit.bind(this)}/>
                                    <span className="checkmark"></span>
                                </label>
                            </Col> 
                            <Col md={10} >
                                <p style={{color:'#758BA9',fontSize:'16px'}}>I agree to the terms of use.</p>
                            </Col>
                       </Row>
                       <Row style={{paddingLeft:'15px',marginTop:'3%'}}><Col md={1}>  <label  className="containerCheck" ><input type="checkbox" onClick={(e) => this.agreeSubmit1(e)}/><span className="checkmark"></span></label></Col><Col md={10} ><p style={{color:'#758BA9',fontSize:'16px'}}>I hereby declare that the details furnished above are true and correct to the best of my knowledge.</p></Col>
                       </Row>
                    </Col>
                </Row> */}

                
         
                <Row style={{marginTop:'3%',marginBottom:'5%'}}>
            <Col md={6}></Col>
                 <Col md={3}>
                <Button  onClick={this.back} containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
                </Col>
                <Col md={3} >
                <Button disabled={hospitalName === '' || hospitalDepartment === '' || symptoms === ''? true : false}  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Next"  onClick={this.handleShowConfirm}/>
                <PopUpBoxConfirmation showConfirmation={this.state.showConfirmation} handleNo={this.handleNo} handleYes={this.handleYes}/>
                <PopUpBox body={masterPartnerUid !== "null" ? 'Success! Your PLWD account has been created!' : 'Your account has been successfully created!'} show={this.state.show}  handleClose={this.handleClose}/>
                <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
          
              
                </Col>
                </Row>
           
            </StyledCG>

        
       
      </>
    )
  }
}


export default screeningQuestion