import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { Container, Row, Col, } from 'react-grid-system'
import DatePicker from "react-datepicker";
import styled from 'styled-components'
import Swal from 'sweetalert2'
import Select from 'react-select'
import validator from 'validator';

import "react-datepicker/dist/react-datepicker.css";


import Header from '../../components/header2'
import Input from '../../components/input'
import Button from '../../components/button'

import checkActive from '../../assets/images/ACTIVE.png'
import checkInactive from '../../assets/images/INACTIVE.png'

import { StyledSummary, Label } from '../styles/StyledSummary'
import { set } from 'react-ga';

import Polygon from '../../assets/images/Polygon.png'

const steps = ['Details', 'Address', 'Questions', 'Summary', 'Tagging']

import { Fonts, Colors,  } from '../../themes'

const inputPointerStyle = `
  background: #FFFFFF;
  cursor: pointer;
  border: 1px solid #B1C2D8;
  .input-field {
    cursor: pointer;
  }
`

const inputStyle = `
  background: #FFFFFF;
  cursor: pointer;
  border: 1px solid #B1C2D8;
`

const languageData = [
  {
    id: 0,
    value: 'English',
    label: 'English'
  },
  {
    id: 1,
    value: 'Mandarin',
    label: 'Mandarin'
  },
  {
    id: 2,
    value: 'Malay',
    label: 'Malay'
  },
  {
    id: 3,
    value: 'Tamil',
    label: 'Tamil'
  },
  {
    id: 4,
    value: 'Hokkien',
    label: 'Hokkien',
  },
];

const stageofDementiaData =[
  {
    id: 0,
    name : 'MILD',
    value: 'MILD',
    label: 'MILD'
  },
  {
    id: 1,
    name : 'MODERATE',
    value: 'MODERATE',
    label: 'MODERATE'
  },
  {
    id: 2,
    name : 'SEVERE',
    value: 'SEVERE',
    label: 'SEVERE'
  },
]

const typeOfDementiaData = [
  {
    id: 0,
    name: `Alzheimer's Disease`,
    value: `Alzheimer's Disease`,
    label: `Alzheimer's Disease`
  },
  {
    id: 1,
    name: 'Vascular Dementia',
    value: `Vascular Dementia`,
    label: `Vascular Dementia`
  },
  {
    id: 2,
    name: 'Frontotemporal Dementia (FTD)',
    value: `Frontotemporal Dementia (FTD)`,
    label:  `Frontotemporal Dementia (FTD)`
  },
  {
    id: 3,
    name: 'Lewy Body Dementia (LBD)',
    value: `Lewy Body Dementia (LBD)`,
    label: `Lewy Body Dementia (LBD)`
  },
  {
    id: 4,
    name: 'Mild Cognitive Impairment (MCI)',
    value: `Mild Cognitive Impairment (MCI)`,
    label: `Mild Cognitive Impairment (MCI)`
  },
];


function SummaryPLWD({
  location,
  history,
}) {
  const [state, setState] = useState({
    agree: false,
    agree2: false,
    agree3: false,
    accountData: {}
  })

  const { agree, agree2, agree3, accountData, } = state
  const { credential } = location.state
  const { 
    firstname,
    lastname,
    gender,
    lastNric,
    birth,
    dementiaDetail,
    email,
    phone,
    homeNumber,
    streetName,
    unitNumber,
    postalCode,
    cityCountry,
    dementiaHospital,
    existingDementiaSingapore,
    displayName,
    metadata,
    cardAddress,
  } = accountData
  
  useEffect(() => {
    if (credential) {
      setState(state => ({
        ...state,
        accountData: {
          ...credential.accountData,
          displayName: `${credential.accountData.firstname} ${credential.accountData.lastname}`
        },
      }))
    }
  }, [])

  const renderField = ({ label, value, onChange, maxLength = 50, }) => {
    return (
      <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
        <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
          <Label>{label}</Label>
        </Col>
        <Col md={6} xs={12} offset={{ md: 1, }}>
          <Input
            inputProps={{
              value,
              onChange,
              maxLength,
            }}
            containerStyle={inputStyle}
          />
        </Col>
      </Row>
    )
  }

  const _handleCheckbox = (e, agree) => {
    let checked = e.target.checked;
    setState(state => ({
      ...state,
      [agree]: checked
    }))
  }
  
  const handleChange = ({ stateName, value, }) => {
    setState(state => ({
      ...state,
      accountData: {
        ...state.accountData,
        [stateName]: value
      }
    }))
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <Input
        inputProps={{
          value: props.value || props.placeholder,
          onClick: props.onClick, 
          ref,
        }}
        containerStyle={`
          ${inputPointerStyle}
          width: 190%;
        `}
      />
    );
  });

  const renderDropdown = ({ label, stateName, value, data, objName }) => {
    const changedValue = state.accountData.dementiaDetail

    return (
      <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
        <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
          <Label>{label}</Label>
        </Col>
        <Col md={6} xs={12} offset={{ md: 1, }}>
          <Select  
            onChange={(e) => {
              changedValue[stateName] = e.value
              handleChange(stateName, changedValue)
            }} 
            value={value} 
            options={data} 
          />
        </Col>
      </Row>
    )
  }

  const getDropdownValue = ({ data, value }) => {
    return data.filter(item => item.value === value)[0]
  }

  const buttonDisabled = !agree || !agree2 || !agree3 || displayName === '' || firstname === '' || lastname === '' || lastNric === ''  || streetName === '' || postalCode === '' || cityCountry === '' || dementiaHospital.hospitalName === ''  || dementiaHospital.hospitalDepartment === '' || dementiaHospital.symptoms === ''

  return (
    <StyledSummary>
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <div className="base-container">
          <Header title={accountData.masterPartnerUid ? 'Create PLWD Account' : null} />
          <p className="title-summary">Person Living with Dementia Summary</p>
          <Row nogutter style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
            {
              steps.map((item, index) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="circle" style={{ backgroundColor: index < 4 ? '#502773' : '#B1C2D8' }}>
                      <span className="circle-text" style={{ color: index < 4 ? '#FFFFFF' : '#758BA9' }}>{index + 1}</span>
                    </div>
                    <span className="step-text">{item}</span>
                  </div>
                )
              })
            } 
            <div className="line" />
          </Row>
          <div className="thank-you">Thank you for filling in your details!</div>
          <div className="thank-you2">Your physical CARA card will be processed based on the following details:</div>
          <Row  nogutter style={{ marginTop: '33px', borderTop: '1px solid #B1C2D8', paddingTop: '29px' }}>
            <Row nogutter style={{ width: '100%', }}>
              <Col md={5} xs={12}>
                <div className="confirm-name">Please confirm your name</div>
                <div className="confirm-name2">This is how your name will be printed on your physical CARA card, please check it matches your NRIC.</div>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }}>
                <Input
                  inputProps={{
                    value: displayName,
                    onChange: (e) => {
                      setState(state => ({
                        ...state,
                        accountData: {
                          ...credential.accountData,
                          displayName: e.target.value
                        }
                      }))
                    },
                    maxLength: 30,
                  }}
                  containerStyle={`
                    background: #FFFFFF;
                    cursor: pointer;
                    border: 1px solid #B1C2D8;
                  `}
                />
              </Col>
            </Row>
             {
               [
                renderField({ 
                  label: 'First Name', 
                  value: firstname,
                  onChange: (e) => handleChange({ stateName: 'firstname', value: e.target.value })
                }),
                renderField({ 
                  label: 'Last Name (Surname)', 
                  value: lastname,
                  onChange: (e) => handleChange({ stateName: 'lastname', value: e.target.value })
                }),
               ]
             }    
            <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>Gender</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }}>
                <Row style={{  }}>
                  <Col xs={6}>
                    <button  className="type" onClick={() => handleChange({ stateName: 'gender', value: 'male' }) }>
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'male' ? checkActive : checkInactive} style={{marginRight:'15px'}} /> 
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Male</span>
                      </Row>
                    </button>
                  </Col>
                  <Col xs={6}>
                    <button  className="type" onClick={() => handleChange({ stateName: 'gender', value: 'female' })}>
                      <Row style={{displa:'flex',alignItems:'center'}}><img src={gender === 'female' ? checkActive : checkInactive} style={{marginRight:'15px', }} />
                        <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Female</span>
                      </Row>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row> 
            {renderField({ 
              label: 'Last 4 characters of NRIC', 
              value: lastNric,
              onChange: (e) => handleChange({ stateName: 'lastNric', value: e.target.value, }),
              maxLength: 4, 
            })}
            <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
              <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                <Label>{`Date of Birth`}</Label>
              </Col>
              <Col md={6} xs={12} offset={{ md: 1, }} style={{ width: '100%', }}>
                <DatePicker 
                  peekNextMonth  
                  showMonthDropdown   
                  showYearDropdown  
                  customInput={<CustomInput />}   
                  filterDate = {
                    (date) => { 
                      return moment() > date;
                    }
                  }  
                  dropdownMode= 'select' 
                  dateFormat="dd-MM-yyyy" 
                  selected={birth ? 
                      new Date(birth) 
                    : null
                  } 
                  onChange={date => handleChange({ stateName: 'birth', value: date, })} 
                  placeholderText="Please select a date" 
                />
              </Col>
            </Row>
            {/* {renderField({ label: 'Date of Birth', value: moment(birth).format('DD MMMM YYYY') })} */}
            
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />

            {
              [
                renderDropdown({ label: 
                  'Preferred Language of Communication', 
                  value: getDropdownValue({ data: languageData, value: dementiaDetail && dementiaDetail.language }), 
                  data: languageData, 
                  stateName: 'language',
                  objName: 'dementiaDetail' 
                }),
                renderDropdown({ 
                  label: 'Type of Dementia', 
                  value: getDropdownValue({ data: typeOfDementiaData, value: dementiaDetail && dementiaDetail.typeDementia }),
                  data: typeOfDementiaData, 
                  stateName: 'typeDementia',
                  objName: 'dementiaDetail' 
                }),
                renderDropdown({ 
                  label: 'Stage of Dementia', 
                  value: getDropdownValue({ data: stageofDementiaData, value: dementiaDetail && dementiaDetail.stageDementia }), 
                  data: stageofDementiaData, 
                  stateName: 'stageDementia',
                  objName: 'dementiaDetail' 
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 10px' }} />
            {
              [
                renderField({ 
                  label: 'Email Address (optional)', 
                  value: email,
                  onChange: (e) => handleChange({ stateName: 'email', value: e.target.value }) 
                }),
                renderField({ 
                  label: 'Mobile Number (optional)', 
                  value: phone,
                  onChange: (e) => handleChange({ stateName: 'phone', value: e.target.value }) 
                }),
                renderField({ 
                  label: 'Home Number (optional)', 
                  value: homeNumber,
                  onChange: (e) => handleChange({ stateName: 'homeNumber', value: e.target.value }) 
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            {/* <div className="please-text">Please confirm this is the address for your CARA card to be posted to</div>
            <div className="please-text2">If address is not correct, please click on <span className="circle-info">2</span> Address at the top of page to edit information</div> */}
            {
              [
                renderField({ 
                  label: 'Street Name', 
                  value: streetName,
                  onChange: (e) => handleChange({ stateName: 'streetName', value: e.target.value }),
                  maxLength: 200,
                }),
                renderField({ 
                  label: 'Unit Number', 
                  value: unitNumber,
                  onChange: (e) => handleChange({ stateName: 'unitNumber', value: e.target.value }) 
                }),
                renderField({ 
                  label: 'Postal Code', 
                  value: postalCode,
                  onChange: (e) => handleChange({ stateName: 'postalCode', value: e.target.value }) 
                }),
                renderField({ 
                  label: 'City, Country', 
                  value: cityCountry,
                  onChange: (e) => handleChange({ stateName: 'cityCountry', value: e.target.value }) 
                }),
              ]
            }
            
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 30px' }} />
            <div>
              <div class="checkbox-container">
                <label class="checkbox-label">
                    <input type="checkbox" onClick={(e) => {
                      setState(state => ({
                        ...state,
                        accountData: {
                          ...state.accountData,
                          cardAddress: e.target.checked
                        }
                      }))
                    }} checked={cardAddress} />
                    <span class="checkbox-custom rectangular"></span>
                </label>
                <div class="input-title">Please check this box if you would like CARA Physical card to be sent to another address.</div>
              </div>
            </div>
            {
              cardAddress &&
              [
                renderField({ 
                  label: 'Address', 
                  value: (metadata && metadata.cardAddress) && metadata.cardAddress.partnerAddress,
                  onChange: (e) => {
                    setState(state => {
                      const currentSeparateAddress = (state.accountData.metadata && state.accountData.metadata.cardAddress) ? state.accountData.metadata.cardAddress : {}
                      return {
                        ...state,
                        accountData: {
                          ...state.accountData,
                          metadata: {
                            cardAddress: {
                              ...currentSeparateAddress,
                              partnerAddress: e.target.value
                            }
                          }
                        }
                      }
                    })
                  }
                }),
                renderField({ 
                  label: 'Unit Number', 
                  value: (metadata && metadata.cardAddress) && metadata.cardAddress.partnerBranch,
                  onChange: (e) => {
                    setState(state => {
                      const currentSeparateAddress = (state.accountData.metadata && state.accountData.metadata.cardAddress) ? state.accountData.metadata.cardAddress : {}
                      return {
                        ...state,
                        accountData: {
                          ...state.accountData,
                          metadata: {
                            cardAddress: {
                              ...currentSeparateAddress,
                              partnerBranch: e.target.value
                            }
                          }
                        }
                      }
                    })
                  } 
                }),
                renderField({ 
                  label: 'Postal Code', 
                  value: (metadata && metadata.cardAddress) && metadata.cardAddress.partnerPostalCode,
                  onChange: (e) => {
                    setState(state => {
                      const currentSeparateAddress = (state.accountData.metadata && state.accountData.metadata.cardAddress) ? state.accountData.metadata.cardAddress : {}
                      return {
                        ...state,
                        accountData: {
                          ...state.accountData,
                          metadata: {
                            cardAddress: {
                              ...currentSeparateAddress,
                              partnerPostalCode: e.target.value
                            }
                          }
                        }
                      }
                    })
                  } 
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0 30px' }} />
            {
              [
                renderField({ 
                  label: 'Hospital Name', 
                  value: dementiaHospital && dementiaHospital.hospitalName ,
                  onChange: (e) => {
                    const dementiaHospitalChanged = dementiaHospital
                    dementiaHospital[`hospitalName`] = e.target.value
                    handleChange({ stateName: 'dementiaHospital', value: dementiaHospitalChanged })
                  }
                }),
                renderField({ 
                  label: 'Hospital Department', 
                  value: dementiaHospital && dementiaHospital.hospitalDepartment,
                  onChange: (e) => {
                    const dementiaHospitalChanged = dementiaHospital
                    dementiaHospital[`hospitalDepartment`] = e.target.value
                    handleChange({ stateName: 'dementiaHospital', value: dementiaHospitalChanged })
                  } 
                }),
                <Row nogutter style={{ width: '100%', marginTop: '40px' }}>
                  <Col md={5} xs={12} style={{ display: 'flex', alignItems: 'center', }}>
                    <Label>Currently or have previously used services from Dementia Singapore, formerly known as Alzheimer's Disease Association (ADA)</Label>
                  </Col>
                  <Col md={6} xs={12} offset={{ md: 1, }}>
                    <Row style={{  }}>
                      <Col xs={6}>
                        <button  className="type" onClick={() => {
                          handleChange({ stateName: 'existingDementiaSingapore', value: true })
                        }}>
                          <Row style={{displa:'flex',alignItems:'center'}}><img src={existingDementiaSingapore ? checkActive : checkInactive} style={{marginRight:'15px'}} /> 
                            <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> Yes</span>
                          </Row>
                        </button>
                      </Col>
                      <Col xs={6}>
                        <button  className="type" onClick={() => {
                          handleChange({ stateName: 'existingDementiaSingapore', value: false })
                        }}>
                          <Row style={{displa:'flex',alignItems:'center'}}><img src={!existingDementiaSingapore ? checkActive : checkInactive} style={{marginRight:'15px', }} />
                            <span style={{ fontFamily: `"TruenoRegular", Arial, sans-serif`, fontWeight: `bold`, }}> No</span>
                          </Row>
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>,
                renderField({ 
                  label: `Name 2 Signs & Symptoms Experienced by the Person Living with Dementia`, 
                  value: dementiaHospital && dementiaHospital.symptoms,
                  onChange: (e) => {
                    const dementiaHospitalChanged = dementiaHospital
                    dementiaHospital[`symptoms`] = e.target.value
                    handleChange({ stateName: 'dementiaHospital', value: dementiaHospitalChanged })
                  }
                }),
              ]
            }
            <div style={{ width: '100%', height: '1px', background: '#B1C2D8', margin: '50px 0' }} />
            <div>
              <span className="confirm-text">Inaccurate information may affect your application. Please check carefully.</span>
              <div />
              {/* <div className="confirm-text2">By clicking next, you consent to DSG collecting your information for the delivery of service.<br /><br />For more details on our terms of use and privacy policy, please click<a href='/privacy-policy' target="_blank"  style={{color:'#758BA9',paddingLeft:'5px', fontWeight:'bold',}}>here</a>.</div> */}
              <div style={{ marginTop: '13px' }}>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title"> I agree to the <a href='/Terms-Of-Use' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Terms of Use</a>,<a href='/Privacy-Policy' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>Privacy Policy</a> and the<a href='/Membership-Terms' target="_blank"  style={{color:' #502773',paddingLeft:'5px', fontWeight:'bold',}}>CARA Membership Terms and Conditions</a></div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label">
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree2')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">I hereby declare that all information provided by me in this registration form is true and accurate to the best of my knowledge.</div>
                </div>
                <div class="checkbox-container">
                  <label class="checkbox-label" style={{ marginRight: '15px' }}>
                      <input type="checkbox" onClick={(e) => _handleCheckbox(e, 'agree3')} />
                      <span class="checkbox-custom rectangular"></span>
                  </label>
                  <div class="input-title">By clicking NEXT, I agree to the collection, use and/or disclosure of the entered personal data for the purposes of processing the CARA membership application and providing services associated with the CARA membership. This includes, but is not limited to, sending me marketing information about new CARA features, notification of marketing events, initiatives and promotions, lucky draws, membership and rewards schemes and other promotions.</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', margin: '57px 0 120px' }}>
              <div>
                <Button
                  text="Back"
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                    color: #502773;
                  `}
                  bordered
                  onClick={() => history.goBack()}
                />
              </div>
              <div style={{ width: '21px', }} />
              <div>
                <Button
                  text="Next"
                  textStyle={`
                    line-height: 60px;
                    text-align: center;
                    width: 100%;
                  `}
                  disabled={buttonDisabled}
                  onClick={() => {
                    let letters = /^[A-Za-z]+$/;
                    
                    if (
                        (firstname == '') || (lastname =='') || (state.displayName == '') || (state.gender == '') || (lastNric == '') || (!birth)
                    ) {
                      Swal.fire({
                        title: 'Warning!',
                        text: 'please fill the required data and choose date',
                        icon: 'info',
                        confirmButtonText: 'OK'
                      })
                    } 
                    
                    else if ((email != '' && !validator.isEmail(email)))  {
                      Swal.fire({
                        title: 'Warning!',
                        text: 'please fill email and phone number correctly',
                        icon: 'info',
                        confirmButtonText: 'OK'
                      })
                    }

                    else if (lastNric.length < 4) {
                      Swal.fire({
                        title: 'Warning!',
                        text: 'Last NRIC must be 4 character.',
                        icon: 'info',
                        confirmButtonText: 'OK'
                      })
                    }
                    
                    else if ( 
                      ( (!isNaN(lastNric[0]) || lastNric[0] >= 1 || lastNric[0] <= 10) ) && 
                      ( (!isNaN(lastNric[1]) || lastNric[1] >= 1 || lastNric[1] <= 10) ) && 
                      ( (!isNaN(lastNric[2]) || lastNric[2] >= 1 || lastNric[2] <= 10) ) && 
                      lastNric[3].match(letters)
                    ) {
                    
                      history.push('sample-card', {
                        ...location.state,
                        credential: {
                          ...location.state.credential,
                          accountData: {
                            ...accountData,
                            metadata: cardAddress ? {
                              caraPro: false,
                              ...accountData.metadata,
                            } : { caraPro: false, }
                          },
                        }
                      })
                      return state
                
                    } else {
                      Swal.fire({
                        title: 'Warning!',
                        text: 'Incorrect NRIC format, please input last 3 numbers of NRIC and final alphabet. For e.g If NRIC is S1234123A enter 123A.',
                        icon: 'info',
                        confirmButtonText: 'OK'
                      })
                    }
                  }}
                />
              </div>
            </div>

          </Row>
        </div>
      </Container>

    </StyledSummary>
  )
}

export default SummaryPLWD
