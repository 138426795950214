import React, { Component } from 'react';
import Navigation from '../components/navigation';
import Section1 from '../components/content/Section1'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './LandingPage.css'
import Img from '../assets/images/asian-elderly.png'
import Card from 'react-bootstrap/Card'
import Pict1 from '../assets/images/NewPict1.png'
import Pict2 from '../assets/images/Rewards & Benefits - img.png'
import Pict3 from '../assets/images/Solutions & Support - img.png'
import Pict4 from '../assets/images/Pict4.png'
import PWD from '../assets/images/PWD.png'
import CG from '../assets/images/CG.png'
import CardSingle from '../components/card'
import CardImage from '../components/cardImage'

import CardDeck from 'react-bootstrap/CardDeck'
import CardIcon from '../components/cardIcon';
import Rounded from '../assets/images/Ellipse.png'
import Logo from '../assets/images/Logo.png'
import  '../components/navigation/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import PictNew1 from '../assets/images/PictNew1.png'
import PictNew2 from '../assets/images/PictNew2.png'
import PictNew3 from '../assets/images/PictNew3.png'
import PictNew4 from '../assets/images/PictNew4.png'
import Icon3 from '../assets/images/Icon3.png'
import HeaderImage from '../assets/images/header-image.jpg'

import CaraTrasparentLogo from '../assets/images/CARA_iphone_mockup.png'
import Garddens from '../assets/images/gardens.png'
import ComfortDelGro from '../assets/images/ComfortDelgro-img05.png'
import homefresh from '../assets/images/homefresh.png'
import euyansang from '../assets/images/eusanyang.png'
import foodfor from '../assets/images/foodfor.png'
import speedoc from '../assets/images/speedoc.png'


import AppS from '../assets/images/GPlay.png'

import Gplay from '../assets/images/AppS.png'
import { Link } from 'react-router-dom';
import CardIconCustom from '../components/cardIconCustom';
import { StyledLandingPage } from './styles/StyledLandingPage'

class LandingPage extends Component {
    componentDidMount() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
    
    render(){
        const style = {
            position:"relative",
            top:"100px",
            left: "77px"
            
        }
        const styleTitle = {
          position:"relative",
          fontFamily: "Gotham",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "38px",
          lineHeight: "20px",
          /* identical to box height, or 53% */
          
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          
          color: "#000",
          top:"0px",
          left: "7px"
          
      }
        return(
          <StyledLandingPage>
            <Navigation Home="#home" Download='#download' Why="#about" How="#howitworks" Social="#benefit" Faq="#faq"/>
            
            <span class="anchor" id="home"></span>
            <Row className="header-container">
              <Col xs={12} md={6} className="left-purple">
                <h2>Empowering<br />Persons Living with<br />Dementia and Caregiver</h2>
                <p >A new lifestyle and community digital platform that provides an ecosystem for personalised dementia support and care.</p>
                <div className="header-button"><a href='#about' style={{ color:'#502773' }}> What is CARA?</a></div>
              </Col>
              <Col xs={12} md={6} style={{padding: 0}} className="header-image-container">
                <img src={HeaderImage} className="header-image" />
              </Col>
            </Row>
            {/* <div className="header-container" id="home">
              <div className="left-purple">
                <h2>Empowering<br />Persons Living with<br />Dementia and Caregivers</h2>
                <p >A new lifestyle and community digital platform that provides an ecosystem for personalised dementia support and care.</p>
                <div className="header-button" onClick="location.href='#about';"><a href='#about' style={{ color:'#502773' }}> What is CARA?</a></div>
              </div>
              <div className="header-image-container">
                <img src={HeaderImage} className="header-image" />
              </div>
            </div> */}

            {/* <div id="home" className="BgTop"> 
                <div className="splash">
                </div>
                <h2>Empowering Persons Living with Dementia and Caregivers</h2>
                <p >A new lifestyle and community digital platform that provides an ecosystem for personalised dementia support and care.</p>
                <div className="button">What is CARA?</div>
            </div> */}
            
            {/* <div id='about1'>
            <div id="about" >  
            </div>   
            </div> */}
            <span class="anchor" id="about"></span>
              <Container >
                <Row  style={{margin:'0px',padding:'0px'}}>
                  <Col style={{margin:'0px', marginLeft: '25px', marginRight: '35px', padding:'0px'}}>
                    <h5 style={{display:'flex',justifyContent:'flex-start', fontFamily: `"TruenoBold", Arial, sans-serif`,}}>About CARA</h5>
                    <p style={{paddingBottom:'40px', color: '#424242', fontFamily: `"TruenoRegular", Arial, sans-serif`, lineHeight: '1.7', fontSize: 18 }}>An initiative by Dementia Singapore (formerly known as Alzheimer’s Disease Association), <b style={{ color: 'black', fontWeight: 'bold', }}>CARA</b> stands for <b style={{ color: 'black', fontWeight: 'bold', }}>Community, Assurance, Rewards and Acceptance</b>.  It is a lifestyle and community digital platform that provides easy access for persons living with dementia and caregivers to connect to an ecosystem of solutions. It serves as a key touchpoint for members, providing personalised support across the journey of living with and caring for dementia.</p>
                  </Col>
                </Row>
                <Row style={{margin:'0px auto', overflow: 'hidden', }}>
                  <Col >
                    <CardImage image={Pict1} title="Membership Card" text="Your very own CARA membership card. This serves as a gateway to access tailored benefits and support.  It is also a unique identifier to facilitate the journey of living with dementia in the community."/>
                  </Col>
                  <Col>
                    <CardImage  image={Pict2} title="Rewards & Benefits" text="CARA partners a range of organisations to drive the movement towards an inclusive society. Members of CARA will get to enjoy a rewarding care journey through tailored benefits from our carefully selected partners."/>
                  </Col>
                  <Col>
                    <CardImage  image={Pict3} title="Solutions & Support" text="CARA curates a list of solution providers relevant to our members’ needs. Members can navigate options at their fingertips and  tap on the community platform for further support, allowing a greater peace of mind. "/>
                  </Col>
                </Row>
              </Container>
            

            <div className="Top">
              <div className="breakPoint">
                <h3 style={{paddingTop: '50px'}}>A LIFESTYLE AND COMMUNITY PLATFORM</h3>
                <p>Empowering persons living with dementia and their Caregiver to lead a meaningful and purpose-filled life.</p>  
                <button ><a href='#download'>Download CARA</a></button>
              </div>
            </div>
            <span class="anchor" id="howitworks"></span>
            <div className="section2">
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <h5>How It Works</h5>
                  </Col>
                </Row>
                <Row style={{ }}>
                  <Col xs={12} md={6} lg={3}>  
                    <CardSingle image={PictNew1} title="1. Download CARA" text='on your phone by clicking the button below.' />
                  </Col> 
                  <Col xs={12} md={6} lg={3}> 
                    <CardSingle image={PictNew2} title="2. Sign up" text='for your membership by providing details of person living with dementia and caregiver(s).' />
                  </Col>
                  <Col xs={12} md={6} lg={3}  > 
                    <CardSingle image={PictNew3} title="3. Wait to be notified" text='of your registration outcome by SMS/email.' />
                  </Col>
                  <Col xs={12} md={6} lg={3}> 
                    <CardSingle image={PictNew4} title="4. Enjoy your benefits!" text='Once approved, start using the mobile app and enjoy the features and benefits that come with it! ' />
                  </Col>
                </Row>
                <Row style={{ marginTop:'60px', display:'flex', justifyContent:'center', alignItems: 'center' }}>  <button style={{width: '300px',height: '50px', display:'flex', justifyContent:'center', alignItems: 'center' }}><a href='#download'>Download CARA</a></button></Row>
              </Container>
            </div>

            <span class="anchor" id="benefit"></span>
            <div className="section3">
              <Row style={{margin: '0 1% 0 3%'}}>
                <Col>
                  <h2>Latest Benefits</h2>
                  <p>Join as a CARA member today to access tailored benefits from our carefully selected partners.</p>
                </Col>
              </Row>
              <Row className="CardIcon">
                  <CardIcon  image={Garddens} title='Gardens by the Bay' />
                  <CardIcon  image={euyansang}  title='Eu Yan Sang'/>
                  <CardIcon image={foodfor} title='Food for Thought' />
                  <CardIcon image={speedoc}  title='Speedoc'/>
                  <CardIcon image={homefresh}  title='Home Fresh'/>
                  {/* <CardIconCustom image={ComfortDelGro}  title='ComfortDelGro MedCare'/> */}
                  <CardIcon image={ComfortDelGro}  title='ComfortDelGro MedCare'/>
              </Row>
              <Row style={{display:'flex',justifyContent:'center', alignItems: 'center', marginTop:'50px'}}>
                <button style={{width: '300px',height: '50px', display:'flex', justifyContent:'center', alignItems: 'center' }}><a href='#download'>Download CARA</a></button>
              </Row>
              <p style={{paddingTop:'60px', textAlign: 'center'}}>Join as a member to access all benefits</p>
            </div>

            <div className="section4">
              <Row>
                <span class="anchor" id="download"></span>
                <Col md={6} className='botRight'>
                  <h3>Download Now!</h3>
                  <br/>
                  <p>Available now on Apple App Store and Google Play. Click the icons below to download now!</p>
                  <br/>
                  <br/>
                  <div className="app-button-container">
                    <button style={{background:'none',border:'none', height: 100}} ><a href='https://play.google.com/store/apps/details?id=com.embreo.carasg'><img src={AppS}/></a></button>
                    <button  style={{background:'none',border:'none', height: 100}} ><a href='https://apps.apple.com/id/app/cara-sg/id1553855834'> <img src={Gplay}/></a></button>
                  </div> 
                </Col>  
                <Col md={6} className="botLeft">
                  <span class="anchor" id="faq"></span>
                  <h3>FAQ</h3>
                  <br/>
                  <ul>
                    <li>I was a member of the Safe Return Card (SRC). Do I need to register again?</li>
                    <li>How long will the approval of membership take?</li>
                    <li>Can I register more than one caretaker for a person with dementia?</li>
                    <li>Can I register my FDW?</li>
                    <li>What if I lose my physical membership card? How can I get another one?</li>
                    <li>What if I don’t have a phone to download the app?</li>
                  </ul>
                  {/* <div className="faq-container">
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- I was a member of the Safe Return Card (SRC). Do I need to register again? </label>
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- How long will the approval of membership take?</label>
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- Can I register more than one caretaker for a person with dementia?</label>
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- Can I register my FDW?</label>
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- What if I lose my physical membership card? How can I get another one?</label>
                    <label style={{ fontFamily: `"TruenoRegular", Arial, sans-serif` }}>- What if I don’t have a phone to download the app?</label>
                  </div>     */}
                  <br/>
                  <Link to='/faq'>
                    <p>See all &gt;</p>
                  </Link>      
                </Col>
              </Row>
            </div>

            <div className="footer">
              <Row style={{margin: '0px', padding:'0'}}>
                <Col lg={6} style={{display:'flex',justifyContent:'flex-start', }}>
                <h4>For more details on our <Link to='/Terms-Of-Use'><b> Terms of Use</b></Link></h4>
                </Col>
                <Col lg={4} style={{display:'flex',justifyContent:'flex-start'}}>
                <h4>Copyright 2021</h4>
                </Col>
              </Row>
            </div>   
          </StyledLandingPage>
        )
    }
}

export default LandingPage