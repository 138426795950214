import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';


export class createAccountCG extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          uid:this.props.location.state.uid,
          token:this.props.location.state.token,
          streetName:this.props.location.state.streetName,
          unitNumber:this.props.location.state.unitNumber,
          postalCode:this.props.location.state.postalCode,
          cityCountry:this.props.location.state.cityCountry,
          masterPartnerUid:this.props.location.state.masterPartnerUid,
          partnerToken:this.props.location.state.partnerToken,

         
          show: false,
        }
      }
   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
 
 submitForm = ()=>{
      
  this.props.history.push({pathname:'/registrationCreateNewCG',
  state:{
    uid:this.state.uid,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
  }})
  console.log(this.state.streetName)
  console.log(this.state.unitNumber)
  console.log(this.state.postalCode)
  console.log(this.state.cityCountry)
  return this.state
}
tagCG=()=>{ 
  this.props.history.push({pathname:'/registrationTagExistingCG',
  state:{
    uid:this.state.uid,
    token:this.state.token,
    streetName:this.state.streetName,
    unitNumber:this.state.unitNumber,
    postalCode:this.state.postalCode,
    cityCountry:this.state.cityCountry,
    masterPartnerUid: this.state.masterPartnerUid,
    partnerToken: this.state.partnerToken,

  }})
  console.log(this.state.token)
  return this.state}
    
  render() {
    return (
      <>
        <StyledCG>
            <Header/>
            <h2 style={{marginTop:'150px'}}>Would you like to create account for your Organisation Staff / Next-of-Kin now?</h2>
            <Container>
                <Row style={{textAlign:'start',marginBottom:'30px',padding:'0'}}>
                <Col style={{display:'flex',justifyContent:'center'}}>
                 
                  <Button containerStyle={StyledButton} buttonStyle={{height:'60px', width:'433px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.submitForm} text="Create New Organisation Staff" />
                  
                </Col>
                
                </Row>
                <Row style={{textAlign:'start',marginBottom:'30px'}}>
                <Col style={{display:'flex',justifyContent:'center'}}>
                 
                  <Button containerStyle={StyledButton} buttonStyle={{height:'60px', width:'433px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.tagCG}  backgroundColor={colors.color14} text="Look for an existing Organisation Staff" />
                  
                </Col>
               
                 </Row>
                
                
         
            </Container>
               
            </StyledCG>

        
       
      </>
    )
  }
}


export default createAccountCG