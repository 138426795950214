import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import validator from 'validator';
import Button from '../../components/button'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import Polygon from '../../assets/images/Polygon.png'
import firststepcgcreateplwd from '../../assets/images/first-step-cg-create-plwd.png';

import Swal from 'sweetalert2'
import moment from 'moment'
import { Images, Colors, Fonts } from '../../themes';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import styled from "styled-components";
import PopUpBoxHandler from '../../components/modalhandler';
import PopUpBox from '../../components/modal'
import DatePicker from "react-datepicker";
import axios from 'axios'
import AppConfig from '../../config'

import "react-datepicker/dist/react-datepicker.css";

const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
  
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv onClick={props.onClick}>
    <Row>
   
      <label ref={ref}>
      <Col >
        {props.value || props.placeholder}
        </Col> 
      </label>
     
      <Col style={{display:'flex',alignSelf:'center',justifyContent:'flex-end',marginRight:'10px'}}>
      <img src={Polygon} onClick={props.onClick} />
</Col>
      </Row>
    </CustomDatePickDiv>
  );
});

const CustomDatePickDiv = styled.div`
  background-color: white;
 
  border-radius: 10px;
  label {
    padding-left:5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}
  }
  
`;

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 
  .input-field { cursor: pointer; }
`;

export class cgDetail extends Component {
  constructor(props) {
    super(props)
        
    this.state = {
      streetName:this.props.location.state.streetName,
      unitNumber:this.props.location.state.unitNumber,
      postalCode:this.props.location.state.postalCode,
      cityCountry:this.props.location.state.cityCountry,   
      username: this.props.location.state.username,
      password: this.props.location.state.password,
      phone: this.props.location.state.phone,
      prefixCountryNum: this.props.location.state.countryCode,
      credentialType: this.props.location.state.isEditing ? this.props.location.state.credential.credentialType :  this.props.location.state.credentialType,
      idTokenGoogle: this.props.location.state.idTokenGoogle,
      masterPartnerUid: this.props.location.state.masterPartnerUid,
      partnerToken: this.props.location.state.partnerToken,
      firstname: '',
      lastname:'',
      displayName: '',
      gender:'',
      date: '',
      birth:'',
      homeNumber:'',
      email:'',
      lastNric:'',
      dateNow:'',
      showError:false,
      errorMessage:'',
      Switch: SwitchNo,
      SwitchGenderM:TypeInactive,
      SwitchGenderF:TypeInactive,
      languageDropdown: false,
      language: {
        id: 1,
        name: 'English',
      },
      typeOfDementiaDropdown: false,
      typeOfDementia: {
        id: 1,
        name: `Alzheimer's Disease`,
      },
      typeOfDementiaFiltered: true,
      languageFiltered: true,
      show: false,
      delivery: '',
      dateSend: moment().format('YYYY-MM-DD'),
    }   
  }
   
  handleShow = (e)=> {
    this.setState({show: true})
  }
   
  componentDidMount() {
    if (window.sessionStorage.getItem("gender") == 'male'){
      this.setState({SwitchGenderM: TypeActive,gender:window.sessionStorage.getItem("gender")})
    }
    if (window.sessionStorage.getItem("gender") == 'female'){
      this.setState({SwitchGenderF: TypeActive,gender:window.sessionStorage.getItem("gender")})
    }
    if (  this.props.location.state.lastNric){
      this.setState({lastNric:  this.props.location.state.lastNric})
    }
    if (window.sessionStorage.getItem("lastNric") != ''){
      this.setState({lastNric:  window.sessionStorage.getItem("lastNric")})
    }
    if (window.sessionStorage.getItem("firstname") != ''){
      this.setState({firstname: window.sessionStorage.getItem("firstname")})
    }
    if (window.sessionStorage.getItem("lastname") != ''){
      this.setState({lastname: window.sessionStorage.getItem("lastname")})
    }
    if (window.sessionStorage.getItem("displayName") != ''){
      this.setState({displayName: window.sessionStorage.getItem("displayName")})
    }
    if (window.sessionStorage.getItem("birth") != ''){
    this.setState({birth: window.sessionStorage.getItem("birth")})
    }
    if (window.sessionStorage.getItem("homeNumber") != null){
      this.setState({homeNumber: window.sessionStorage.getItem("homeNumber")})
    }
    if (window.sessionStorage.getItem("email") != ''){
      this.setState({email: window.sessionStorage.getItem("email")})
    }
    if (window.sessionStorage.getItem("dateNow")!= ''){
      this.setState({dateNow:window.sessionStorage.getItem("dateNow")})
    }
    if (window.sessionStorage.getItem("dateNow")!= ''){
      this.setState({dateNow:window.sessionStorage.getItem("dateNow")})
    } 
    if (window.sessionStorage.getItem("masterPartnerUid") != '' && window.sessionStorage.getItem("masterPartnerUid")!= null){
      this.setState({masterPartnerUid:window.sessionStorage.getItem("masterPartnerUid")})
    }
    if (window.sessionStorage.getItem("partnerToken") != '' && window.sessionStorage.getItem("partnerToken")!= null){
      this.setState({partnerToken:window.sessionStorage.getItem("partnerToken")})
    }
  }

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  }
 
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }

  changeGenderM=()=>{
    if(this.state.SwitchGenderM == TypeInactive || gender =='male' ){
        this.setState({SwitchGenderM: TypeActive,gender:'male', SwitchGenderF:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }

  changeGenderF=()=>{
    if(this.state.SwitchGenderF == TypeInactive || gender =='female' ){
        this.setState({SwitchGenderF: TypeActive,gender:'female', SwitchGenderM:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  
  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        ic
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? 'pointer' : 'not-allowed'}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Polygon}
        {...props}
        dropdownWidth={'100%'}
      />
    );
  }

  submitForm = async () => {
    const accountUrl = AppConfig.ACCOUNT_URL
    if ((this.state.firstname =='' && window.sessionStorage.getItem("firstname") == '') || (this.state.lastname =='' && window.sessionStorage.getItem("lastname") == '') || (this.state.displayName == '' && window.sessionStorage.getItem("displayName") == '') || (this.state.gender =='' && window.sessionStorage.getItem("gender") == '')  || 
    (this.state.birth =='' && window.sessionStorage.getItem("birth") == '')|| (this.state.lastNric =='' || this.state.lastNric == 'null' && window.sessionStorage.getItem("lastNric") == '') || this.state.birth === null || this.state.email === '' ) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please fill all required fields',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      var letters = /^[A-Za-z]+$/;
      let result = {}
    
      if (this.state.lastNric.length < 4  ) {
        Swal.fire({
          title: 'Warning!',
          text: 'Last NRIC must be 4 character.',
          icon: 'info',
          confirmButtonText: 'OK'
        })
      }
    
      else if ( 
      ( (!isNaN(this.state.lastNric[0]) || this.state.lastNric[0] >= 1 || this.state.lastNric[0] <= 10) ) && 
      ( (!isNaN(this.state.lastNric[1]) || this.state.lastNric[1] >= 1 || this.state.lastNric[1] <= 10) ) && 
      ( (!isNaN(this.state.lastNric[2]) || this.state.lastNric[2] >= 1 || this.state.lastNric[2] <= 10) ) && 
      this.state.lastNric[3].match(letters) 
      ) {
        const checkEmail = await axios.post(`${accountUrl}/public/credential/checkCaregiverRegistration`, {
          email : this.state.email,
        })

        if(checkEmail.status == 200 && checkEmail.data.data.registered == true) return Swal.fire({
          title: 'Warning!',
          text: 'This email is already exist',
          icon: 'info',
          confirmButtonText: 'OK'
        })

        window.sessionStorage.setItem("firstname", this.state.firstname);
        window.sessionStorage.setItem("lastname", this.state.lastname);
        window.sessionStorage.setItem("displayName", this.state.displayName);
        window.sessionStorage.setItem("gender", this.state.gender);
        window.sessionStorage.setItem("birth",this.state.birth);
        window.sessionStorage.setItem("email",this.state.email);
        window.sessionStorage.setItem("lastNric",this.state.lastNric);
        window.sessionStorage.setItem("homeNumber",this.state.homeNumber);
        window.sessionStorage.setItem("masterPartnerUid",this.state.masterPartnerUid);
        window.sessionStorage.setItem("partnerToken",this.state.partnerToken);
        
        window.sessionStorage.setItem("dateNow",this.state.dateNow)
        this.props.history.push({pathname:'/CareGiverResidingAddress',
        state:{
          username: this.state.username,
          password: this.state.password,
          prefixCountryNum: this.state.prefixCountryNum,
          phone: this.state.phone,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          displayName: this.state.displayName,
          gender : this.state.gender,
          birth: this.state.birth,
          homeNumber:this.state.homeNumber,
          email:this.state.email,
          idTokenGoogle:this.state.idTokenGoogle,
          credentialType: this.state.credentialType,
          lastNric:this.state.lastNric,
          streetName: this.state.streetName,
          unitNumber: this.state.unitNumber,
          postalCode: this.state.postalCode,
          cityCountry: this.state.cityCountry,
          dateNow:this.state.dateNow,
          masterPartnerUid:this.state.masterPartnerUid,
          partnerToken:this.state.partnerToken,
        }})
        console.log(this.state.username)
        console.log(this.state.firstname)
        console.log(this.state.lastname)
        
        console.log(this.state.credentialType)
        console.log(this.state.gender)
        console.log(this.state.birth)
        console.log(this.state.homeNumber)
        console.log(this.state.phone)
        return this.state
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Incorrect NRIC format, please input last 3 numbers of NRIC and final alphabet. For e.g If NRIC is S1234123A enter 123A.',
          icon: 'info',
          confirmButtonText: 'OK'
        })
      }
    }
  }

  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  handleCloseError =()=>{
    this.setState({showError:false})
  }
  render() {
    const {firstname,lastNric,lastname, displayName, birth,email,homeNumber, genderDropdown,Switch,gender,SwitchGenderF,SwitchGenderM,delivery, masterPartnerUid, } =this.state
    
    return (
      <>
        <StyledCG>
            <Header title={(masterPartnerUid && masterPartnerUid != "null") ? `Create Organisation Staff Account` : null} />
            <Row style={{marginTop:'5%'}}>
              <Col md={8}>
                <h2>My Details</h2>
                <p className="signUp" style={{color:'#758BA9', textAlign: 'left', marginTop: '1%', width: '62%', wordWrap:'break-word'}}>
                  Information provided will help us process your membership. 
                  All fields are mandatory (unless stated optional)
                </p>
              </Col>
              <Col md={4}>
                <img src={firststepcgcreateplwd} className="fluid" alt="First Step Cg Registration" />
              </Col>
            </Row>
           
                <Row style={{textAlign:'start',marginTop:'3%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>First Name (Given name(s) as per NRIC)</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your first name here...',name:"firstname",
                                                                    value:firstname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('firstname', e.target.value),}} />
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last Name (Surname as per NRIC)</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last name here...',name:"lastname",
                                                                    value:lastname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastname', e.target.value),}} />
                   
                    </Col>
                </Row>
                {/* <Row>
                  <Col style={{margin:'auto'}}>
                    <label>Display Name</label>
                  </Col>
                  <Col md={6}>
                  <Input 
                    containerStyle="InputStyle" 
                    inputProps={{ 
                      placeholder:'Type your display name here...',
                      name:"displayName",
                      value:displayName,
                      onChange: (e) => this.onChangeText('displayName', e.target.value),
                    }} />
                  </Col>
                </Row> */}
                <Row> 
                    <Col  style={{margin:'auto'}}>
                        <label>Date of Birth</label>
                    </Col>
                    <Col md={6}>
                    <Row style={{margin:'auto', border: '1px solid #B1C2D8',boxSizing: 'border-box',borderRadius: '15px'}}>
                    <DatePicker showMonthDropdown    showYearDropdown   customInput={<CustomInput />} filterDate = {(date) => {return moment() > date;}} placeholderText="Please select a date"  dropdownMode= 'select' dateFormat="dd-MM-yyyy" selected={this.state.dateNow ? new Date(this.state.dateNow) : null} 
                                 onChange={date => { this.setState({birth:date.toISOString().slice(0,10)}) 
                                  this.setState({dateNow:date.toISOString().slice(0,10)}) 
                                  console.log(date.toISOString().slice(0,10))}} />
 
                         
                  </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: '1%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Gender </label>
                    </Col>
                    <Col md={6}>
                    <Row>
                    
                    <Col md={6} >
                    <button style={{marginRight:'20px'}} className="type" onClick={this.changeGenderM}><Row style={{alignItems:'center'}}><img src={SwitchGenderM} style={{marginRight:'15px'}} /> Male</Row></button>
                    </Col>
                    <Col md={6} >
                    <button className="type"  onClick={this.changeGenderF}><Row style={{alignItems:'center'}}><img src={SwitchGenderF} style={{marginRight:'15px'}}/> Female</Row></button>
                    </Col>
                    </Row>
                    </Col>
                </Row>
                {/* <Row style={{marginTop:'2%'}}> 
                    <Col  style={{margin:'auto'}}>
                        <label>Date of Birth</label>
                    </Col>
                    <Col md={6}>
                    <Row style={{margin:'auto', border: '1px solid #B1C2D8',boxSizing: 'border-box',borderRadius: '15px'}}>
                    <DatePicker showMonthDropdown    showYearDropdown   customInput={<CustomInput />} filterDate = {(date) => {return moment() > date;}} placeholderText="Please select a date"  dropdownMode= 'select' dateFormat="dd-MM-yyyy" selected={this.state.dateNow ? new Date(this.state.dateNow) : null} 
                                 onChange={date => { this.setState({birth:date.toISOString().slice(0,10)}) 
                                  this.setState({dateNow:date.toISOString().slice(0,10)}) 
                                  console.log(date.toISOString().slice(0,10))}} />
 
                         
                  </Row>
                    </Col>
                </Row> */}
                
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last 4 characters of NRIC</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last NRIC here...',name:"lastNric",
                                                                    value:lastNric,maxLength:4,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastNric', e.target.value),}} />
                   
                    </Col>
                </Row>
                
                <Row >
                    <Col  style={{margin:'auto'}}>
                        <label>Email Address</label>
                    </Col>
                    <Col md={6}>
                    <Input    inputProps={{placeholder:'Type your email here...',name:"email",
                                                                    value:email,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('email', e.target.value),}} />
                   
                    </Col>
                </Row>
                <hr/>
                <Row style={{marginTop: '2%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Home Number (optional)</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your home number here...',name:"homeNumber",
                                                                    value:homeNumber,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('homeNumber', e.target.value),}} />
                   
                    </Col>
                </Row>
           
            <Row style={{marginTop:'5%',marginBottom:'5%'}}>
            <Col md={8}></Col>
              <Col md={4} style={{display:'flex',justifyContent:'flex-end'}}>
                <Button  buttonStyle={{height:'50px',width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} containerStyle={StyledButton} textStyle={StyledButton} text="Next" onClick={this.submitForm}/>
              </Col>
                <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
            </Row>
         
                
            </StyledCG>

      </>
    )
  }
}


export default cgDetail