import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton,InputStyle} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'

import Button from '../../components/button'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'
import TypeActive from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import Polygon from '../../assets/images/Polygon.png'
import firstStepImg from '../..//assets/images/first-step-cg-create-plwd.png'
import { checkDuplicateLastNricLastNameDateOfBirth } from '../../api/UserAPI';

import styled from "styled-components";

import { Images, Colors, Fonts } from '../../themes';
import moment from 'moment'
import { Link } from 'react-router-dom';
import Select from 'react-select'

import ProccessData from '../../lib/ProccessData';
import Swal from 'sweetalert2'

import PopUpBoxHandler from '../../components/modalhandler';

import DatePicker from "react-datepicker";


const relationshipStatusData =
[
      {
        id: 0,
        name: `Uncle / Aunt`,
        value: `Uncle / Aunt`,
        label: `Uncle / Aunt`
      },
      {
        id: 1,
        name:  'Spouse',
        value: `Spouse`,
        label:  `Spouse`
      },
      {
        id: 2,
        name:  'Parent',
        value: `Parent`,
        label:  `Parent`
      },
      {
        id: 3,
        name:  'Grandparent',
        value: `Grandparent`,
        label: `Grandparent`
      },
      {
        id: 4,
        name:  'Child',
        value: `Child`,
        label:  `Child`
      },
      {
        id: 5,
        name: 'Grandchild',
        value: `Grandchild`,
        label:  `Grandchild`
      },
      {
        id: 6,
        name: `Nephew / Niece`,
        value: `Nephew / Niece`,
        label:  `Nephew / Niece`
      },
      {
        id: 7,
        name: `Organisation Staff`,
        value: `Organisation Staff`,
        label:  `Organisation Staff`
      },
      {
        id: 8,
        name:  'Volunteer',
        value: `Volunteer`,
        label:  `Volunteer`
      },
      {
        id: 9,
        name: 'Others, please specify',
        value: 'Others, please specify',
        label: 'Others, please specify',
      },
    ]

const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`
const languageData = [
    {
      id: 0,
      value: 'English',
      label: 'English'
    },
    {
      id: 1,
      value: 'Mandarin',
      label: 'Mandarin'
    },
    {
      id: 2,
      value: 'Malay',
      label: 'Malay'
    },
    {
      id: 3,
      value: 'Tamil',
      label: 'Tamil'
    },
    {
      id: 4,
      value: 'Hokkien',
      label: 'Hokkien',
    },
    {
      id: 5,
      value: 'Cantonese',
      label: 'Cantonese'
    },
    {
      id: 6,
      value: 'Others, please specify',
      label: 'Others, please specify',
    },
  ];
  const stageofDementiaData =[
    {
      id: 0,
      name : 'MILD',
      value: 'MILD',
      label: 'MILD'
    },
    {
      id: 1,
      name : 'MODERATE',
      value: 'MODERATE',
      label: 'MODERATE'
    },
    {
      id: 2,
      name : 'SEVERE',
      value: 'SEVERE',
      label: 'SEVERE'
    },
  ]
  const typeOfDementiaData = [
    {
      id: 0,
      value: `Alzheimer's Disease`,
      label: `Alzheimer's Disease`
    },
    {
      id: 1,
      value: `Vascular Dementia`,
      label: `Vascular Dementia`
    },
    {
      id: 2,
      value: `Frontotemporal Dementia (FTD)`,
      label:  `Frontotemporal Dementia (FTD)`
    },
    {
      id: 3,
      value: `Lewy Body Dementia (LBD)`,
      label: `Lewy Body Dementia (LBD)`
    },
    {
      id: 4,
      value: `Mild Cognitive Impairment (MCI)`,
      label: `Mild Cognitive Impairment (MCI)`
    },
    {
      id: 5,
      value: 'Others, please specify',
      label: 'Others, please specify',
    },
  ];
  
const InputReadOnlyStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin: 10px 0 25px 0; 
.input-field { cursor: pointer; }
`;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv onClick={props.onClick}>
    <Row>
   
      <label ref={ref}>
      <Col >
        {props.value || props.placeholder}
        </Col> 
      </label>
     
      <Col style={{display:'flex',alignSelf:'center',justifyContent:'flex-end',marginRight:'10px'}}>
      <img src={Polygon} onClick={props.onClick} />
</Col>
      </Row>
    </CustomDatePickDiv>
  );
});

const CustomDatePickDiv = styled.div`
  background-color: white;
 
  border-radius: 10px;
  label{padding-left:5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}}
  }
`;
const InputTypeStyle = `
background-color: ${Colors.color3}; 
border: 1px solid ${Colors.color20};
margin-top: 10px; 
.input-field { cursor: pointer; }
`;
export class pwdDetail extends Component {
  constructor(props) {
    super(props)
    
    this.state = {  
      username:'',
      password: '',
      streetName:this.props.location.state.streetName,
      unitNumber:this.props.location.state.unitNumber,
      postalCode:this.props.location.state.postalCode,
      cityCountry:this.props.location.state.cityCountry,
      uid:this.props.location.state.uid,
      token:this.props.location.state.token, 
      relationship: '',
      relationshipStatus: '',
      phoneNumber: '',
      homeNumber: '',
      email: '',
      firstname: '',
      lastname:'',
      displayName: '',
      gender:'',
      NIRC:'',
      birth: '',
      language:'',
      dementiaType: '',
      dementiaStage:'',
      dateNow:'',
      defaultStage:'',
      defaultLanguage:'',
      defaultType:'',
      showError:false,
      errorMessage:'',
      selectLanguage: languageData,
      usedService:false,
      Switch: SwitchNo,
      SwitchGenderM:TypeInactive,
      SwitchGenderF:TypeInactive,
      languageDropdown: false,
      language: {
          id: 1,
          name: 'English',
      },
      typeOfDementiaDropdown: false,
      typeOfDementia: {
        id: 1,
        name: `Alzheimer's Disease`,
      },
      typeOfDementiaFiltered: true,
      languageFiltered: true,
      show: false,
      delivery: '',
      dateSend: moment().format('YYYY-MM-DD'),
      fullData: {
        ...this.props.location.state.fullData
      },
    }
    this.handleChangeLanguange = this.handleChangeLanguange.bind(this)
  } 
  //     componentDidMount(){
  //       if(window.sessionStorage.getItem("username") != ''){
  //         this.setState({username: window.sessionStorage.getItem("username")})
  //       }
  //       if(window.sessionStorage.getItem("password") != ''){
  //         this.setState({password: window.sessionStorage.getItem("password")})
  //       }
  //      if(window.sessionStorage.getItem("gender") == 'male'){
  //       this.setState({SwitchGenderM: TypeActive,gender:window.sessionStorage.getItem("gender")})
  //      }
  //       if(window.sessionStorage.getItem("gender") == 'female'){
  //       this.setState({SwitchGenderF: TypeActive,gender:window.sessionStorage.getItem("gender")})
  //      }
  //      if(window.sessionStorage.getItem("firstname") != ''){
  //        this.setState({firstname: window.sessionStorage.getItem("firstname")})
  //      }
  //     if(window.sessionStorage.getItem("lastname") != ''){
  //       this.setState({lastname: window.sessionStorage.getItem("lastname")})
  //     }
  //     if(window.sessionStorage.getItem("displayName") != ''){
  //       this.setState({displayName: window.sessionStorage.getItem("displayName")})
  //     }
      
  //  if(  this.props.location.state.NIRC){
  //   this.setState({NIRC:  this.props.location.state.NIRC})
  // }
      
  //      if(window.sessionStorage.getItem("birth") != ''){
  //       this.setState({dateNow: window.sessionStorage.getItem("birth")})
  //     }
      
  // if(window.sessionStorage.getItem("defaultType") != ''){
  //   this.setState({defaultType: window.sessionStorage.getItem("defaultType")})
  // }
  
  // if(languageData[window.sessionStorage.getItem("defaultType")]!= null){
  //   this.setState({dementiaType:typeOfDementiaData[window.sessionStorage.getItem("defaultType")].value})
  // }
  // if(window.sessionStorage.getItem("defaultLanguage") != ''){
  //   this.setState({defaultLanguage: window.sessionStorage.getItem("defaultLanguage")})
  // }
  
  // if(languageData[window.sessionStorage.getItem("defaultLanguage")]!= null){
  //   this.setState({language:languageData[window.sessionStorage.getItem("defaultLanguage")].value})
  // }
  //  if(window.sessionStorage.getItem("defaultStage") != ''){
  //   this.setState({defaultStage: window.sessionStorage.getItem("defaultStage")})
  // }
  // if(stageofDementiaData[window.sessionStorage.getItem("defaultStage")]!= null){
  //   this.setState({dementiaStage: stageofDementiaData[window.sessionStorage.getItem("defaultStage")].value})
  // }
  // if(window.sessionStorage.getItem("dateNow")!= ''){
  //   this.setState({dateNow:window.sessionStorage.getItem("dateNow"),birth:window.sessionStorage.getItem("dateNow")})
  // }
  //    }


  componentDidMount() {
    if(this.props.history.location.state.editingData) {
      const theState = {
        username: this.props.history.location.state.editingData.username,
        password: this.props.history.location.state.editingData.password,
        relationship: this.props.history.location.state.editingData.relationship,
        relationshipStatus: this.props.history.location.state.editingData.relationshipStatus,
        firstname: this.props.history.location.state.editingData.firstname,
        lastname: this.props.history.location.state.editingData.lastname,
        birth: this.props.history.location.state.editingData.birth,
        SwitchGenderM: this.props.history.location.state.editingData.gender === 'male' ? TypeActive : TypeInactive,
        SwitchGenderF: this.props.history.location.state.editingData.gender !== 'male' ? TypeActive : TypeInactive,
        gender: this.props.history.location.state.editingData.gender,
        NIRC: this.props.history.location.state.editingData.NIRC,
        language: this.props.history.location.state.editingData.language,
        dementiaType: this.props.history.location.state.editingData.dementiaType,
        dementiaStage: this.props.history.location.state.editingData.dementiaStage,
        email: this.props.history.location.state.editingData.fullData.routeState.fullData.credential.accountData.email,
        phoneNumber: this.props.history.location.state.editingData.fullData.routeState.fullData.credential.accountData.phone,
        homeNumber: this.props.history.location.state.editingData.fullData.routeState.fullData.credential.accountData.homeNumber,
      }
      
      this.setState(theState)
    } else {
      this.setState({
        relationship: '',
        relationshipStatus: '',
        firstname: '',
        lastname: '',
        birth: '',
        gender: '',
        NIRC: '',
        language: '',
        dementiaType: '',
        dementiaStage: '',
        email: '',
        phoneNumber: '',
        homeNumber: ''
      })
      if(window.sessionStorage.getItem("username") != ''){
                this.setState({username: window.sessionStorage.getItem("username")})
              }
              if(window.sessionStorage.getItem("password") != ''){
                this.setState({password: window.sessionStorage.getItem("password")})
              }
             if(window.sessionStorage.getItem("gender") == 'male'){
              this.setState({SwitchGenderM: TypeActive,gender:window.sessionStorage.getItem("gender")})
             }
              if(window.sessionStorage.getItem("gender") == 'female'){
              this.setState({SwitchGenderF: TypeActive,gender:window.sessionStorage.getItem("gender")})
             }
             if(window.sessionStorage.getItem("firstname") != ''){
               this.setState({firstname: window.sessionStorage.getItem("firstname")})
             }
            if(window.sessionStorage.getItem("lastname") != ''){
              this.setState({lastname: window.sessionStorage.getItem("lastname")})
            }
            if(window.sessionStorage.getItem("displayName") != ''){
              this.setState({displayName: window.sessionStorage.getItem("displayName")})
            }
            
         if(window.sessionStorage.getItem("NIRC") != ''){
          this.setState({NIRC: window.sessionStorage.getItem("NIRC")})
        }
            
             if(window.sessionStorage.getItem("birth") != ''){
              this.setState({dateNow: window.sessionStorage.getItem("birth")})
            }
            
        if(window.sessionStorage.getItem("defaultType") != ''){
          this.setState({defaultType: window.sessionStorage.getItem("defaultType")})
        }
        
        if(languageData[window.sessionStorage.getItem("defaultType")]!= null){
          this.setState({dementiaType:typeOfDementiaData[window.sessionStorage.getItem("defaultType")].value})
        }
        if(window.sessionStorage.getItem("defaultLanguage") != ''){
          this.setState({defaultLanguage: window.sessionStorage.getItem("defaultLanguage")})
        }
        
        if(languageData[window.sessionStorage.getItem("defaultLanguage")]!= null){
          this.setState({language:languageData[window.sessionStorage.getItem("defaultLanguage")].value})
        }
         if(window.sessionStorage.getItem("defaultStage") != ''){
          this.setState({defaultStage: window.sessionStorage.getItem("defaultStage")})
        }
        if(stageofDementiaData[window.sessionStorage.getItem("defaultStage")]!= null){
          this.setState({dementiaStage: stageofDementiaData[window.sessionStorage.getItem("defaultStage")].value})
        }
        if(window.sessionStorage.getItem("dateNow")!= ''){
          this.setState({dateNow:window.sessionStorage.getItem("dateNow"),birth:window.sessionStorage.getItem("dateNow")})
        }
        if(window.sessionStorage.getItem("email") != ''){
          this.setState({email: window.sessionStorage.getItem("email")})
        }
        if(window.sessionStorage.getItem("phoneNumber") != ''){
          this.setState({phoneNumber: window.sessionStorage.getItem("phoneNumber")})
        }
        if(window.sessionStorage.getItem("homeNumber") != ''){
          this.setState({homeNumber: window.sessionStorage.getItem("homeNumber")})
        }
        if(window.sessionStorage.getItem("relationship") != ''){
          this.setState({relationship: window.sessionStorage.getItem("relationship")})
        }
        if(window.sessionStorage.getItem("relationshipStatus") != ''){
          this.setState({relationshipStatus: window.sessionStorage.getItem("relationshipStatus")})
        }
    }
  }

  alertError = (text, exit = true) => {
    Swal.fire({
      title: 'Error!',
      text,
      icon: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    })
  }

  checkDuplicateLastNricLastNameDateOfBirth = async () => {
    if((this.state.firstname =='') || (this.state.lastname =='') || (this.state.gender =='') || (this.state.NIRC =='') || 
    (this.state.birth =='')   || (this.state.language =='') || (this.state.dementiaType =='') || (this.state.dementiaStage=='') ){
      Swal.fire({
        title: 'Warning!',
        text: 'please fill the required data and choose date',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      try {
        let resultGet = {}
        resultGet.payload = await checkDuplicateLastNricLastNameDateOfBirth({
          lastname: this.state.lastname,
          birth: this.state.birth,
          lastNric: this.state.NIRC
        })
        resultGet.payload.type = 'SUCCESS'
        ProccessData(resultGet, 'get address')
        .then(res => {
          console.log(res)
          if(res && res.data && res.data.success) {
            this.submitForm()
          } else {
            this.alertError(res.data.message)
          }
        })
        .catch(err => {
          this.alertError(err)
        })
      } catch (e) {
        this.alertError(e)
      }
    }
  }

  mapStatesToSessionStorage = () => {
    for(let state in this.state) {
      window.sessionStorage.setItem(state, this.state[state])
    }
  }

   handleShow = (e)=> {
    
     this.setState({show: true})
 } 
  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state })
  }
 
  changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes,usedService:true})
   }else{ 
       this.setState({Switch: SwitchNo,usedService:false})
   }
  }
  changeGenderM=()=>{
    if(this.state.SwitchGenderM == TypeInactive ){
        this.setState({SwitchGenderM: TypeActive,gender:'male', SwitchGenderF:TypeInactive})
        
     }else{ 
         this.setState({SwitchGender: TypeInactive,SwitchGenderM: TypeActive,})
     }
  }
  changeGenderF=()=>{
    if(this.state.SwitchGenderF == TypeInactive ){
        this.setState({SwitchGenderF: TypeActive,gender:'female', SwitchGenderM:TypeInactive})
        
     }else{ 
         this.setState({SwitchGenderM: TypeInactive,SwitchGenderF: TypeActive,})
     }
  }
  handleChangeCustomLanguange = (language) => {
 Swal.fire({
  //  title: 'Please fill username and phone number',
   html:
     `<label style="color: #424242;">Please Specify </label><input ${language === languageData[languageData.length - 1].value && `value="${language}"`} id="swal-input-language" class="swal2-input">`,
     showCancelButton: true,
     onOpen: () => {
      const input = document.getElementById('swal-input-language')

      if(input.value === '') {
        Swal.getConfirmButton().setAttribute('disabled', '')
      }

      input.addEventListener('keyup', (e) => {
        if(e.target.value.trim() === '') {
          Swal.getConfirmButton().setAttribute('disabled', '')
        } else {
          Swal.getConfirmButton().removeAttribute('disabled')
        }
      })
    },
   preConfirm: () => {
     const value = document.getElementById('swal-input-language').value;
     languageData.splice(languageData.length - 1, 1, { id: 6, value: value.trim(), label: value.trim() })
      this.setState({language : value.trim()})
   }
 })
 }
  handleChangeLanguange =(e)=> {
    if(e.id !== 6) {
      this.setState({language:e.value})
    } else {
      this.handleChangeCustomLanguange(this.state.language)
    }
        
  this.setState({defaultLanguage:e.id})
  }; 
  handleChangeCustomTypeDementia = (dementiaType) => {
    Swal.fire({
     //  title: 'Please fill username and phone number',
      html:
        `<label style="color: #424242;">Please Specify </label><input ${dementiaType === typeOfDementiaData[typeOfDementiaData.length - 1].value && `value="${dementiaType}"`} id="swal-input-type-dementia" class="swal2-input">`,
        showCancelButton: true,
        onOpen: () => {
          const input = document.getElementById('swal-input-type-dementia')

          if(input.value === '') {
            Swal.getConfirmButton().setAttribute('disabled', '')
          }

          input.addEventListener('keyup', (e) => {
            if(e.target.value.trim() === '') {
              Swal.getConfirmButton().setAttribute('disabled', '')
            } else {
              Swal.getConfirmButton().removeAttribute('disabled')
            }
          })
        },
      preConfirm: () => {
        const value = document.getElementById('swal-input-type-dementia').value;
        typeOfDementiaData.splice(typeOfDementiaData.length - 1, 1, { id: 5, value: value.trim(), label: value.trim() })
         this.setState({dementiaType : value.trim()})
      }
    })
    }
  handleChangeTypeDementia =(e)=> {
    if(e.id !== 5) {
      this.setState({dementiaType:e.value})
    } else {
      this.handleChangeCustomTypeDementia(this.state.dementiaType)
    }
   
    this.setState({defaultType:e.id})
  }; 
  handleChangeStageDementia =(e)=> {
    this.setState({dementiaStage:e.value})
    console.log('stageDementia', this.state.dementiaStage )
    
  this.setState({defaultStage:e.id})
  }; 
 
  submitForm = ()=>{ 
    if(this.state.usedService){
        this.mapStatesToSessionStorage()

        this.props.history.push({pathname:'/CareGiverCreateNewPWDResidingAddressHaveUsedService',
        state:{
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          // displayName: this.state.displayName,
          gender : this.state.gender,
          NIRC: this.state.NIRC,
          birth: this.state.birth,
          language: this.state.language,
          dementiaType: this.state.dementiaType,
          dementiaStage: this.state.dementiaStage,
          phoneNumber: this.state.phoneNumber,
          email: this.state.email,
          homeNumber: this.state.homeNumber,
          relationship: this.state.relationship,
          relationshipStatusData: this.state.relationshipStatus,
    
          streetName:this.state.streetName,
          unitNumber:this.state.unitNumber,
          postalCode:this.state.postalCode,
          cityCountry:this.state.cityCountry,
          uid:this.state.uid,
          token:this.state.token,
          fullData: {
            ...this.state.fullData
          }
    
    
        }})
        console.log(this.state.username)
        console.log(this.state.firstname)
        console.log(this.state.lastname)
        
        console.log(this.state.gender)
        console.log(this.state.NIRC)
        console.log(this.state.birth)
        console.log(this.state.language)
        console.log(this.state.streetName)
        console.log(this.state.dementiaStage)
        console.log(this.state.unitNumber)
        
        console.log(this.state.streetName)
        console.log(this.state.postalCode)
        console.log(this.state.cityCountry)
        return this.state
      }else{
        var letters = /^[A-Za-z]+$/;
        let result = {}
      
        if (this.state.NIRC.length < 4) {
         
          Swal.fire({
            title: 'Warning!',
            text: 'Last NRIC must be 4 character.',
            icon: 'info',
            confirmButtonText: 'OK'
          })
        }
      
        else if ( 
          ( (!isNaN(this.state.NIRC[0]) || this.state.NIRC[0] >= 1 || this.state.NIRC[0] <= 10) ) && 
          ( (!isNaN(this.state.NIRC[1]) || this.state.NIRC[1] >= 1 || this.state.NIRC[1] <= 10) ) && 
          ( (!isNaN(this.state.NIRC[2]) || this.state.NIRC[2] >= 1 || this.state.NIRC[2] <= 10) ) && 
          this.state.NIRC[3].match(letters)
        ) {
        
        window.sessionStorage.setItem("firstname", this.state.firstname);
        window.sessionStorage.setItem("lastname", this.state.lastname);
        window.sessionStorage.setItem("displayName", this.state.displayName);
        window.sessionStorage.setItem("gender", this.state.gender);
        window.sessionStorage.setItem("birth",this.state.birth);
        window.sessionStorage.setItem("NIRC",this.state.NIRC),
        window.sessionStorage.setItem("defaultType",this.state.defaultType)
        window.sessionStorage.setItem("defaultStage",this.state.defaultStage)
        window.sessionStorage.setItem("defaultLanguage",this.state.defaultLanguage)
        window.sessionStorage.setItem("dateNow",this.state.dateNow)
        this.mapStatesToSessionStorage()
    this.props.history.push({pathname:'/CareGiverCreateNewPWDResidingAddress',
    state:{
      username: this.state.username,
      password: this.state.password,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      // displayName: this.state.displayName,
      phoneNumber: this.state.phoneNumber,
          email: this.state.email,
          homeNumber: this.state.homeNumber,
          relationship: this.state.relationship,
          relationshipStatusData: this.state.relationshipStatus,
      gender : this.state.gender,
      NIRC: this.state.NIRC,
      birth: this.state.birth,
      language: this.state.language,
      dementiaType: this.state.dementiaType,
      dementiaStage: this.state.dementiaStage,

      streetName:this.state.streetName,
      unitNumber:this.state.unitNumber,
      postalCode:this.state.postalCode,
      cityCountry:this.state.cityCountry,
      uid:this.state.uid,
      token:this.state.token,
      dateNow:this.state.dateNow,
      fullData: {
        ...this.state.fullData
      },
      editingData: { 
        ...this.props.history.location.state,
        ...this.props.history.location.state.fullData.routeState,
        editing: true,
        email: this.state.email,
        homeNumber: this.state.homeNumber,
        phoneNumber: this.state.phoneNumber
      },
      isEdit: this.props.history.location.state.isEdit ? true : false,
      editingData2: this.props.history.location.state.isEdit ? { ...this.props.history.location.state, } : undefined
    }})
    console.log(this.state.username)
    console.log(this.state.firstname)
    console.log(this.state.lastname)
    
    console.log(this.state.gender)
    console.log(this.state.NIRC)
    console.log(this.state.birth)
    console.log(this.state.language)
    console.log(this.state.dementiaType)
    console.log(this.state.dementiaStage)
    console.log(this.state.dateNow)
    return this.state}
      else {
  
    Swal.fire({
      title: 'Warning!',
      text: 'Incorrect NRIC format, please input last 3 numbers of NRIC and final alphabet. For e.g If NRIC is S1234123A enter 123A.',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  }
  }
  }
  
  handleCloseError =()=>{
    this.setState({showError:false})
  }
  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };
  handleChangeRelationship =(e)=> {
    if(e.id !== 9) {
      this.setState({relationship:e.value})
      this.setState({relationshipStatus:e.id})
    } else {
      this.handleChangeCustomRelationship(this.state.relationship, this.state.relationshipStatus)
    }
    // this.setState({relationship:e.value})
    // this.setState({relationshipStatus:e.id})
  };
  handleChangeCustomRelationship = (relationship, relationshipId) => {
    Swal.fire({
     //  title: 'Please fill username and phone number',
      html:
        `<label style="color: #424242;">Please Specify </label><input ${relationship === relationshipStatusData[relationshipStatusData.length - 1].value && `value="${relationship}"`} id="swal-input-type-relationship" class="swal2-input">`,
        showCancelButton: true,
        onOpen: () => {
          const input = document.getElementById('swal-input-type-relationship')

          if(input.value === '') {
            Swal.getConfirmButton().setAttribute('disabled', '')
          }

          input.addEventListener('keyup', (e) => {
            if(e.target.value.trim() === '') {
              Swal.getConfirmButton().setAttribute('disabled', '')
            } else {
              Swal.getConfirmButton().removeAttribute('disabled')
            }
          })
        },
      preConfirm: () => {
        const value = document.getElementById('swal-input-type-relationship').value;
        relationshipStatusData.splice(relationshipStatusData.length - 1, 1, { id: 9, value: value.trim(), label: value.trim() })
         this.setState({relationship : value.trim()})
        this.setState({relationshipStatus:relationshipId})
      }
    })
    }
  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        ic
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? 'pointer' : 'not-allowed'}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Polygon}
        {...props}
        dropdownWidth={'100%'}
      />
    );
  }
  render() {
    const {NIRC,firstname,lastname, displayName, email,birth,dateSend,delivery,language,languageFiltered,enderData, genderDropdown,Switch,gender,SwitchGenderF,SwitchGenderM, phoneNumber, homeNumber } =this.state
    
    
    return (
      <>
        <StyledCG>
            <Header/>
            <Row style={{marginTop:'3%'}}>
              <Col md={4}>
                <p style={{textAlign: 'left'}}>Information provided will help us process your membership. All fields are mandatory (unless stated optional)</p>
              </Col>
            </Row>
            <Row style={{marginTop:'1%'}}>
              <Col md={5}>
                <h2>Please input details of person you are caring for:</h2>
              </Col>
              <Col md={{ span: 4, offset: 3 }}>
                <img src={firstStepImg} className="fluid" alt="First Step Cg Create New PLWD" />
              </Col>
            </Row>
            
            <hr/>
                {/* <Row> <Col  style={{margin:'auto',marginTop:'6%'}}><label>Name displayed  for identification purposes</label></Col></Row>
                 */}
                 <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                    
                        <label>The Person Living with Dementia is my</label>
                    </Col>
                    <Col md={6}>
                      <Select  
                        onChange={(e) =>  this.handleChangeRelationship(e)} 
                        value={relationshipStatusData.filter(item => String(item.value) === String(this.state.relationship))}
                        options={relationshipStatusData} 
                      />
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col  style={{margin:'auto'}}>
                    
                        <label>First Name ( Given name(s) as per NRIC )</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle"  inputProps={{placeholder:'Type your first name here...',name:"firstname",
                                                                    value:firstname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('firstname', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last Name  ( Surename as per NRIC )</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last name here...',name:"lastname",
                                                                    value:lastname,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('lastname', e.target.value),}}/>
                   
                    </Col>
                </Row>
                {/* <Row>
                  <Col  style={{margin:'auto'}}>
                    <label>Display Name</label>
                  </Col>
                  <Col md={6}>
                  <Input 
                    containerStyle="InputStyle" 
                    inputProps={{
                      placeholder:'Type your display name here...',
                      name:"displayName",
                      value:displayName,
                      onChange: (e) => this.onChangeText('displayName', e.target.value),
                      }}
                    />
                  </Col>
                </Row> */}
                <Row style={{marginBottom: '2%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Date of Birth</label>
                    </Col>
                    <Col md={6}>
                    <Row style={{margin:'auto', border: '1px solid #B1C2D8',boxSizing: 'border-box',borderRadius: '15px'}}>
                    <DatePicker  
                      showMonthDropdown    
                      showYearDropdown  
                      value={this.state.birth}
                      customInput={<CustomInput />}  
                      filterDate = {(date) => {
                        return moment() > date;
                      }} 
                      dropdownMode= 'select' 
                      dateFormat="dd-MM-yyyy" 
                      selected= {
                        this.state.dateNow ? 
                        new Date(this.state.dateNow) : 
                        null
                      }  
                      onChange={date => { 
                        this.setState({
                          dateNow:date.toISOString().slice(0,10),
                          birth:date.toISOString().slice(0,10),
                        })                                                                                          
                        this.setState({
                          dateNow:date.toISOString().slice(0,10)})
                        }} 
                      placeholderText="Please select a date" 
                    />
                  </Row>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Gender </label>
                    </Col>
                    <Col md={6}>
                    <Row>
                    
                    <Col md={6} >
                    <button style={{marginRight:'20px'}} className="type" onClick={this.changeGenderM}><Row style={{alignItems:'center'}}><img src={SwitchGenderM} style={{marginRight:'15px'}} /> Male</Row></button>
                    </Col>
                    <Col md={6} >
                    <button className="type"  onClick={this.changeGenderF}><Row style={{alignItems:'center'}}><img src={SwitchGenderF} style={{marginRight:'15px'}}/> Female</Row></button>
                    </Col>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Last 4 characters of NRIC</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your last NRIC here...',name:"NIRC",
                                                                    value:NIRC,maxLength:4,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('NIRC', e.target.value),}} />
                   
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Preferred Language of Communication</label>
                    </Col>
                    <Col md={6}>
                    <Select 
                      onChange={(e) => this.handleChangeLanguange(e)} 
                      value={languageData.filter(item => String(item.value) === String(this.state.language))}
                      options={languageData} 
                    />
                      
                        </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Type of Dementia</label>
                    </Col> 
                    <Col md={6}>
                    <Select  
                      onChange={(e) =>  this.handleChangeTypeDementia(e)} 
                      value={typeOfDementiaData.filter(item => String(item.value) === this.state.dementiaType)}
                      options={typeOfDementiaData} 
                    />
                       
                        </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Stage of Dementia</label>
                    </Col>
                    <Col md={6}>
                    <Select  
                      onChange={(e) =>  this.handleChangeStageDementia(e)} 
                      value={stageofDementiaData.filter(item => String(item.value) === this.state.dementiaStage)}
                      options={stageofDementiaData} 
                    />
                   
                    </Col>
                </Row>
                <hr/>

                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Email Address (Optional)</label>
                        <p style={{ textAlign: 'left', fontSize: 15 }}>
                         Email is our preferred communication as it allows us to assist you with account issues quickly. If you have one, please include it.
                        </p>
                    </Col>
                    <Col md={6}>
                      <Input 
                        containerStyle="InputStyle" 
                        inputProps={{placeholder:'Type your email here...',
                        name:"email",
                        value:(email && email !== 'null') ? email : '',
                        onChange: (e) => this.onChangeText('email', e.target.value),}}
                      />  
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Mobile Number (Optional)</label>
                    </Col> 
                    <Col md={6}>  
                      <Input 
                        containerStyle="InputStyle" 
                        inputProps={{placeholder:'Type your mobile number here...',
                        name:"phoneNumber",
                        value:(phoneNumber && phoneNumber !== 'null') ? phoneNumber : '',
                        onChange: (e) => this.onChangeText('phoneNumber', e.target.value),}}
                      /> 
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Home Number (Optional)</label>
                    </Col>
                    <Col md={6}>
                      <Input 
                        containerStyle="InputStyle" 
                        inputProps={{placeholder:'Type your home number here...',
                        name:"homeNumber",
                        value:(homeNumber && homeNumber !== 'null') ? homeNumber : '',
                        onChange: (e) => this.onChangeText('homeNumber', e.target.value),}}
                      /> 
                    </Col>
                </Row>
                
                <Row style={{marginTop:'5%',marginBottom:'5%'}}>
                <Col md={8}></Col>
                 <Col md={4} style={{display:'flex',justifyContent:'flex-end'}}>
                <Button 
                  containerStyle={StyledButton} 
                  textStyle={StyledButton} 
                  buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} 
                  onClick={() => {
                    this.checkDuplicateLastNricLastNameDateOfBirth()
                  }}  
                  text="Next" 
                />
                <PopUpBoxHandler show={this.state.showError} text={this.state.errorMessage} handleClose={this.handleCloseError}/>
                </Col></Row>
              
                
            </StyledCG>

        
       
      </>
    )
  }
}


export default pwdDetail