import React from 'react';
import Logo from '../../assets/images/LogoCara.png'
import  './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Switch, Route, Link } from 'react-router-dom';


function Navigation(props) {
    return (
        <>
    <Navbar expand="lg" sticky="top" className="nav-container bg-white justify-content-between flex-row-reverse flex-lg-row" >
      <Navbar.Brand className='row-xl-7 logo-container' href={props.Home}>
        <img src={Logo}/>
      </Navbar.Brand>
      <div>
        <Navbar.Toggle className='navbar-toggler' data-toggle="collapse" data-target="#Nav" aria-controls="Nav"  aria-expanded="false" />
        <Navbar.Collapse id="Nav " >
          <Nav className="ml-auto menu  sidebar  " >
            <Navbar.Toggle className='navbar-toggler' data-toggle="collapse" data-target="#Nav" aria-controls="Nav"  aria-expanded="true" />
            {/* <Nav.Link href={props.Home}><h6 className="menu">Home</h6></Nav.Link> */}
            <Nav.Link  href={props.Why}><h6 className="menu">About</h6></Nav.Link>
            <Nav.Link href={props.How}><h6 className="menu">How it Works</h6></Nav.Link>
            <Nav.Link href={props.Social}><h6 className="menu">Benefits</h6></Nav.Link>
            <Nav.Link href={props.Download}><h6 className="menu">Download Now</h6></Nav.Link>
            <Nav.Link href={props.Faq}><h6 className="menu">FAQ</h6></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
  </Navbar>

        </>
    )
  }
  
  
  export default Navigation
  