import React, { Component } from 'react'
import Header from '../components/header'
import OptionType from '../components/optiontype'
import TabsIndicator from '../components/tabsIndicator';

import SigninPartner from './registrationPartner/SigninPartner';

import {StyledRegis} from "./styles/StyledRegist";


export class Registation extends Component {
  constructor(props) {
    super(props)
    // this.state.showing = 'OPTION' || 'SIGNIN'
    this.state = {
      showing: 'OPTION'
    }
  }
  render() {
    const { showing } = this.state
    return (
      <>
        <StyledRegis>
            <Header/>
            <OptionType />
            {/* <div style={{ marginTop: '30px' }}>
              <TabsIndicator
                setShowing={(showing) => this.setState({ showing })}
              />
            </div>
            {
              showing === 'SIGNIN' ?
                <SigninPartner />
              :
                <div className="option-container">
                  <OptionType />
                </div>
            } */}
        </StyledRegis>
      </>
    )
  }
}


export default Registation