import React, { Component } from 'react';
import Navigation from '../components/navigation';
import Section1 from '../components/content/Section1'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './LandingPage.css'
import  '../components/navigation/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {StyledTerms} from './styles/StyledTerms'
import { Link } from 'react-router-dom';

class PrivacyPolicy extends Component {
 
    render(){
        
    return(
        <div>
            <Navigation Home="/" Why="/" How="/" Social="/" Faq="/"/>
                <StyledTerms>
                  
                    <Row style={{borderBottom: '1px solid #758BA9'}}>
                        <Col xs={4}  style={{display:'flex',justifyContent:'center'}}>
                            <Link to='/Terms-Of-Use'>
                                <h4 >Terms of Use</h4>
                            </Link>
                        </Col>
                        <Col xs={3} style={{borderBottom: '3px #7F59A0 solid', display:'flex',justifyContent:'center'}}>
                            <h4 className='active'>Privacy Policy</h4>
                        </Col>
                        <Col xs={5}  style={{display:'flex',justifyContent:'center',width: '440px'}}>
                            <Link to='/Membership-Terms'>
                                <h4>Membership Terms and Condition</h4>
                            </Link>
                        </Col>
                    </Row>
                   
                        <h3>Privacy Policy</h3>
                        <p>Your privacy is important to us</p>
                        <h3>Data Protection Policy (“Policy”)</h3>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>1. Introduction</h5></Row>
                            <Row><p>1.1 At Dementia Singapore Ltd (“Dementia Singapore”), we understand the importance of your personal data. Dementia Singapore’s employees, representatives and agents are committed to protecting the confidentiality and privacy of your personal data.</p>
                            <p>1.2 This Policy helps you understand how we deal with (such as collecting, using, disclosing and/or processing) your personal data on the CARA mobile application and/or website (“platform”). Please feel free to contact our Data Protection Officer at dpo@dementia.org.sg if you have any queries.</p>
                            <p>1.3 Definitions and key terms</p>
                            <p style={{textIndent:'15px'}}>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
                            <ul >
                                <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Dementia Singapore, (20 Bendemeer Road #01-02, BS Bendemeer Centre, Singapore 339914) that is responsible for your information under this Privacy Policy.</li>
                                <li>Country: where CARA (“platform”) or the owners of the platform are based, in this case is Singapore</li>
                                <li>Client: refers to the company, organisation or person that signs up to use the Dementia Membership Programme Service to manage the relationships with your consumers or service users.</li>
                                <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit the platform and use the services.</li>
                                <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</li>
                                <li>Personnel: refers to those individuals who are employed by Dementia Singapore, the platform, or are under contract to perform a service on behalf of one of the parties.</li>
                                <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
                                <li>Service: refers to the service provided by the platform as described in the relative terms (if available) and on this platform.</li>
                                <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                <li>Website: The CARA site, which can be accessed via this URL: <a style={{color:'black'}} href="www.cara.sg"> www.cara.sg</a></li>
                            </ul>
                       </Row>

                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>2. Consent to Policy</h5></Row>
                            <Row><p>2.1 By using our platform, you hereby agree and expressly consent to the terms and conditions of this Policy, to us dealing with your personal data (such as collecting, using, disclosing and/or processing) in accordance with and as set out in this Policy.</p>

                                 <p>2.2 We wish to highlight that this Policy may be updated from time to time where necessary and you hereby agree to and consent to the prevailing terms of the updated Policy always subject to your rights under the Singapore Personal Data Protection Act (No.26 of 2012) (“Act”). Any changes to this Policy will be published on <a style={{color:'black'}} href="www.cara.sg">www.cara.sg. </a></p></Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>3. Personal Data</h5></Row>
                            <Row>
                                <p>3.1 “Personal Data” is defined under the Act (“PDPA”) to mean data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access.</p>
                                <p>3.2 In this Policy, “Personal Data” refers to personal data provided by you and collected by Dementia Singapore. Examples could include names, identification numbers, email addresses, home addresses, telephone numbers, medical records, lifestyle preferences, personal interests, work-related information, photographs and video images.</p>                                    
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>4. Collecting, Using, Disclosing and Processing Personal Data</h5></Row>
                            <Row><p>4.1 We may collect, use, disclose and process your personal data for various purposes as listed below:</p></Row>

                                <Row><p>(a) Administering any form that you submit via the platform including but not limited to registration, application forms and contest entry forms. We will do so in a manner to help you complete the usage of our services and to render our services more efficiently. This includes contacting you, collecting your financial information to process payment for our services or to process application of grants/subsidies and also promoting and announcing contest winners.</p></Row>

                                <Row><p>(b) Providing our services to you when you request for and/or use our services and/or enter into an agreement. This includes conducting assessments (different modes of interaction), interacting with you (examples include when you request to be contacted either in response to your queries or to be placed on a mailing list, when we need to contact you where necessary and keeping notes to fulfill our services to you).</p></Row>

                                <Row><p>(c) Improving our services to you. This includes collecting and storing history of our interactions with you (examples include types of services and promotions offered to and/or used by you and our internal operations), generating statistics and research results for internal usage and your location history which will enable us to enhance your customer experience by sending you relevant information from time to time.</p></Row>

                                <Row><p>(d) Complying with any law, regulation or governmental requirement as may be applicable to us from time to time. This includes any record-keeping and statutory reporting requirements.</p></Row>

                                <Row><p>(e) Processing and administering any employment application and related documents provided such as resumes and CVs. This includes contacting you and evaluating your suitability for the appointment in question.</p></Row>
                                <Row><p>(f) When you submit your personal data for any other reason.</p></Row>
                                <Row><p>4.2 Any purpose not listed in this Policy will be notified to you at the time of collection and your consent will be sought no later than at the time of collection.</p></Row>
                                <Row><p>4.2 Any purpose not listed in this Policy will be notified to you at the time of collection and your consent will be sought no later than at the time of collection.</p></Row>
                                <Row><p>4.3 In the event that you provide personal data of one of your seniors (whether your relationship to the senior is next-of-kin, family member, caregiver, representative or legal guardian), you undertake and warrant that you have gotten their consent to disclose their personal data to us and that the personal data provided is true and accurate.</p></Row>
                                <Row><p>4.4 We may also disclose your personal data to our agents, affiliates, related corporations, third parties including but not limited to third-party service providers acting on our behalf who would process your personal data for the above purposes and they will be required to treat your personal data in accordance with this Policy. The aforesaid parties help us to make our business more efficient and effective. Where we disclose personal data to third parties, we will take reasonable steps to protect the security of your personal data. In the event that any of the aforesaid parties are located outside Singapore, we will comply with the requirements under the PDPA in doing so and we will ensure the same standard of personal data protection in the relevant jurisdiction as required under the Act.</p></Row>
                                <Row><p>4.5 We may disclose your personal data without your consent strictly only as provided under and in accordance with the Act.</p></Row>
                                
                        
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>5. Contacting Us</h5></Row>
                            <Row><p>5.1 All queries, requests and feedback on this Policy and/or regarding personal data can be directed to our Personal Data Protection Officer at: dpo@dementia.org.sg and 20 Bendemeer Rd, #01-02 BS Bendemeer Centre, Singapore 339914. This includes any request from you to withdraw your consent to the use of any of your personal data, correcting your personal data or accessing your personal data.</p></Row>
                            <Row><p>5.2 In the event that you seek to withdraw your consent, correct or access your personal data, please note that we will carry out a verification process to ascertain your identity. We may also require more information for clarification before we can process your request. Upon you contacting us, we will use our best efforts to get back to you within a reasonable period. In the event that we are unable to perform your request substantively, we will communicate with you on the timeline that you can expect.</p></Row>
                            <Row><p>5.3 In the event that your personal data was provided to us by a third party, please contact the third party directly who will deal with your queries, requests and feedback on our behalf.</p></Row>
                            <Row><p>5.4 In the event that you withdraw your consent for the collection, use, disclosure and processing of your personal data, this could result in certain legal consequences. Depending on the extent of your withdrawal of consent, we may not be in a position to continue our existing legal relationship with you and we expressly reserve all our rights in this regard.</p></Row>
                            
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>6. Managing your Personal Data</h5></Row>
                            <Row>   <p>6.1 We take reasonable steps to ensure the accuracy and completeness of your personal data. Should you provide us inaccurate and/or incomplete personal data or fail to update us of any change in your personal data, we are not responsible for relying on any of the aforesaid personal data.</p>
                                    <p>6.2 We also retain records of your personal data only for a reasonable period of time for the above-stated purposes, legal or business purposes and in accordance with the Act. In the event that your personal data is no longer required, we will anonymise and/or destroy the personal data in our control as soon as possible.</p>
                                    <p>6.3 We also use reasonable efforts to ensure the security of your personal data and have reasonable security processes/arrangements to protect your personal data. We regularly review our security practices to consider appropriate new technology and methods. However, please be aware that despite our best efforts, no security measures are perfect or impenetrable.</p>
                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>7. Cookie Policy</h5></Row>
                            <Row>   
                                    <p>When you visit our platform, we may assign your device one or more cookies. A cookie is a small text file that contains information that can later be read by us to facilitate your access to our platform, gather statistical data, and personalise your online experience.</p>
                                    <p>By accessing and using our platform, you agree that we can place and use cookies on your device.</p>
                                    <p>We currently use cookies on our platform for various purposes, including but not limited to improving your user experience on our platform, remembering your preferences, targeting advertising that might be of interest to you, helping us understand how our platform is performing, measuring the overall effectiveness of our online content, advertising campaigns, and the products and services we offer through our platform, monitoring traffic to our platform, detecting your web browser’s multimedia capabilities, tracking promotional advertisements that have been displayed to you and maintaining current login and purchase information on secured portions of the platform (the “Cookie Purposes”).</p>
                                    <p>Information that we collect from you through cookies may be passed to our third-party service providers, whether located in Singapore or elsewhere, for one or more of the Cookie Purposes, for managing and/or administering our platform, or for the purpose of data hosting/storage/backup.</p>
                                    <p>Our platform may allow third parties to download cookies to your device. Third parties, such as our trusted social media (including Facebook), advertising, analytics and business partners, may use cookies and other technologies to collect information about your online activities while you are on our platform. This information may be used to measure the usage of our platform and personalise advertising content on our platform. We do not have access to or control over cookies or other features these third parties may use, and the information practices of these third parties are not covered by this policy. Please refer to the policies of those third parties to better understand their activities and practices.</p>
                                    <p>Most browsers automatically accept cookies, but you can modify your browser settings to decline cookies. Generally, you can remove these cookies by following directions provided in your Internet browser’s “help” file. If you choose not to allow us to place a cookie on your computer, you may be restricted from some services and some of the interactive features offered on our platform may be similarly restricted or rendered inoperable.</p>

                            </Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>8. Governing Law</h5></Row>
                            <Row><p>8.1 This Policy and your use of this platform and/or our services shall be governed in all respects by the laws of the Republic of Singapore.</p></Row>
                        </Col>
                        <Col style={{marginBottom:'20px'}}>
                            <Row style={{marginBottom:'10px'}}><h5>9. Market Research</h5></Row>
                            <Row>   <p>9.1 Occasionally, the platform asks its users to complete market research surveys. Survey results are aggregated and shared with advertisers, sponsors and related corporations in a manner that does not disclose personal information.</p></Row>
                        </Col>
                        
                   
            </StyledTerms>
        </div>
        )
    }
}

export default PrivacyPolicy