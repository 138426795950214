import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledButton = styled.div`
  width: 290px;
  flex: 1;
  height: 60px;
  border-radius: 10px;
  background: ${props => props.bordered ? Colors.color3 : props.disabled ? Colors.color16 : props.backgroundColor};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  ${props =>
    props.bordered &&
    `
      border: 1.5px solid ${props.borderColor};
    `
  }
  :hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  }
  
  .button-text {
    ${Fonts.FontRegular};
    font-style: normal;
    display: inline-block;
    font-size: 20px;
    color: ${props => props.disabled ? Colors.color7 : props.textColor};
    font-weight: 800;
    text-align: center;
    ${props => props.textStyle}
  }

  .icon-right {
    width: 12px;
    height: 8px;
    object-fit: contain;
  }

  ${props => props.buttonStyle}
`

export const StyledDropdown = styled.div`  
  ul {
    background-color: ${Colors.color3};
    width: ${props => props.dropdownWidth};
    z-index: 2;
    position: absolute;
    height: ${props => props.showDropdown ? 'auto' : '0px'};
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  ul li {
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
    font-weight: 800;
    color: ${Colors.color2};
    padding: ${props => props.showDropdown ? '10px' : '0px'};
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  ul li:hover {
    cursor: pointer;
  }
`