import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import { Link } from 'react-router-dom';
import Button from '../../components/button'
import styled from 'styled-components';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

import back from '../../assets/images/icon-back.png'

import AppConfig from '../../config'

import Swal from 'sweetalert2'
import PopUpBoxHandler from '../../components/modalhandler';
import axios from 'axios'
const accountUrl = AppConfig.ACCOUNT_URL

export class createNewPWD extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          username:'',
          password :'',
          confirmPassword: '',
          enableBtn:true,
          uid:this.props.location.state.uid,
          token:this.props.location.state.token,
         
    streetName:this.props.location.state.streetName,
    unitNumber:this.props.location.state.unitNumber,
    postalCode:this.props.location.state.postalCode,
    cityCountry:this.props.location.state.cityCountry,
          errorMessage:'',
          show: false,
          fullData: {
            ...this.props.location.state.fullData
          },
          
          type: 'password',
          type2: 'password',
        }
      }
   handleShow = (e)=> {
    
     this.setState({show: true})
  }
  
  submitForm = ()=>{ 

    if(this.state.password !== this.state.confirmPassword) {
      Swal.fire({
        title: 'Warning!',
        text: 'The password and the confirm password do not match',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else if(this.state.password.length < 6) {
      Swal.fire({
        title: 'Warning!',
        text: 'Password cannot be less than 6 characters',
        icon: 'info',
        confirmButtonText: 'OK'
      })
    } else {
      axios.post(`${accountUrl}/public/credential/checkDementiaRegistration`, {
        username : this.state.username
   })
   .then(res => {
       console.log(res)
     if(res.status==200 && res.data.data.registered==true){
      Swal.fire({
        title: 'Warning!',
        text: 'Username already exist',
        icon: 'info',
        confirmButtonText: 'OK'
      })
     }else{
       
      window.sessionStorage.setItem("username", this.state.username);
      window.sessionStorage.setItem("password", this.state.password);
      this.props.history.push({pathname:'/CareGiverCreateNewPWDDetail',
      state:{
        username: this.state.username,
        password: this.state.password,
        streetName: this.state.streetName,
        unitNumber: this.state.unitNumber,
        postalCode: this.state.postalCode,
        cityCountry: this.state.cityCountry,
        uid: this.state.uid,
        uid:this.state.uid,
        token:this.state.token,
        fullData: {
          ...this.state.fullData
        },
      }})
      console.log(this.state.username)
      console.log(this.state.password)
      if(this.state.password == this.state.confirmPassword){
        console.log("Password Match")
      }
      return this.state
     };
   }, (error) => {
     console.log(error);
   });
    }
  }
 togglePasswordVisiblity = () => {
  if(this.state.passwordShown==false){

    this.setState({passwordShown :true});
    
    this.setState({type:'text'})

  }else{
    this.setState({type:'password'})
    this.setState({passwordShown :false});
    
  }
}; 

togglePasswordVisiblity2 = () => {
  if(this.state.passwordShown2==false){

    this.setState({passwordShown2 :true});
    
    this.setState({type2:'text'})

  }else{
    this.setState({type2:'password'})
    this.setState({passwordShown2 :false});
    
  }
}; 

onChangeText = (stateName, value) => {
  this.setState({ [stateName]: value });
  
  if(this.state.password.length >= 5 && this.state.confirmPassword.length >= 5 && this.state.username != ''){
    this.setState({enableBtn:false})
  }else{
    this.setState({enableBtn:true})
  }
};
    
handleClose = (e)=> {
    
  this.setState({show: false})
 } 
  render() {
    
    const {type,username,password,enableBtn,show,errorMessage, confirmPassword, type2} = this.state
    return (
      <>
        <StyledCG>
            <Header/>
            <div onClick={() => this.props.history.goBack()} style={{ marginBottom: '20px', width: '70px', height: '30px', background: '#502773', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <div><img src={back} alt="back" style={{ width: '9px', objectFit: 'contain' }} /></div>
              <div style={{ fontWeight: 800, fontSize: '13px', color: 'white', marginLeft: '5px' }}>Back</div>
            </div>
            <Row>
              <Col md={6}>
                <h2>
                  Create an account 
                  for the Person Living with Dementia
                </h2>
              </Col>
            </Row>
         
                <Row style={{textAlign:'start',marginTop:'6%'}}>
                    <Col  style={{margin:'auto'}}>
                        <label>Username</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" inputProps={{placeholder:'Type your username here...',name:"username",
                                                                    type:'text',value:username,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('username', e.target.value),}}/>
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label>Password</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" 
                           inputProps={{placeholder:'Type your password here...',name:"password",
                                                                    type:type,value:password,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('password', e.target.value)}}   />
                          <i onClick={this.togglePasswordVisiblity}>{eye}</i>
      
                    </Col>
                </Row>
                <Row>
                    <Col  style={{margin:'auto'}}>
                        <label> Confirm Password</label>
                    </Col>
                    <Col md={6}>
                    <Input containerStyle="InputStyle" 
                           inputProps={{placeholder:'Type your confirm password here...',name:"confirmPassword",
                                                                    type:type2 ,value:confirmPassword,
                                                                    onChange: (e) =>
                                                                     this.onChangeText('confirmPassword', e.target.value)}}   />
                          <i onClick={this.togglePasswordVisiblity2}>{eye}</i>
      
                    </Col>
                </Row>
           
            <Row style={{justifyContent:'flex-end',marginRight:'1%',marginTop:'10%'}}>
               
                <div style={{ width: '230px' }}>
                  {/* <Button disabled={enableBtn} containerStyle={StyledButton} textStyle={StyledButton} buttonStyle={{height:'60px', width:'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}} onClick={this.submitForm}  text="Register" /> */}
                  <CustomButton color="#62318C" onClick={this.submitForm}>
                    Register
                  </CustomButton>
                </div>
                <PopUpBoxHandler show={show} text={errorMessage} handleClose={this.handleClose}/>
           
                </Row>
            </StyledCG>

        
       
      </>
    )
  }
}

const CustomButton = styled.button`
  background-color: ${props => props.color} !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 60px !important;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px #C1CAD7 !important;
  font-weight: bold !important;
  margin-bottom: 20px;
  cursor: pointer !important; 
`;


export default createNewPWD