import React,{useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CG from '../../assets/images/Caregiver_new icon.svg'
import PWD from '../../assets/images/OptionPWD.png'
import Type from '../../assets/images/ACTIVE.png'
import TypeInactive from '../../assets/images/INACTIVE.png'
import partnerIcon from '../../assets/images/partner.png'
import { Link } from 'react-router-dom';

import Button from '../button'


import { StyledType, StyledButton } from "./StyledType";

function OptionType(props) {
 const [inactivePWD,setInactivePWD] = useState(TypeInactive);
 const [inactiveCG,setInactiveCG] = useState(TypeInactive);
 const [inactivePartner,setInactivePartner] = useState(TypeInactive);
 const [route,setRoute] = useState("")

 useEffect(() => {
  
  sessionStorage.clear();
});

  const changeColorPWD = () => {
    if (inactivePWD== TypeInactive) {
        setInactivePWD(Type)
        setInactiveCG(TypeInactive)
        setInactivePartner(TypeInactive)
        setRoute("/registrationPWD")
    } else {
      setInactivePWD(TypeInactive)
    }
  }

  const changeColorCG = () => {
    if (inactiveCG == TypeInactive ){
      setInactiveCG(Type)
      setInactivePWD(TypeInactive)
      setInactivePartner(TypeInactive)
      setRoute("/registrationCG")
    } else {
      setInactiveCG(TypeInactive)
    }
  }

  const changeColorPartner = () => {
    if (inactivePartner == TypeInactive ) {
      setInactivePartner(Type)
      setInactivePWD(TypeInactive)
      setInactiveCG(TypeInactive)
      setRoute("/registration-partner")
    } else {
      setInactivePartner(TypeInactive)
    }
  }
  return (
    <>
      <StyledType >
        <h2 style={{marginBottom:'40px',marginTop:'30px'}}>Select Member Role</h2>
        <Row className="justify-content-center">
          {/* <Col xs={12} md={4}>
            <button className="containerPWD" onClick={changeColorPWD}>
              <Row style={{justifyContent:'flex-end'}}>
                  <img src={inactivePWD}  className="type"/>
              </Row> 
              <img style={{
                display:"block",
                paddingTop:'10px', 
                marginLeft: "auto", 
                marginRight: "auto",
                width: '70px',
                height: '70px',
                objectFit: 'contain',  
              }} src={PWD}/>
              <h4 style={{marginTop: '25px'}}>I have dementia</h4>
            </button>
          </Col> */}
            
          <Col xs={12} md={4}>
            <button onClick={changeColorCG} className="containerCG">
              <Row style={{justifyContent:'flex-end'}}>
                <img  src={inactiveCG} className='type' />
              </Row>
              <img style={{ 
                display:"block", 
                paddingTop:'10px', 
                marginLeft: "auto", 
                marginRight: "auto",
                width: '70px',
                height: '70px',
                objectFit: 'contain',
              }} src={CG}/>
              <h4 style={{marginTop: '25px'}}>I am a Caregiver</h4>
            </button>
          </Col>

          <Col xs={12} md={4}>
            <button onClick={changeColorPartner} className="containerCG">
              <Row style={{justifyContent:'flex-end'}}>
                <img  src={inactivePartner} className='type' />
              </Row>
              <img style={{
                display:"block",
                width: '70px',
                height: '70px',
                objectFit: 'contain',
                paddingTop:'10px', 
                marginLeft: "auto", 
                marginRight: "auto"
              }} src={partnerIcon}/>
              <h4 style={{marginTop: '25px'}}>I am a Care Professional</h4>
            </button>
          </Col>
        </Row>
        <div style={{display:'flex',justifyContent:'flex-end',marginTop:'40px',marginBottom:'40px'}}>
          <Link to={route}>
            <Button containerStyle={`display: flex; justify-content: flex-end; `} buttonStyle={`display: flex; justify-content: center; align-items: flex-end; height: 60px; width: 200px; `} text="Next"/>
          </Link>
        </div>
      </StyledType>

    </>
  )
}


export default OptionType
